import {
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { ConfigProvider, DatePicker, Form, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  EMPLOYEE_LEAVE_TYPES,
  GLOBAL_TIME_FORMAT,
} from "app/constants/global.constants";
import {
  API_ENDPOINT_LEAVE_APPROVE_REJECT_LEAVE,
  API_ENDPOINT_LEAVE_CREATE_REQUEST,
} from "app/scenes/leaves/leaves.constants";
import { apiPost } from "app/services/apiService";
import Button from "app/shared/Button";
import Selector from "app/shared/Selector";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { parseURL } from "app/utils/helpers/common.helper";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

export default function CreateLeave(props) {
  const [isLoading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [description, setDescription] = useState("");

  const dispatch = useDispatch();

  const createRequest = async () => {
    if (moment(toDate).isBefore(moment(fromDate))) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: "Invalid leave duration", type: 0 },
      });
      return;
    }
    setLoading(true);
    const payload = {
      from: fromDate,
      to: toDate,
      description: description,
      type: type,
    };

    if (!fromDate || !toDate) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: "Select your dates", type: 0 },
      });
      return;
    }
    const response = await apiPost(API_ENDPOINT_LEAVE_CREATE_REQUEST, payload);

    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onSuccess && props.onSuccess();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  return (
    <Form
      name="basic"
      initialValues={{
        remember: true,
        description: description,
        toDate: toDate,
        fromDate,
        fromDate,
      }}
      onFinish={createRequest}
      // onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <div className="filterWidget" style={{ marginBottom: 12 }}>
        <Typography variant="caption" style={{ marginBottom: 4 }}>
          Leave Type
        </Typography>
        <Selector
          placeholder="Leave Type"
          labelKey="name"
          valueKey="id"
          serviceType="STATIC"
          onChange={(item) => setType(item)}
          staticData={EMPLOYEE_LEAVE_TYPES}
          autoSelect="PAID"
        />
      </div>
      <div style={{ display: "flex", marginTop: 24 }}>
        <div
          className="filterWidget"
          style={{ marginBottom: 12, marginRight: 48 }}
        >
          <Form.Item name="fromDate">
            <Typography variant="caption" style={{ marginBottom: 4 }}>
              Start Date
            </Typography>
            <DatePicker
              onChange={(ojb, dateString) =>
                setFromDate(moment(dateString).format(GLOBAL_TIME_FORMAT))
              }
              name="fromDate"
            />
          </Form.Item>
        </div>
        <div className="filterWidget" style={{ marginBottom: 12 }}>
          <Form.Item name="toDate">
            <Typography variant="caption" style={{ marginBottom: 4 }}>
              End Date
            </Typography>
            <DatePicker
              onChange={(ojb, dateString) =>
                setToDate(moment(dateString).format(GLOBAL_TIME_FORMAT))
              }
              name="toDate"
            />
          </Form.Item>
        </div>
      </div>

      <div className="filterWidget" style={{ marginBottom: 12 }}>
        <Form.Item
          name="description"
          rules={[
            {
              required: true,
              message: "Please describe your leave",
            },
          ]}
          style={{ fontSize: 12 }}
        >
          <TextArea
            //   value={value}
            //   onChange={(e) => setValue(e.target.value)}
            placeholder="Please add description here...."
            style={{
              backgroundColor: "#F4F6F9",
              width: "100%",
              border: "none",
              borderRadius: 12,
            }}
            onChange={(e) => setDescription(e.target.value)}
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>
      </div>
      <div className="action-buttons">
        <Form.Item style={{ width: "100%" }}>
          <div className="filterWidget" style={{ marginTop: 44 }}>
            <Button
              type="primary"
              size="large"
              style={{ width: "100%" }}
              htmlType="submit"
              loading={isLoading}
            >
              Create Request
            </Button>
          </div>
        </Form.Item>
      </div>
    </Form>
  );
}
