import { Form, Slider } from "antd";
import { API_ENDPOINT_BROKERS_INVITE } from "app/scenes/brokers/brokers.constants";
import { API_ENDPOINT_USER_INTERNAL_CREATE } from "app/scenes/promoters/promoters.constants";
import { apiPost, apiPut } from "app/services/apiService";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import LocationFinder from "app/shared/LocationFinder";
import Selector from "app/shared/Selector";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LockResetIcon from "@mui/icons-material/LockReset";
import { colorPicker } from "app/utils/helpers/color.helper";
import {
  API_ENDPOINT_GLOBAL_UPDATE_MASTER_DATA,
  API_ENDPOINT_GLOBAL_UPDATE_PROPERTY_TYPE,
  API_ENDPOINT_PROPERTY_DEVELOPER_UPDATE,
} from "app/scenes/property/property.constants";
import Typography from "app/shared/Typography";

export default function EditPropertyTypes(props) {
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [comission, setComission] = useState("");

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (props.data) {
      setName(props.data?.name);
    }
  }, [props.data]);

  const dispatch = useDispatch();
  const handleUpdateMasterData = async () => {
    setLoading(true);
    const payload = {
      name: name,
      category: category,
      comission: comission,
    };

    const response = await apiPut(
      API_ENDPOINT_PROPERTY_DEVELOPER_UPDATE + props.data.id,
      payload
    );
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onCreate && props.onCreate();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  return (
    <div>
      <Form
        name="basic"
        initialValues={{
          remember: true,
        }}
        fields={[
          {
            name: "name",
            value: name,
          },
          {
            name: "category",
            value: category,
          },
          {
            name: "comission",
            value: comission,
          },
        ]}
        onFinish={handleUpdateMasterData}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="filterWidget" style={{ marginBottom: 12 }}>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Please check your email",
              },
            ]}
          >
            <Input
              placeholder="Name"
              label="Name"
              onChange={(e) => setName(e.target.value)}
              name="name"
            />
          </Form.Item>
        </div>

        <div className="action-buttons">
          <Form.Item style={{ width: "100%" }}>
            <div className="filterWidget" style={{ marginTop: 44 }}>
              <Button
                type="primary"
                size="large"
                style={{ width: "100%" }}
                htmlType="submit"
                loading={isLoading}
              >
                Update
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
