import React, { useEffect, useMemo, useRef, useState } from "react";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import { API_ENDPOINT_USER_SEARCH } from "app/scenes/leads/leads.constants";
import {
  API_ENDPOINT_PROJECT_SEARCH,
  API_ENDPOINT_PROPERTY_SEARCH,
} from "app/scenes/property/property.constants";

const API_MAP = {
  USERS: API_ENDPOINT_USER_SEARCH,
  PROPERTY: API_ENDPOINT_PROPERTY_SEARCH,
  PROJECT: API_ENDPOINT_PROJECT_SEARCH,
};
function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (props.serviceType === "STATIC") {
      setOptions(props.staticData);
    }
  }, [props.serviceType, props.staticData]);

  const ReturnSearchData = (query) => {
    let filtered = props.staticData.filter((item) =>
      item[props.labelKey || "label"]
        ?.toLowerCase()
        .includes(query?.toLowerCase())
    );
    return filtered;
  };
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      if (props.serviceType !== "STATIC") {
        setOptions([]);
        setFetching(true);
        fetchOptions(
          value,
          props.serviceType || "USERS",
          props.labelKey,
          props.valueKey
        ).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }
          setOptions(newOptions);
          setFetching(false);
        });
      } else {
        let searchedResult = ReturnSearchData(value);

        setOptions(searchedResult);
      }
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
      style={{ height: props.height || 30, width: "100%" }}
      defaultValue={props.defaultValue}
    />
  );
}

// Usage of DebounceSelect

async function fetchUserList(username, serviceType, labelKey, valueKey) {
  return fetch(API_MAP[serviceType] + "?query=" + username, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then((response) => response.json())
    .then((body) =>
      body.data.map((user) => ({
        label: user[labelKey || "fullname"],
        value: user[valueKey || "id"],
      }))
    );
}
const SearchSelector = (props) => {
  const [value, setValue] = useState([]);
  const [isClicked, setClicked] = useState(false);
  useEffect(() => {
    if (props.defaultValue)
      setValue([{ label: props.defaultValue, value: props.defaultValue }]);
  }, [props.defaultValue]);

  // useEffect(() => {
  //   if (clearOnParentValue && clearOnParentValue === null) setValue([]);
  // }, [props.clearOnParentValue]);
  return (
    <DebounceSelect
      mode={"multiple"}
      value={value}
      placeholder={props.placeholder || "Select users"}
      fetchOptions={fetchUserList}
      onChange={(newValue) => {
        setValue(newValue && (newValue[1] || newValue[0]));
        props.onChange &&
          props.onChange(
            (newValue && newValue[1]?.value) || newValue[0]?.value
          );
      }}
      height={props.height}
      serviceType={props.serviceType}
      style={{
        width: "100%",
      }}
      queryFilter={props.queryFilter}
      staticData={props.staticData}
      valueKey={props.valueKey}
      labelKey={props.labelKey}
      defaultValue={props.defaultValue}
    />
  );
};
export default SearchSelector;
