import Typography from "app/shared/Typography";
import PromotorIcon from "assets/images/promote.svg";
import HomeIcon from "assets/images/home.svg";
import ComissionIcon from "assets/images/comission.svg";
import VisitIcon from "assets/images/visit.svg";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import "./SaleList.scss";
import { colorPicker } from "app/utils/helpers/color.helper";
import { Avatar, Image } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  API_ENDPOINT_FETCH_BROKER_COMISSION_EARNING_LIST,
  API_ENDPOINT_FETCH_BROKER_REFERRALS,
} from "app/scenes/brokers/brokers.constants";
import { useEffect, useState } from "react";
import Loader from "app/shared/Loader";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { apiGet } from "app/services/apiService";
import TabBar from "app/shared/TabBar";
import CallReceivedRoundedIcon from "@mui/icons-material/CallReceivedRounded";
import CallMadeRoundedIcon from "@mui/icons-material/CallMadeRounded";
import TableView from "app/shared/TableView";
import { parseURL } from "app/utils/helpers/common.helper";
import moment from "moment";
import { PROPERTY_TYPES } from "app/constants/global.constants";
const imageType = {
  HOME: HomeIcon,
  VISIT: VisitIcon,
  COMISSION: ComissionIcon,
};

const salesTableColumn = [
  {
    title: "Property Name",
    index: "property.name",
    search: true,
  },
  {
    title: "Property Type",
    index: "property.property_type.name",
    renderTag: true,
    filters: PROPERTY_TYPES,
    onFilter: (value, record) =>
      record.property.property_type.name.indexOf(value) === 0,
  },
  {
    title: "Property Cost",
    index: "property.cost",
    amount: true,
  },
];

export default function SaleList(props) {
  const visitTableCols = [
    {
      title: "Visiting Amount",
      index: "property.booking_amount",
      amount: true,
    },
    {
      title: "Lead Id",
      index: "lead.lead_code",
      renderTag: true,
      url_redirect_key: "lead.id",
    },
    {
      title: "Lead Name",
      index: "lead.fullname",
    },
    {
      title: "Lead Mobile",
      index: "lead.mobile",
    },
    {
      title: "Status",
      index: "status",
      renderTag: true,
    },
  ];
  const renderTabContent = (dataType) => {
    return (
      <div className="EarningListContent">
        <div className="TableContainer">
          <TableView
            data={props.data && props.data[dataType]}
            column={[
              ...salesTableColumn,
              {
                title: props.type === "SALES" ? "Sale Date" : "Visit Date",
                index: "createdAt",
                date: true,
                defaultSortOrder: "descend",
                sorter: (a, b) =>
                  moment(a.createdAt).unix() - moment(b.createdAt).unix(),
              },
              ...(props.type === "SALES"
                ? [
                    {
                      title: "Comission (%)",
                      index: "property.comission",
                    },
                  ]
                : visitTableCols),
            ]}
            // handleAction={handleTableAction}
          />
        </div>
      </div>
    );
  };
  const items = [
    {
      key: "1",
      label: `Residential ${props.type === "SALES" ? " Sales" : " Visits"}`,
      children: renderTabContent("residentials"),
    },
    {
      key: "2",
      label: `Commerical ${props.type === "SALES" ? " Sales" : " Visits"}`,
      children: renderTabContent("commercials"),
    },
  ];

  return (
    <div className="SaleList">
      <TabBar items={items} color="red" leftAligned inActiveColor="gray_dark" />
    </div>
  );
}
