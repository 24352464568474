import React, { useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import DummyAvatar from "assets/images/DummyAvatar.png";
import { API_ENDPOINT_PROFILE_UPDATE_AVATAR } from "app/scenes/onboarding/onboard.constants";
import { apiPostForm } from "app/services/apiService";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};
const UserAvatar = (props) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(props.avatar);
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const uploadUserAvatar = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    let formData = new FormData();
    formData.append("file", file);
    const response = await apiPostForm(
      API_ENDPOINT_PROFILE_UPDATE_AVATAR,
      formData
    );
    if (response.status) {
      onSuccess("Ok");
    } else {
      const error = new Error("Some error");
      onError({ error });
    }
  };

  return (
    <Upload
      name="avatar"
      listType="picture-circle"
      className="avatar-uploader"
      showUploadList={false}
      beforeUpload={beforeUpload}
      customRequest={uploadUserAvatar}
      onChange={handleChange}
      style={{ height: 84, width: 84 }}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="avatar"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      ) : (
        <img
          src={DummyAvatar}
          alt="avatar"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",

            objectFit: "cover",
          }}
        />
      )}
    </Upload>
  );
};
export default UserAvatar;
