import { EditFilled } from "@ant-design/icons";
import { Button, Popconfirm, Switch, Tag } from "antd";
import { colorPicker } from "app/utils/helpers/color.helper";
import Typography from "../Typography";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import AspectRatioOutlinedIcon from "@mui/icons-material/AspectRatioOutlined";
import "./property_card.scss";
import { useContext, useEffect, useState } from "react";
import { apiGet } from "app/services/apiService";
import { parseURL } from "app/utils/helpers/common.helper";
import { API_ENDPOINT_PROPERTY_FETCH_IMAGES } from "app/scenes/property/property.constants";
import { useNavigate } from "react-router";
import { FormattedNumber } from "react-intl";
import { FeatureFlags } from "app/utils/FeatureFlag/FeatrueFlag";
export default function PropertyCard(props) {
  const [images, setImages] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetchImages();
  }, []);

  const { hasAccess } = useContext(FeatureFlags);

  const fetchImages = async () => {
    const response = await apiGet(
      parseURL(API_ENDPOINT_PROPERTY_FETCH_IMAGES, {
        propertyId: props.data.id,
      })
    );
    if (response.status) {
      setImages(response.data);
    }
  };

  const handleEditMode = (e) => {
    e.stopPropagation();
    navigate(
      "/property/project/unit/edit/" +
        props.data.propertyProjectId +
        "/" +
        props.data.id
    );
  };
  return (
    <div
      className="PropertyCard"
      onClick={() => navigate("/property/" + props.data.id)}
    >
      <div className="CardImage">
        <img
          src={
            images && images.length > 0
              ? images[0].url
              : "https://images.ctfassets.net/bed00l8ra6lf/5gauKvJAKwG7FJQtEVz7vJ/a57c984e42f71fa8f216eca14f77cd84/Before_you_start.svg"
          }
          alt="PropertyImage"
        />
        {hasAccess("PROPERTY_LISTING_MANAGE") ? (
          <div className="floatTopButton">
            <Button
              type="primary"
              shape="circle"
              icon={<EditFilled style={{ color: colorPicker("red") }} />}
              style={{
                backgroundColor: "white",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              }}
              onClick={handleEditMode}
            />
          </div>
        ) : null}
        <div className="floatingBottomButton">
          <Tag color="#108ee9">{props.data?.property_code}</Tag>

          <Popconfirm
            title={`Are you sure want to ${
              props.data?.status === "DISABLED" ? "enable" : "disable"
            } this account`}
            // description="Are you sure to delete this task?"
            // onConfirm={() =>
            //   childProps.handleSwitch(
            //     childProps.id,
            //     props.data?.status === "DISABLED"
            //       ? "UNDER_REVIEW"
            //       : "DISABLED"
            //   )
            // }
            onCancel={null}
            okText="Yes"
            cancelText="No"
          >
            <Switch
              style={{ backgroundColor: "#7f8c8d" }}
              // onClick={(e) => {
              //   e.stopPropagation();
              // }}
            />
          </Popconfirm>
        </div>
      </div>
      <div className="CardDetails">
        <Typography variant="body" style={{ marginTop: 14 }}>
          {/* INR{" "} */}
          <b style={{ fontWeight: 700, color: colorPicker("red") }}>
            <FormattedNumber
              value={props.data?.cost}
              style="currency"
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              currency={"INR"}
            />
          </b>
        </Typography>
        <Typography
          variant="caption"
          color="gray-dark"
          style={{ marginTop: 8 }}
        >
          {props.data?.name}
        </Typography>
        <div className="Amentities">
          <div className="Amentity">
            <BedOutlinedIcon fontSize={"small"} style={{ color: "#000929" }} />
            <Typography variant="xs" style={{ marginLeft: 4 }} color="black">
              {props.data.bedroom || "NA"}
            </Typography>
          </div>

          <div className="Amentity">
            <BathtubOutlinedIcon
              fontSize={"small"}
              style={{ color: "#000929" }}
            />
            <Typography variant="xs" style={{ marginLeft: 4 }} color="black">
              {props.data.bathroom || "NA"}
            </Typography>
          </div>

          <div className="Amentity">
            <AspectRatioOutlinedIcon
              fontSize={"small"}
              style={{ color: "#000929" }}
            />
            <Typography variant="xs" style={{ marginLeft: 4 }} color="black">
              {props.data?.size} {props.data?.size_unit}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
