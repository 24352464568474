import { Checkbox, ConfigProvider, Form, Modal, Slider, Upload } from "antd";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import Selector from "app/shared/Selector";
import SimpleFileSelector from "app/shared/SimpleFileSelector";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/helpers/color.helper";
import STATE_LIST from "app/constants/states.json";
import "../../property_create.scss";
import { useEffect, useState } from "react";
import { apiGet, apiPost } from "app/services/apiService";
import {
  API_ENDPOINT_PROJECT_PAYMENT_PLAN_INFO,
  API_ENDPOINT_PROPERTY_ADD_PLAN,
  API_ENDPOINT_PROPERTY_INFO,
} from "../../../../property.constants";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { useDispatch } from "react-redux";
import { COMISSION_TYPES } from "app/constants/global.constants";
import MapPicker from "app/shared/MapPicker";
import { parseURL } from "app/utils/helpers/common.helper";
import { useParams } from "react-router";
import Loader from "app/shared/Loader";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import EMICalculator from "app/shared/EMICalculator";
import { CheckCircleOutlined, SafetyOutlined } from "@ant-design/icons";
import TableView from "app/shared/TableView";
import { FormattedNumber } from "react-intl";

const marks = {
  0: "0°C",
  26: "26°C",
  37: "37°C",
  100: {
    style: {
      color: "#f50",
    },
    label: <strong>100°C</strong>,
  },
};
const roomData = [
  {
    label: "6 Month",
    value: 6,
  },
  {
    label: "12 Month",
    value: 12,
  },
  {
    label: "18 Month",
    value: 18,
  },
  {
    label: "24 Month",
    value: 24,
  },
  {
    label: "30 Month",
    value: 30,
  },
  {
    label: "36 Month",
    value: 36,
  },
  {
    label: "42 Month",
    value: 42,
  },
  {
    label: "48 Month",
    value: 48,
  },
];

export default function PaymentPlanInfo(props) {
  const [showImageDialog, setShowImageDialog] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("EMI");
  const [formData, setFormData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [useEMICal, setUseEMICal] = useState(false);
  const [currentProjectId, setCurrentProjectId] = useState("");
  const [planInfo, setPlanInfo] = useState(null);
  const [propertyInfo, setPropertyInfo] = useState(null);
  const dispatch = useDispatch();

  const tableColumn = [
    {
      title: "Installment",
      index: "name",
    },

    {
      title: "Percentage (%)",
      index: "percentage",
    },
    {
      title: "Amount",
      index: "percentage",
      customRender: (data) => {
        console.log(
          "Data",
          (parseInt(data.percentage) / 100) * data.propertyCost
        );
        return (
          <FormattedNumber
            value={(parseInt(data.percentage) / 100) * data.propertyCost}
            style="currency"
            currency={"INR"}
            minimumFractionDigits={0}
            maximumFractionDigits={0}
          />
        );
      },
    },
    {
      title: "On Day",
      index: "day",
    },
  ];

  const updatePaymentPlan = async () => {
    setLoading(true);
    const payload = {
      type: selectedPlan,
      downpayment: formData.downpayment,
      plan_duration: formData.emi_tenure,
      additional_cost: formData.additional_cost,
      custom_plan_id: formData.payment_plan,
      interest: formData.interest,
      emi: formData.emi,
      lendingPartnerId: formData.bank,
    };
    if (
      selectedPlan === "EMI" &&
      parseInt(payload.downpayment) > parseInt(formData.total_cost)
    ) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: "Downpayment cannot exceed total cost", type: 0 },
      });
    } else {
      const response = await apiPost(
        parseURL(API_ENDPOINT_PROPERTY_ADD_PLAN, {
          propertyId: props.propertyId,
        }),
        payload
      );
      if (response.status) {
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: response.message, type: 1 },
        });
        props.onSuccess && props.onSuccess();
      }
    }
    setLoading(false);
  };

  let { propertyId } = useParams();
  useEffect(() => {
    if (props.editMode) {
      fetchPropertyDetails();
    } else {
      setFormData({ ...formData, total_cost: props.propertyCost });
    }
  }, []);
  const fetchPropertyDetails = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_PROPERTY_INFO + propertyId);
    if (response.status) {
      handleInitialValues(response.data);
      setCurrentProjectId(response.data.property_project?.id);
      setPropertyInfo(response.data);
      if (response.data && response.data.property_payment_plan?.custom_plan_id)
        fetchPaymentPlanInfo(
          response.data.property_payment_plan?.custom_plan_id,
          response.data.property_project?.id
        );
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  const fetchPaymentPlanInfo = async (planId, projectId = currentProjectId) => {
    const response = await apiGet(
      parseURL(API_ENDPOINT_PROJECT_PAYMENT_PLAN_INFO, {
        projectId: projectId,
        planId: planId,
      })
    );
    if (response.status) {
      setPlanInfo(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  const handleInitialValues = (respData) => {
    let data = {
      downpayment:
        respData.property_payment_plan &&
        respData.property_payment_plan?.downpayment,
      emi_tenure:
        respData.property_payment_plan &&
        respData.property_payment_plan?.plan_duration,
      additional_cost:
        respData.property_payment_plan &&
        respData.property_payment_plan?.additional_cost,
      total_cost: respData.cost,
      interest: respData.property_payment_plan?.interest,
      payment_plan: respData.property_payment_plan?.custom_plan_id,
      emi: respData.property_payment_plan?.emi,
      bank: respData.property_payment_plan?.lendingPartnerId,
    };
    setSelectedPlan(
      respData.property_payment_plan &&
        respData.property_payment_plan?.plan_type
    );
    setFormData(JSON.parse(JSON.stringify(data)));

    setLoading(false);
  };

  const handleApplyPlan = (planData) => {
    let prevInfo = JSON.parse(JSON.stringify(formData));
    prevInfo.downpayment = planData.emi_downpayment;
    prevInfo.emi = planData.emi;
    prevInfo.interest = planData.intereset;
    prevInfo.emi_tenure = planData.emi_tenure;
    prevInfo.total_cost = planData.total_cost;
    prevInfo.bank = planData.bank;

    setFormData(prevInfo);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "payment_plan")
      fetchPaymentPlanInfo(value, currentProjectId || props.projectId);
  };

  return props.editMode && isLoading ? (
    <Loader />
  ) : (
    <ConfigProvider
      theme={{
        components: {
          Slider: {
            railBg: "#7C8091",
            dotActiveBorderColor: colorPicker("red"),
            colorText: colorPicker("red"),
            trackBg: colorPicker("red"),
            trackHoverBg: colorPicker("red"),
            handleColor: colorPicker("red"),
            handleActiveColor: colorPicker("red"),
          },
        },
      }}
    >
      <div className="PropertyCreate">
        <Form
          name="basic"
          // initialValues={{ ...formData }}
          fields={[
            {
              name: ["total_cost"],
              value: formData.total_cost,
            },
            {
              name: ["emi_tenure"],
              value: formData.emi_tenure,
            },
            {
              name: ["downpayment"],
              value: formData.downpayment,
            },
            {
              name: ["interest"],
              value: formData.interest,
            },
            {
              name: ["additional_cost"],
              value: formData.additional_cost,
            },
            {
              name: ["emi"],
              value: formData.emi,
            },
          ]}
          onFinish={updatePaymentPlan}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Checkbox
            onChange={() => setSelectedPlan("PAYMENT")}
            checked={selectedPlan === "PAYMENT"}
          >
            <Typography variant="h5">Custom Payment Plan </Typography>
          </Checkbox>

          <div className="form-row" style={{ marginBottom: 0 }}>
            <div className="input-element">
              <Form.Item
                name={"payment_plan"}
                initialValue={
                  selectedPlan === "PAYMENT" ? formData.emi_tenure : ""
                }
              >
                <Typography
                  variant="body"
                  weight={400}
                  style={{ marginLeft: 4, marginBottom: 4 }}
                >
                  <SafetyOutlined style={{ color: colorPicker("green") }} />{" "}
                  Approved Payment Plans
                </Typography>
                <Selector
                  placeholder="Payment Plan"
                  labelKey="name"
                  valueKey="id"
                  serviceType="PAYMENT_PLANS"
                  name="payment_plan"
                  onChange={handleInputChange}
                  disabled={selectedPlan === "EMI"}
                  defaultValue={formData.payment_plan}
                  queryFilter={{
                    key: "projectId",
                    dataValue: currentProjectId || props.projectId,
                  }}
                />
              </Form.Item>
            </div>
          </div>

          {planInfo && selectedPlan === "PAYMENT" ? (
            <div className="form-row">
              <TableView
                data={planInfo.property_payment_schemes_installments || []}
                column={[...tableColumn]}
                style={{ width: "100%" }}
                propertyCost={formData?.total_cost}
              />
            </div>
          ) : null}

          <Checkbox
            onChange={() => setSelectedPlan("EMI")}
            checked={selectedPlan === "EMI"}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">EMI Plan </Typography>
              {selectedPlan === "EMI" ? (
                <Typography
                  variant="caption"
                  style={{ marginLeft: 6, textDecoration: "underline" }}
                  onClick={() => setUseEMICal(!useEMICal)}
                >
                  *Use calculator to fill the below details
                </Typography>
              ) : null}
            </div>
          </Checkbox>

          <div className="form-row" style={{ marginBottom: 0 }}>
            <div className="input-element">
              <Form.Item
                name={selectedPlan === "EMI" ? "downpayment" : "dp_m"}
                rules={[
                  {
                    required: selectedPlan === "EMI",
                    message: "Please check your input",
                  },
                ]}
                initialValue={
                  selectedPlan === "EMI" ? formData.downpayment : ""
                }
              >
                <Input
                  placeholder="Downpayment"
                  label="Downpayment"
                  background="white"
                  name="downpayment"
                  onChange={handleInputChange}
                  disabled
                  defaultValue={
                    selectedPlan === "EMI" ? formData.downpayment : ""
                  }
                  prefix={
                    <CurrencyRupeeIcon
                      style={{ fontSize: 16, color: colorPicker("gray_dark") }}
                    />
                  }
                />
              </Form.Item>
            </div>
            <div className="input-element">
              <Form.Item
                name={selectedPlan === "EMI" ? "emi_tenure" : "emi_uress"}
              >
                <Input
                  placeholder="EMI Tenure"
                  label="EMI Tenure (Yr)"
                  name="emi_tenure"
                  onChange={handleInputChange}
                  disabled
                  defaultValue={
                    selectedPlan === "EMI" ? formData.emi_tenure : ""
                  }
                />
              </Form.Item>
            </div>
            <div className="input-element">
              <Form.Item
                name={selectedPlan === "EMI" ? "additional_cost" : "ad_cost"}
                initialValue={
                  selectedPlan === "EMI" ? formData.additional_cost : ""
                }
              >
                <Input
                  placeholder="Additional Cost"
                  label="Additional Cost"
                  background="white"
                  name="additional_cost"
                  onChange={handleInputChange}
                  disabled
                  defaultValue={
                    selectedPlan === "EMI" ? formData.additional_cost : ""
                  }
                  prefix={
                    <CurrencyRupeeIcon
                      style={{ fontSize: 16, color: colorPicker("gray_dark") }}
                    />
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className="form-row" style={{ marginBottom: 24 }}>
            <div className="input-element">
              <Form.Item name={selectedPlan === "EMI" ? "total_cost" : "ff"}>
                <Input
                  placeholder="Total Cost"
                  label="Total Cost"
                  background="white"
                  disabled
                  defaultValue={
                    selectedPlan === "EMI" ? formData.total_cost : ""
                  }
                  prefix={
                    <CurrencyRupeeIcon
                      style={{ fontSize: 16, color: colorPicker("gray_dark") }}
                    />
                  }
                />
              </Form.Item>
            </div>
            <div className="input-element">
              <Form.Item name={selectedPlan === "EMI" ? "emi" : "ff"}>
                <Input
                  placeholder="Monthly EMI"
                  label="Monthly EMI"
                  background="white"
                  name="emi"
                  disabled
                  defaultValue={formData.emi}
                  prefix={
                    <CurrencyRupeeIcon
                      style={{ fontSize: 16, color: colorPicker("gray_dark") }}
                    />
                  }
                />
              </Form.Item>
            </div>
            <div className="input-element">
              <Form.Item name={"interest"}>
                <Input
                  placeholder="Interest Rate"
                  label="Interest Rate (%)"
                  background="white"
                  name="interest"
                  disabled
                  defaultValue={formData.interest}
                  prefix={
                    <CurrencyRupeeIcon
                      style={{ fontSize: 16, color: colorPicker("gray_dark") }}
                    />
                  }
                />
              </Form.Item>
            </div>
          </div>

          {/* <MapPicker /> */}
          <div className="FormAction">
            {props.goBack ? (
              <div style={{ marginRight: 24 }}>
                <Button
                  style={{ width: 360, marginRight: 24 }}
                  // onClick={() => setShowImageDialog(true)}
                  background={"black"}
                  onClick={props.goBack}
                >
                  Back
                </Button>
              </div>
            ) : null}
            <Button
              style={{ width: 360 }}
              // onClick={() => setShowImageDialog(true)}
              htmlType="submit"
              loading={isLoading}
            >
              Next
            </Button>
          </div>
        </Form>
        <div>
          <Typography variant="xs">
            *Payment terms are subjected to differ with the approval of banks{" "}
            <u style={{ color: colorPicker("red") }}>T&C</u> applied.
          </Typography>
        </div>
      </div>
      <Modal
        centered
        open={useEMICal}
        onCancel={() => setUseEMICal(false)}
        width={1000}
        footer={false}
      >
        <EMICalculator
          propertyInfo={formData}
          applyPlan={(planData) => {
            handleApplyPlan(planData);
            setUseEMICal(false);
          }}
        />
      </Modal>
    </ConfigProvider>
  );
}
