import React, { useState } from "react";
import { ConfigProvider, Popconfirm, Space, Switch, Table, Tag } from "antd";
import "./RolesAccess.scss";
import Typography from "app/shared/Typography";
import { apiGet, apiPut } from "app/services/apiService";
import {
  API_ENDPOINT_SETTINGS_FETCH_ROLES_ACCESS,
  API_ENDPOINT_SETTINGS_FETCH_USER_ROLES,
  API_ENDPOINT_SETTINGS_UPDATE_USER_ROLE,
} from "../../settings.constans";
import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { useEffect } from "react";
import Loader from "app/shared/Loader";
import { AddShoppingCart } from "@mui/icons-material";

const RolesAccess = (props) => {
  const [selectedModule, setSelectedModule] = useState(0);
  const [rolesInfo, setRolesInfo] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState([]);

  useEffect(() => {
    fetchUserRole();
  }, []);
  const fetchUserRole = async () => {
    const response = await apiGet(API_ENDPOINT_SETTINGS_FETCH_USER_ROLES);
    if (response.status) {
      setUserRole(response.data);
    }
  };
  const columns = [
    {
      title: "Role",
      dataIndex: "feature_name",
      key: "feature_name",
    },
    {
      title: "Broker",
      handleAction: (propsData) => handleRoleChange(propsData),
    },
    {
      title: "Promoter",
      handleAction: (propsData) => handleRoleChange(propsData),
    },
    {
      title: "Manager",
      handleAction: (propsData) => handleRoleChange(propsData),
    },
    {
      title: "Employee",
      handleAction: (propsData) => handleRoleChange(propsData),
    },
    {
      title: "Investor",

      handleAction: (propsData) => handleRoleChange(propsData),
    },
    {
      title: "CEO",
      handleAction: (propsData) => handleRoleChange(propsData),
    },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    fetchRolesAccess();
  }, []);
  const fetchRolesAccess = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_SETTINGS_FETCH_ROLES_ACCESS);
    if (response.status) {
      setRolesInfo(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    }
    setLoading(false);
  };

  const getColumnReady = () => {
    let newColumn = [];
    columns.forEach((item) => {
      if (item.title === "Role") {
        newColumn.push(item);
      } else {
        let newColData = {
          ...item,
          align: "center",
          dataIndex: "feature_roles",
          render: (data, fullObj) => (
            <Popconfirm
              placement="bottom"
              title={"Roles Update"}
              description={`Are you sure want to update this role`}
              okText="Yes"
              cancelText="No"
              onConfirm={() =>
                item.handleAction({ title: item.title, ...fullObj })
              }
              onCancel={null}
            >
              <Switch
                size="small"
                checked={
                  data.find((role) => role?.user_role?.role_name === item.title)
                    ? true
                    : false
                }
              />
            </Popconfirm>
          ),
        };

        newColumn.push(newColData);
      }
    });
    console.log("column", columns);
    return newColumn;
  };

  const handleRoleChange = async (data) => {
    const roleId = userRole.find((role) => role.role_name === data.title);
    if (roleId) {
      const paylaod = {
        roleId: roleId?.id,
        featureId: data.id,
      };
      console.log("update alc pyload", paylaod);
      const response = await apiPut(
        API_ENDPOINT_SETTINGS_UPDATE_USER_ROLE,
        paylaod
      );
      if (response.status) {
        fetchRolesAccess();
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: response.message, type: 1 },
        });
      } else {
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: response.message, type: 0 },
        });
      }
    }
  };
  return isLoading ? (
    <Loader />
  ) : (
    <div className="RolesAccess">
      <div className="RolesMenus">
        {rolesInfo &&
          rolesInfo.map((role, key) => {
            return (
              <div
                className={
                  "MenuItem " + (selectedModule === key ? "--selected" : "")
                }
                key={key}
                onClick={() => setSelectedModule(key)}
              >
                <Typography variant="caption">{role?.module_name}</Typography>
              </div>
            );
          })}
      </div>
      <div className="TableContainer">
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBorderRadius: 0,
              },
            },
          }}
        >
          <Table
            columns={getColumnReady()}
            dataSource={
              rolesInfo && rolesInfo?.[selectedModule]?.module_features
            }
            size="small"
            bordered
            pagination={false}
            handleAction={handleRoleChange}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};
export default RolesAccess;
