import {
  CheckOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Drawer, Popconfirm, Switch, Tabs } from "antd";
import { ACCOUNT_STATUS } from "app/constants/global.constants";
import { apiGet, apiPost } from "app/services/apiService";
import Button from "app/shared/Button";
import TabBar from "app/shared/TabBar";
import TableView from "app/shared/TableView";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { parseURL } from "app/utils/helpers/common.helper";
import moment from "moment";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  API_ENDPOINT_BROKERS_LIST,
  API_ENDPOINT_USER_UPDATE_ACCOUNT_STATUS,
} from "../../brokers.constants";
import BrokerDetails from "../BorkerDetails";
import "./brokerslist.scss";
import InviteBroker from "./InviteBroker";
import { useNavigate } from "react-router";
import { FeatureFlags } from "app/utils/FeatureFlag/FeatrueFlag";

const tableColumn = [
  {
    title: "",
    renderAction: true,
    handleAction: (propsData) => propsData.handleAction(propsData),
    actions: [
      {
        event: "view_dashboard",
        icon: <OpenInNewIcon style={{ fontSize: 12, color: "white" }} />,
        color: "#73AF00",
        showOn: (data) => data.hasAccess,
        accessKey: "BROKER_ANALYTICS_VIEW",
      },
    ],
  },
  {
    title: "Member Code",
    index: "user_profile.member_id",
    renderTag: true,
  },
  {
    title: "Name",
    index: "user_profile.fullname",
    search: true,
    avatar: "user_profile.avatar",
  },

  {
    title: "Mobile",
    index: "user_profile.mobile",
  },

  {
    title: "City",
    index: "user_profile.city",
  },
  // {
  //   title: "Company",
  //   index: "user_profile.company",
  // },

  {
    title: "Account",
    index: "updatedAt",
    handleAction: (propsData) => propsData.handleAction(propsData),
    child: (childProps) =>
      childProps.status === "ACTIVE" || childProps.status === "DISABLED" ? (
        <Popconfirm
          title={`Are you sure want to ${
            childProps.status === "ACTIVE" ? "disable" : "enable"
          } this account`}
          // description="Are you sure to delete this task?"
          onConfirm={() =>
            childProps.handleSwitch(
              childProps.id,
              childProps.status === "ACTIVE"
                ? ACCOUNT_STATUS.DISABLED
                : ACCOUNT_STATUS.ACTIVE
            )
          }
          onCancel={null}
          okText="Yes"
          cancelText="No"
        >
          <Switch
            size="small"
            checked={childProps.status === "ACTIVE"}
            disabled={childProps.status === "PENDING"}
          />
        </Popconfirm>
      ) : null,
  },
];

const actionColumnObj = {
  title: "Action",
  renderAction: true,
  handleAction: (propsData) => propsData.handleAction(propsData),
  actions: [
    {
      event: "view_detail",
      icon: <SearchOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#69A2FD",
    },
    {
      event: "approve",
      icon: <CheckOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#73AF00",
      popup: true,
      popupTitle: "Are you sure want to approve?",
      showOn: (data) =>
        data.status !== "ACTIVE" && data.status !== "DELETED" && data.hasAccess,
      accessKey: "BROKER_MANAGE",
    },
    {
      event: "delete",
      icon: <DeleteOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#FF0000",
      popup: true,
      popupTitle: "Are you sure want to delete?",
      showOn: (data) => data.status !== "DELETED" && data.hasAccess,
      accessKey: "BROKER_MANAGE",
    },
  ],
};
const LIST_TYPE_MAP = {
  1: "ALL",
  2: "APPROVED",
  3: "PENDING",
  4: "REJECTED",
  5: "INVITED",
};

const dateColumn = [
  {
    title: "Created On",
    index: "createdAt",
    date: true,
    defaultSortOrder: "descend",
    sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
  },
  {
    title: "Approved On",
    index: "updatedAt",
    date: true,
    defaultSortOrder: "descend",
    sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
  },
];

export default function BrokerList(props) {
  const [properties, setProperties] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [brokers, setBrokers] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [showDrawerFor, setShowDrawerFor] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hasAccess } = useContext(FeatureFlags);

  useEffect(() => {
    fetchBrokerList();
  }, [activeTab]);
  const fetchBrokerList = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_BROKERS_LIST +
        "?type=" +
        LIST_TYPE_MAP[activeTab] +
        "&role=Broker"
    );
    if (response.status) {
      setBrokers(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  const handleShowDrawer = (type) => {
    setShowDrawerFor(type);
    setShowDrawer(true);
  };

  const handleTableAction = (event) => {
    console.log(event);
    switch (event.eventType) {
      case "view_detail":
        handleShowDrawer("DETAIL");
        setSelectedUser(event.rowData.id);
        return;
      case "view_dashboard":
        navigate("/brokers/" + event.rowData.id + "/dashboard");
        return;
      case "approve":
        handleUserAccountStatus(event.rowData.id, ACCOUNT_STATUS.ACTIVE);
        return;
      case "delete":
        handleUserAccountStatus(event.rowData.id, ACCOUNT_STATUS.DELETED);
      case "toggle":
        handleUserAccountStatus(event.rowData.id, event.rowData.NEW_STATUS);
        return;
      default:
        return null;
    }
  };

  const handleUserAccountStatus = async (userId, status) => {
    const response = await apiPost(
      parseURL(API_ENDPOINT_USER_UPDATE_ACCOUNT_STATUS, {
        userId: userId,
        status: status,
      })
    );
    if (response.status) {
      fetchBrokerList();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    }
  };

  const items = [
    {
      key: "1",
      label: "All",
      children: (
        <TableView
          data={brokers}
          isLoading={isLoading}
          column={[
            ...tableColumn,
            ...dateColumn,
            {
              title: "Status",
              index: "status",
              renderTag: true,
              filters: [
                {
                  text: "Approved",
                  value: "ACTIVE",
                },
                {
                  text: "Pending",
                  value: "PENDING",
                },
                {
                  text: "Rejected",
                  value: "REJECT",
                },
                {
                  text: "Deleted",
                  value: "DELETED",
                },
                {
                  text: "Disabled",
                  value: "DISABLED",
                },
              ],
              onFilter: (value, record) => record.status.indexOf(value) === 0,
            },
            actionColumnObj,
          ]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      ),
    },
    {
      key: "3",
      label: "Pending",
      children: (
        <TableView
          data={brokers}
          isLoading={isLoading}
          column={[
            ...tableColumn,
            {
              title: "Created On",
              index: "createdAt",
              date: true,
              defaultSortOrder: "descend",
              sorter: (a, b) =>
                moment(a.createdAt).unix() - moment(b.createdAt).unix(),
            },
            ,
            actionColumnObj,
          ]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      ),
    },
    {
      key: "2",
      label: "Approved",
      children: (
        <TableView
          data={brokers}
          isLoading={isLoading}
          column={[...tableColumn, ...dateColumn, actionColumnObj]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      ),
    },

    {
      key: "4",
      label: "Rejected",
      children: (
        <TableView
          data={brokers}
          isLoading={isLoading}
          column={[...tableColumn, ...dateColumn, actionColumnObj]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      ),
    },
    {
      key: "5",
      label: "Invited",
      children: (
        <TableView
          data={brokers}
          isLoading={isLoading}
          column={[
            ...tableColumn,
            {
              title: "Email",
              index: "email",
            },
            actionColumnObj,
          ]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      ),
    },
  ];
  return (
    <div className="BrokersList">
      <div className="TopSelectors" style={{ marginBottom: 20 }}>
        <div className="leftSelectors">
          {hasAccess("BROKER_MANAGE") ? (
            <div className="WidgetContainer" style={{ minWidth: 0 }}>
              <Button
                onClick={() => navigate("/brokers/comissions/pending")}
                background="black"
                size="medium"
              >
                Broker Comissions
              </Button>
            </div>
          ) : null}
        </div>
        <div className="rightSelectors">
          {hasAccess("BROKER_MANAGE") ? (
            <div className="WidgetContainer" style={{ minWidth: 0 }}>
              <Button onClick={() => handleShowDrawer("INVITE")}>
                Invite Broker
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      <div>
        <div id="tabBarContainer">
          <TabBar
            items={items}
            color="red"
            onChange={(tab) => setActiveTab(tab)}
            inActiveColor="gray_dark"
            activeTab={activeTab}
          />
        </div>
      </div>
      <Drawer
        title={showDrawerFor === "INVITE" ? "Invite Broker" : "Broker Details"}
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        width={showDrawerFor === "INVITE" ? 400 : 600}
      >
        {showDrawerFor === "INVITE" ? (
          <InviteBroker
            onInvite={() => setShowDrawer(false)}
            userType="Broker"
          />
        ) : (
          <BrokerDetails selectedUser={selectedUser} type="BROKER" />
        )}
      </Drawer>
    </div>
  );
}
