import { BellFilled, MailFilled } from "@ant-design/icons";
import { Button } from "antd";
import { colorPicker } from "app/utils/helpers/color.helper";
import { useLocation } from "react-router";
import SearchBox from "../SearchBox";
import Typography from "../Typography";
import GlobalSearch from "./GlobalSearch";
import headerConfig from "./header.config";
import "./header.scss";
export default function Header(props) {
  const location = useLocation();

  const headerIndex = Object.keys(headerConfig).findIndex((header) =>
    location.pathname.includes(header)
  );
  console.log("headerINdex", headerIndex);
  return (
    <div className="Header">
      <div className="headerLeft">
        <Typography variant="h5">
          {Object.values(headerConfig)[headerIndex] || "Broker Dashboard"}
        </Typography>
      </div>
      <div className="headerRight">
        <div className="rightItem" style={{ width: 320 }}>
          <GlobalSearch />
        </div>
        <div className="rightItem">
          <Button
            type="primary"
            shape="circle"
            icon={<MailFilled style={{ color: colorPicker("gray_dark") }} />}
            style={{
              backgroundColor: "white",
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            }}
          />
        </div>
        <div className="rightItem">
          <Button
            type="primary"
            shape="circle"
            icon={<BellFilled style={{ color: colorPicker("gray_dark") }} />}
            style={{
              backgroundColor: "white",
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            }}
          />
        </div>
      </div>
    </div>
  );
}
