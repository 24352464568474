import { Form, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  GLOBAL_TIME_FORMAT,
  LEAD_ACTIVITY_TYPES,
} from "app/constants/global.constants";
import Button from "app/shared/Button";
import DateTimePicker from "app/shared/DateTimePicker";
import Input from "app/shared/InputBox";
import Selector from "app/shared/Selector";
import Typography from "app/shared/Typography";
import React, { useState } from "react";
import "../../LeadDetails.scss";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import moment from "moment";
import { apiPost, apiPostForm } from "app/services/apiService";
import { parseURL } from "app/utils/helpers/common.helper";
import {
  API_ENDPOINT_LEAD_ACTIVITY_ADD_ATTACHMENT,
  API_ENDPOINT_LEAD_CREATE_ACTIVITY,
} from "app/scenes/leads/leads.constants";
import { useParams } from "react-router";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { useDispatch } from "react-redux";

export default function LogLeadActivity(props) {
  const [activityType, setActivityType] = useState("");
  const [description, setDescription] = useState("");
  const [logDate, setLogDate] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [form] = Form.useForm();

  let { leadId } = useParams();
  const dispatch = useDispatch();
  const addLog = async () => {
    const payload = {
      type: activityType,
      description: description,
      activity_date: logDate,
      priority: "MEDIUM",
    };
    const response = await apiPost(
      parseURL(API_ENDPOINT_LEAD_CREATE_ACTIVITY, { leadId: leadId }),
      payload
    );
    if (response.status) {
      if (attachment) {
        addAttachment(response.data.id);
      } else {
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: response.message, type: 1 },
        });
        props.onSuccess && props.onSuccess();
      }
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  const clearFormData = () => {
    setActivityType("");
    setDescription("");
    setAttachment(null);
  };
  const addAttachment = async (activityId) => {
    let formData = new FormData();
    formData.append("file", attachment);

    const response = await apiPostForm(
      parseURL(API_ENDPOINT_LEAD_ACTIVITY_ADD_ATTACHMENT, {
        leadId: leadId,
        activityId: activityId,
      }),
      formData
    );
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onSuccess && props.onSuccess();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  return (
    <Form onFinish={addLog}>
      <Form.Item
        name="activityType"
        initialValue={{ description, activityType, logDate }}
        required
      >
        <Selector
          placeholder="Activity Type"
          labelKey="name"
          valueKey="id"
          serviceType="STATIC"
          onChange={(item) => setActivityType(item.target.value)}
          name="saleType"
          height={40}
          fullObject
          staticData={LEAD_ACTIVITY_TYPES}
          value={activityType}
        />
      </Form.Item>

      <Form.Item
        name="description"
        // initialValue={formData.saleType}
        required
      >
        <TextArea
          //   value={value}
          //   onChange={(e) => setValue(e.target.value)}
          placeholder="Activity description here...."
          style={{
            backgroundColor: "#F4F6F9",
            width: "100%",
            border: "none",
            borderRadius: 12,
          }}
          onChange={(e) => setDescription(e.target.value)}
          autoSize={{ minRows: 3, maxRows: 5 }}
          value={description}
        />
      </Form.Item>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Form.Item>
            <DateTimePicker
              onChange={(time, dateString) =>
                setLogDate(moment(dateString).format(GLOBAL_TIME_FORMAT))
              }
            />
          </Form.Item>
          <Upload
            action={
              "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
            }
            beforeUpload={(file) => setAttachment(file)}
          >
            <FileUploadOutlinedIcon
              style={{
                fontSize: 20,
                marginBottom: 24,
                marginLeft: 24,
                cursor: "pointer",
              }}
            />{" "}
          </Upload>
          <Typography
            style={{ marginBottom: 24, marginLeft: 4 }}
            variant="xs"
            color="gray-dark"
          >
            {attachment?.name}
          </Typography>
        </div>

        <Form.Item>
          <Button background="blue" size="medium" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}
