import React, { useEffect, useState } from "react";
import "./EMICalculator.scss";
import EventIcon from "@mui/icons-material/Event";
import Typography from "../Typography";
import { colorPicker } from "app/utils/helpers/color.helper";
import { Form } from "antd";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Input from "../InputBox";
import { FormattedNumber } from "react-intl";
import Button from "../Button";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { API_ENDPOINT_PROPERTY_FETCH_LIST_COMMON } from "app/scenes/property/property.constants";
import { apiGet } from "app/services/apiService";
import { ADD_TOAST_MESSAGE } from "../Toast/toast.constants";
import { useDispatch } from "react-redux";
import Selector from "../Selector";
export default function EMICalculator(props) {
  const [formData, setFormData] = useState({});
  const [showUpfront, setShowUpFront] = useState(false);
  const [drillDown, setDrillDown] = useState(false);
  const [feeStructure, setFeeStructure] = useState(null);
  const [montlyEMI, setMontlyEMI] = useState(0);
  const [structureData, setStructureData] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.propertyInfo) {
      let formObject = {
        total_cost: props.propertyInfo?.total_cost,
        emi_tenure: props.propertyInfo?.emi_tenure || 20,
        emi_downpayment: props.propertyInfo?.downpayment
          ? props.propertyInfo?.downpayment
          : (20 / 100) * parseInt(props.propertyInfo?.total_cost),
        intereset: props.propertyInfo?.interest || 7.1,
        emi: props.propertyInfo?.emi || 0,
        bank: props.propertyInfo?.bank,
      };
      setFormData(JSON.parse(JSON.stringify(formObject)));
      fetchPropertyFeeStructure(formObject);
    }
  }, []);
  const handleApplyPlan = () => {
    props.applyPlan({
      ...formData,
      emi: montlyEMI,
    });
  };

  useEffect(() => {
    if (!formData.total_cost || !formData.emi_tenure || !formData.intereset)
      return;
    const amountAfterDownpayment =
      parseFloat(formData.total_cost) - parseFloat(formData.emi_downpayment);

    const months = parseInt(formData.emi_tenure) * 12;
    // Calculating interest per month
    const interest =
      (amountAfterDownpayment * (parseFloat(formData.intereset) * 0.01)) /
      months;

    // Calculating total payment
    const total = (amountAfterDownpayment / months + interest).toFixed(2);
    setMontlyEMI(total);
    if (structureData) calculateFeeStructure(structureData);
  }, [formData]);

  const fetchPropertyFeeStructure = async (initialFormData = formData) => {
    const response = await apiGet(
      API_ENDPOINT_PROPERTY_FETCH_LIST_COMMON + "?service=PURCHASE_FEES"
    );
    if (response.status) {
      setStructureData(response.data);
      calculateFeeStructure(response.data, initialFormData);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  const calculateFeeStructure = (data, initialForm = formData) => {
    let feeInfo = {
      upfront: 0,
      total_payable: 0,
      hidden_costs: [],
    };

    data.forEach((feeType) => {
      let pushableObject = {
        name: feeType.name,
        description: feeType.description,
        fee: 0,
      };
      let chargableAmount =
        feeType.chargeType === "PROPERTY_VALUE"
          ? parseFloat(initialForm.total_cost)
          : feeType.chargeType === "FIXED"
          ? parseFloat(feeType.amount)
          : parseFloat(initialForm.total_cost) -
            parseFloat(initialForm.emi_downpayment);
      console.log("chargable fee", chargableAmount);
      let percentageFee = 0;
      if (feeType.percentage) {
        percentageFee = parseFloat(
          (feeType.percentage / 100) * chargableAmount
        ).toFixed(2);
        pushableObject.fee = parseFloat(pushableObject.fee) + percentageFee;

        pushableObject.fee =
          parseFloat(pushableObject.fee) +
          (feeType.amount ? parseFloat(feeType.amount) : 0);
      }

      if (feeType.additional_tax_on === "PERCENTAGE") {
        pushableObject.fee =
          parseFloat(pushableObject.fee) +
          parseFloat((feeType.additional_tax / 100) * percentageFee);
      }

      if (feeType.additional_tax_on === "AMOUNT") {
        pushableObject.fee =
          parseFloat(pushableObject.fee) +
          parseFloat(
            (feeType.additional_tax / 100) * parseFloat(feeType.amount)
          );
      }

      if (feeType.chargeType === "FIXED") {
        pushableObject.fee =
          parseFloat(pushableObject.fee) +
          (feeType.amount ? parseFloat(feeType.amount) : 0);
      }

      feeInfo.hidden_costs.push(pushableObject);
      feeInfo.total_payable += parseFloat(pushableObject.fee);
    });
    feeInfo.upfront =
      parseFloat(feeInfo.total_payable) +
      parseFloat(initialForm.emi_downpayment);
    setFeeStructure(feeInfo);
  };

  const handleInputChange = (e) => {
    const { name, value, obj } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "bank") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ["intereset"]: obj.loan_interest,
      }));
    }
  };
  return (
    <div className="EMICalculator">
      <div className="Title">
        {/* <div></div> */}
        <EventIcon style={{ fontSize: 24, color: colorPicker("red") }} />
        <div className="TitleText">
          <Typography style={{ marginLeft: 4 }} color="black" variant="h6">
            EMI Calculator
          </Typography>
        </div>
      </div>
      <div style={{ marginTop: 24 }}>
        <Typography
          variant="body"
          weight={400}
          style={{ marginLeft: 4, marginBottom: 4 }}
        >
          Bank
        </Typography>
        <Selector
          placeholder="Bank"
          labelKey="name"
          valueKey="id"
          serviceType="BANKS"
          onChange={handleInputChange}
          name="bank"
          fullObject
          defaultValue={formData.bank}
        />
      </div>
      <Form
        name="emi_ssf"
        fields={[
          {
            name: ["total_cost"],
            value: formData.total_cost,
          },
          {
            name: ["emi_tenure"],
            value: formData.emi_tenure,
            errors:
              parseInt(formData.emi_tenure) === 0
                ? ["EMI tenure cannot be 0"]
                : [],
          },
          {
            name: ["emi_downpayment"],
            value: formData.emi_downpayment,
            errors:
              parseFloat(formData.emi_downpayment) <
              (20 / 100) * parseFloat(formData.total_cost)
                ? ["Downlpayment should be min 20%"]
                : parseFloat(formData.emi_downpayment) >
                  parseFloat(formData.total_cost)
                ? ["Downpayment should not exceed total property cost"]
                : [],
          },
          {
            name: ["intereset"],
            value: formData.intereset,
          },
        ]}
        // onFinish={handleApplyPlan}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="PlanForm">
          <div className="formInputContainer">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please check your input",
                },
              ]}
              name="total_cost"
            >
              <Input
                placeholder="Property Price"
                label="Property Price"
                background="white"
                name="total_cost"
                onChange={handleInputChange}
                borderColor="black"
                defaultValue={formData.total_cost}
                disabled
                prefix={
                  <CurrencyRupeeIcon
                    style={{ fontSize: 16, color: colorPicker("gray_dark") }}
                  />
                }
              />
            </Form.Item>
          </div>
          <div className="formInputContainer">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please check your input",
                },
              ]}
              name="emi_tenure"
            >
              <Input
                placeholder="Loan Term"
                label="Loan Term (Yr)"
                background="white"
                name="emi_tenure"
                onChange={handleInputChange}
                borderColor="black"
                defaultValue={formData.emi_tenure}
                max={25}
                maxLength={2}
              />
            </Form.Item>
          </div>

          <div className="formInputContainer">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please check your input",
                },
              ]}
              name="emi_downpayment"
            >
              <Input
                placeholder="Downpayment"
                label="Downpayment (min 20%)"
                background="white"
                name="emi_downpayment"
                onChange={handleInputChange}
                borderColor="black"
                defaultValue={formData.emi_downpayment}
                prefix={
                  <CurrencyRupeeIcon
                    style={{ fontSize: 16, color: colorPicker("gray_dark") }}
                  />
                }
              />
            </Form.Item>
          </div>

          <div className="formInputContainer">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please check your input",
                },
              ]}
              name="intereset"
            >
              <Input
                placeholder="Interest Rate"
                label="Interest Rate %"
                background="white"
                name="intereset"
                onChange={handleInputChange}
                borderColor="black"
                defaultValue={formData.intereset}
                max={25}
                maxLength={4}
              />
            </Form.Item>
          </div>
        </div>

        <div className="PlanInfo">
          <div className="PaymentInfo">
            <Typography>Monthly Payment</Typography>
            <Typography variant="h6">
              <b style={{ fontWeight: 700, color: colorPicker("red") }}>
                <FormattedNumber
                  value={
                    parseFloat(montlyEMI) < 0
                      ? 0
                      : parseFloat(montlyEMI) === Infinity
                      ? 0
                      : montlyEMI
                  }
                  style="currency"
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                  currency={"INR"}
                />
              </b>
            </Typography>

            <Typography
              variant="caption"
              color="gray-dark"
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => setShowUpFront(!showUpfront)}
            >
              {showUpfront ? "hide upfront cost" : "show upfront cost"}
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            {/* <Form.Item style={{ width: 200, marginRight: 8 }}>
              <Button
                type="primary"
                size="large"
                style={{ width: "100%" }}
                //   htmlType="submit"
                //   loading={isLoading}
                background="black"
                onClick={calculateEMI}
              >
                Calculate EMI
              </Button>
            </Form.Item> */}
            <Form.Item style={{ width: 200 }}>
              <Button
                type="primary"
                size="large"
                style={{ width: "100%" }}
                //   htmlType="submit"
                //   loading={isLoading}
                onClick={handleApplyPlan}
              >
                Apply Plan
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
      {showUpfront ? (
        <div className="UpFrontContainer">
          <Typography style={{ textAlign: "center" }}>Loan summary</Typography>
          <div className="UpFrontPayment">
            <Typography>Amount required upfront</Typography>

            <Typography>
              <b style={{ fontWeight: 700, color: colorPicker("red") }}>
                <FormattedNumber
                  value={feeStructure?.upfront}
                  style="currency"
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                  currency={"INR"}
                />
              </b>
            </Typography>
          </div>
          <div className="BreakdownPayment">
            <div>
              <Typography style={{ textAlign: "center" }}>
                Downpayment amount
              </Typography>
              <Typography color="gray-dark" variant="xs">
                20% of the property value
              </Typography>
            </div>
            <Typography>
              <b style={{ fontWeight: 700, color: colorPicker("red") }}>
                <FormattedNumber
                  value={formData.emi_downpayment}
                  style="currency"
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                  currency={"INR"}
                />
              </b>
            </Typography>
          </div>
          <div className="BreakdownPayment">
            <div>
              <Typography>Total purchase costs</Typography>
              <Typography color="gray-dark" variant="xs">
                One time cost and fees payable when purchasing a property in the
                INR
              </Typography>
            </div>
            <div style={{ display: "flex" }}>
              <Typography>
                <b style={{ fontWeight: 700, color: colorPicker("red") }}>
                  <FormattedNumber
                    value={feeStructure?.total_payable}
                    style="currency"
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                    currency={"INR"}
                  />
                </b>
              </Typography>
              {drillDown ? (
                <ExpandLessRoundedIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setDrillDown(false)}
                />
              ) : (
                <ExpandMoreRoundedIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setDrillDown(true)}
                />
              )}
            </div>
          </div>
          {drillDown ? (
            <>
              <div className="UpFrontPayment" style={{ marginTop: 12 }}>
                <Typography>Costs and fees breakdown</Typography>
              </div>
              {feeStructure.hidden_costs &&
                feeStructure.hidden_costs.map((fee, key) => {
                  return (
                    <div className="BreakdownPayment" key={key}>
                      <div>
                        <Typography>{fee.name}</Typography>
                        <Typography color="gray-dark" variant="xs">
                          {fee.description}
                        </Typography>
                      </div>
                      <Typography>
                        <b
                          style={{ fontWeight: 700, color: colorPicker("red") }}
                        >
                          <FormattedNumber
                            value={fee.fee}
                            style="currency"
                            minimumFractionDigits={0}
                            maximumFractionDigits={0}
                            currency={"INR"}
                          />
                        </b>
                      </Typography>
                    </div>
                  );
                })}
            </>
          ) : null}
        </div>
      ) : null}
      <div className="Lenders">
        <Typography
          variant="xs"
          color="gray-dark"
          style={{ marginBottom: 8, textAlign: "center" }}
        >
          Our Lending Partners
        </Typography>
        <div className="LenderList">
          <div className="Lender">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSThUX5QRk0A78vCDeVMNc_vuOP3MqJQhaSvQ&usqp=CAU    "
              alt="LenderImg"
            />
          </div>
          <div className="Lender">
            <img
              src="https://i.pinimg.com/originals/22/ea/e8/22eae82bd07b6e84f8c3f93d981b8c51.jpg    "
              alt="LenderImg"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
