import Typography from "app/shared/Typography";
import React, { useContext, useState } from "react";
import "./LeavesDashboard.scss";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Button from "app/shared/Button";
import { colorPicker } from "app/utils/helpers/color.helper";
import { ConfigProvider, Drawer, Modal, Table } from "antd";
import LeaveRequests from "./components/LeaveRequests";
import { apiGet } from "app/services/apiService";
import { parseURL } from "app/utils/helpers/common.helper";
import {
  API_ENDPOINT_LEAVE_FETCH_APPROVAL_LIST,
  API_ENDPOINT_LEAVE_FETCH_GIST,
  API_ENDPOINT_LEAVE_FETCH_HISTORY,
} from "../../leaves.constants";
import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { useEffect } from "react";
import Loader from "app/shared/Loader";
import TableView from "app/shared/TableView";
import { FeatureFlags } from "app/utils/FeatureFlag/FeatrueFlag";
import CreateLeave from "./components/CreateLeave";
import TabBar from "app/shared/TabBar";
import moment from "moment";

const columns = [
  {
    title: "Type",
    index: "type",
    key: "type",
    renderTag: true,
    tagColorMap: { PAID: "green", UNPAID: "red" },
  },
  {
    title: "From",
    index: "from",
    key: "from",
    date: true,
    defaultSortOrder: "descend",
    sorter: (a, b) => moment(a.from).unix() - moment(b.from).unix(),
  },
  {
    title: "To",
    index: "to",
    key: "to",
    date: true,
    defaultSortOrder: "descend",
    sorter: (a, b) => moment(a.to).unix() - moment(b.to).unix(),
  },
  {
    title: "No of Days",
    index: "days",
    key: "days",
  },
  {
    title: "Applicant Name",
    index: "applicant.user_profile.first_name",
    key: "applicant.user_profile.first_name",
    customRender: (data) => {
      return `${data?.applicant?.user_profile?.first_name} ${data?.applicant?.user_profile?.last_name}`;
    },
  },
  {
    title: "Status",
    index: "status",
    key: "status",
    renderTag: true,
    tagColorMap: {
      APPROVED: "green",
      CANCELLED: "red",
      REJECTED: "red",
      PENDING: "yellow",
    },
  },
  {
    title: "Approved By",
    index: "approver.user_profile.first_name",
    customRender: (data) => {
      return `${data?.approver?.user_profile?.first_name} ${data?.approver?.user_profile?.last_name}`;
    },
  },
  {
    title: "Comment",
    index: "comment",
  },
];

export default function LeavesDashboard(props) {
  const [showModal, setShowModal] = useState(false);
  const [leaveGist, setLeaveGist] = useState(null);
  const [leaveHistory, setLeaveHistory] = useState([]);
  const [approvalList, setApprovalList] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [activeTab, setActiveTab] = useState(1);

  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { hasAccess } = useContext(FeatureFlags);

  useEffect(() => {
    fetchLeaveGist();
    fetchApprovalList();
  }, []);

  useEffect(() => {
    fetchLeaveHistory(true);
  }, [activeTab]);
  const fetchLeaveGist = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_LEAVE_FETCH_GIST);
    if (response.status) {
      setLeaveGist(response.data);
      fetchLeaveHistory();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
      setLoading(false);
    }
  };

  const fetchLeaveHistory = async (loading = false) => {
    const response = await apiGet(
      API_ENDPOINT_LEAVE_FETCH_HISTORY +
        (activeTab > 1 ? "?filter=APPROVED_BY" : "")
    );
    if (response.status) {
      setLeaveHistory(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  const fetchApprovalList = async () => {
    const response = await apiGet(API_ENDPOINT_LEAVE_FETCH_APPROVAL_LIST);
    if (response.status) {
      setApprovalList(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="LeavesDashboard">
      <div className="TopGistContainer">
        <div className="GistCard">
          <div className="NumContainer">
            <Typography variant="h5" color="blue">
              {leaveGist && leaveGist?.available?.total}
            </Typography>
          </div>
          <div>
            <Typography variant="h6" style={{ marginLeft: 12 }}>
              Available Leaves
            </Typography>
            <Typography
              variant="xs"
              color="gray-dark"
              style={{ marginLeft: 12 }}
            >
              Paid{" "}
              <strong style={{ color: colorPicker("green") }}>
                {" "}
                {leaveGist && leaveGist?.available?.paid}
              </strong>{" "}
              Unpaid
              <strong style={{ color: colorPicker("red") }}>
                {" "}
                {leaveGist && leaveGist?.available?.unpaid}
              </strong>
            </Typography>
          </div>
        </div>
        <div className="GistCard">
          <div className="NumContainer">
            <Typography variant="h5" color="blue">
              {leaveGist && leaveGist?.caried}
            </Typography>
          </div>
          <div>
            <Typography variant="h6" style={{ marginLeft: 12 }}>
              Carried Over Leaves
            </Typography>
            <Typography
              variant="xs"
              color="gray-dark"
              style={{ marginLeft: 12 }}
            >
              Including previous year
            </Typography>
          </div>
        </div>

        <div className="GistCard">
          <div className="NumContainer --yellow">
            <Typography variant="h5" color="yellow">
              {leaveGist && leaveGist?.pending}
            </Typography>
          </div>
          <div>
            <Typography variant="h6" style={{ marginLeft: 12 }}>
              Pending Leaves
            </Typography>
            <Typography
              variant="xs"
              color="gray-dark"
              style={{ marginLeft: 12 }}
            >
              awaiting approval
            </Typography>
          </div>
        </div>

        <div className="GistCard">
          <div className="NumContainer --red">
            <Typography variant="h5" color="red">
              {leaveGist && leaveGist?.rejected?.total}
            </Typography>
          </div>
          <div>
            <Typography variant="h6" style={{ marginLeft: 12 }}>
              Rejected Leaves
            </Typography>
            <Typography
              variant="xs"
              color="gray-dark"
              style={{ marginLeft: 12 }}
            >
              Last rejected {leaveGist && leaveGist?.rejected?.last_rejected}
            </Typography>
          </div>
        </div>
      </div>
      {/* <div className="AbsentInfoCard">
        <div className="LeftCard">
          <ErrorOutlineOutlinedIcon
            style={{ color: colorPicker("red"), marginRight: 8 }}
          />
          <Typography>You were absent on last thursday</Typography>
        </div>
        <div style={{ width: 140 }}>
          <Button size="medium">Report</Button>
        </div>
      </div> */}
      {hasAccess("LEAVE_MANAGE") && approvalList.length > 0 ? (
        <div className="AbsentInfoCard">
          <div className="LeftCard">
            <ErrorOutlineOutlinedIcon
              style={{ color: colorPicker("yellow"), marginRight: 8 }}
            />
            <Typography>
              You have <strong>{approvalList.length}</strong> leave request to
              approve
            </Typography>
          </div>
          <div style={{ width: 140 }}>
            <Button size="medium" onClick={() => setShowModal(true)}>
              View
            </Button>
          </div>
        </div>
      ) : null}

      <div className="TableContainer">
        <div className="TableHeader">
          <Typography variant="h6">Leaves History</Typography>
          <div style={{ width: 140 }}>
            <Button size="medium" onClick={() => setShowDrawer(true)}>
              Apply
            </Button>
          </div>
        </div>
        <div>
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBorderRadius: 0,
                },
              },
            }}
          >
            {hasAccess("LEAVE_MANAGE") ? (
              <TabBar
                color="red"
                onChange={(tab) => setActiveTab(tab)}
                activeTab={activeTab}
                items={[
                  {
                    label: "Mine",
                    key: 1,
                    children: (
                      <TableView
                        column={columns}
                        data={leaveHistory || []}
                        size="small"
                        bordered
                        rowClassName="green"
                      />
                    ),
                  },
                  {
                    label: "Approved/Rejected By Me",
                    key: 2,
                    children: (
                      <TableView
                        column={columns}
                        data={leaveHistory || []}
                        size="small"
                        bordered
                        rowClassName="green"
                      />
                    ),
                  },
                ]}
              />
            ) : (
              <TableView
                column={columns}
                data={leaveHistory || []}
                size="small"
                bordered
                rowClassName="green"
              />
            )}
          </ConfigProvider>
        </div>
      </div>
      <Modal
        title="Leave Requests"
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        width={1000}
      >
        <LeaveRequests data={approvalList} onSuccess={fetchApprovalList} />
      </Modal>

      <Drawer
        title="Create Leave Request"
        placement={"right"}
        width={500}
        onClose={() => setShowDrawer(false)}
        open={showDrawer}
      >
        <CreateLeave
          onSuccess={() => {
            setShowDrawer(false);
            fetchLeaveGist();
          }}
        />
      </Drawer>
    </div>
  );
}
