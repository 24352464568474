import {
  CheckOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { Drawer, Modal, Popconfirm, Switch, Tabs, Tag } from "antd";
import { ACCOUNT_STATUS } from "app/constants/global.constants";
import { apiDelete, apiGet, apiPost, apiPut } from "app/services/apiService";
import Button from "app/shared/Button";
import CreateInternalUser from "app/shared/InternalUserCreate";
import TabBar from "app/shared/TabBar";
import TableView from "app/shared/TableView";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { FeatureFlags } from "app/utils/FeatureFlag/FeatrueFlag";
import { exportToExcel, parseURL } from "app/utils/helpers/common.helper";

import { useContext, useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  API_ENDPOINT_GLOBAL_DELETE_AMENTITY,
  API_ENDPOINT_GLOBAL_DELETE_PROPERTY_TYPE,
  API_ENDPOINT_GLOBAL_FETCH_AMENTITY_LIST,
  API_ENDPOINT_GLOBAL_LIST_PROPERTY_TYPE,
  API_ENDPOINT_GLOBAL_UPDATE_MASTER_DATA_STATUS,
  API_ENDPOINT_PROJECT_INVESTORS_LIST,
  API_ENDPOINT_PROPERTY_COMMUNITY_DELETE,
  API_ENDPOINT_PROPERTY_DEVELOPER_UPDATE,
  API_ENDPOINT_PROPERTY_FETCH_LIST_COMMON,
  API_ENDPOINT_PROPERTY_FETCH_SALES_HISTORY,
} from "../../property.constants";
import { DownloadOutlined } from "@ant-design/icons";

import "./PropertyTypes.scss";
import AddAmentity from "./components/AddPropertyType";
import EditPropertyTypes from "./components/AddPropertyType/EditPropertyTypes";
import PropertyCancel from "./components/PropertyCancel";

const tableColumn = [
  {
    title: "Property ID",
    index: "property.property_code",
    renderTag: true,
    search: true,
  },
  {
    title: "Property Name",
    index: "property.name",
    search: true,
  },
  {
    title: "Property Type",
    index: "property.property_type.name",
  },
  {
    title: "Project",
    index: "property.property_project.name",
    search: true,
    renderTag: true,
  },
  {
    title: "Property Cost",
    index: "property.cost",
    amount: true,
  },
  {
    title: "Sale Date",
    index: "createdAt",
    date: true,
  },
  {
    title: "Sale Made By",
    index: "sell_made_by.user_profile.member_id",
    renderTag: true,
    search: true,
  },
];

const LIST_TYPE_MAP = {
  1: "ALL",
  2: "APPROVED",
  3: "PENDING",
  4: "REJECTED",
  5: "INVITED",
};

export default function PropertySales(props) {
  const [isLoading, setLoading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [amentities, setAmentities] = useState([]);
  const [selectedData, setSelectedData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const dispatch = useDispatch();
  const { hasAccess } = useContext(FeatureFlags);

  useEffect(() => {
    fetchAmentityList();
  }, [activeTab]);
  const fetchAmentityList = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_PROPERTY_FETCH_SALES_HISTORY +
        (activeTab === "1" ? "COMPLETED" : "CANCELLED")
    );
    if (response.status) {
      setAmentities(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  const handleShowDrawer = (type) => {
    // setShowDrawerFor(type);
    setShowDrawer(true);
  };

  const handleCancelSales = async (data) => {
    console.log("data is data", data);
    setSelectedData(data?.id);
    setShowModal(true);
  };

  const actionColumnObj = {
    title: "Action",
    renderAction: true,
    handleAction: (propsData) => propsData.handleAction(propsData),
    customRender: (data) => {
      console.log(data);
      return data.status === "CANCELLED" ? null : (
        <Popconfirm
          title="Are you sure want to cancel this sales?"
          onConfirm={() => handleCancelSales(data)}
        >
          <Button size="small">Cancel Sale</Button>
        </Popconfirm>
      );
    },
  };

  const handleDeleteAmentity = async (typeId) => {
    const response = await apiDelete(
      API_ENDPOINT_PROPERTY_COMMUNITY_DELETE + typeId
    );
    if (response.status) {
      fetchAmentityList();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    }
  };

  const handleTableAction = (event) => {
    switch (event.eventType) {
      case "delete":
        handleDeleteAmentity(event.rowData.id);
        return;
      case "edit":
        setSelectedData(event.rowData);
        setShowDrawer(true);
        return;
      case "toggle":
        handleUserAccountStatus(event.rowData.id, event.rowData.NEW_STATUS);
        return;
      default:
        return null;
    }
  };

  const items = [
    {
      key: "1",
      label: "Confirmed Sales",
      children: (
        <TableView
          data={amentities}
          isLoading={isLoading}
          column={[...tableColumn, actionColumnObj]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      ),
    },
    {
      key: "2",
      label: "Reversed Sales",
      children: (
        <TableView
          data={amentities}
          isLoading={isLoading}
          column={[
            ...tableColumn,
            { title: "Cancellation Date", index: "updatedAt", date: true },
          ]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      ),
    },
  ];

  const handleUserAccountStatus = async (userId, status) => {
    const response = await apiPut(
      parseURL(API_ENDPOINT_GLOBAL_UPDATE_MASTER_DATA_STATUS, {
        masterDataId: userId,
        statusId: status,
      }) + "?serviceType=PROPERTY_TYPES"
    );
    if (response.status) {
      fetchAmentityList();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    }
  };

  return (
    <div className="PromoterList">
      <div className="TopSelectors">
        <div className="leftSelectors"></div>
        <div className="rightSelectors">
          <div style={{ width: 200 }}>
            <Button
              size="small"
              background="black"
              icon={<DownloadOutlined />}
              onClick={() =>
                exportToExcel(amentities, [
                  ...tableColumn,
                  {
                    title: "Cancellation Date",
                    index: "updatedAt",
                    date: true,
                  },
                ])
              }
            >
              Download
            </Button>
          </div>
        </div>
      </div>
      <div id="tabBarContainer">
        <TabBar
          items={items}
          color="red"
          onChange={(tab) => setActiveTab(tab)}
          inActiveColor="gray_dark"
          activeTab={activeTab}
        />
      </div>

      <Modal
        title={"Cancellation Policy"}
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
        width={600}
      >
        <PropertyCancel
          selectedProperty={selectedData}
          cancel={() => setShowModal(false)}
          onSuccess={() => {
            fetchAmentityList();
            setShowModal(false);
          }}
        />
      </Modal>
    </div>
  );
}
