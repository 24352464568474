import { Form, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  GLOBAL_TIME_FORMAT,
  LEAD_ACTIVITY_TYPES,
} from "app/constants/global.constants";
import Button from "app/shared/Button";
import DateTimePicker from "app/shared/DateTimePicker";
import Input from "app/shared/InputBox";
import Selector from "app/shared/Selector";
import Typography from "app/shared/Typography";
import React, { useEffect, useState } from "react";
import "../../LeadDetails.scss";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import moment from "moment";
import { apiPost, apiPut } from "app/services/apiService";
import { parseURL } from "app/utils/helpers/common.helper";
import {
  API_ENDPOINT_LEAD_ACTIVITY_ADD_ATTACHMENT,
  API_ENDPOINT_LEAD_CREATE_ACTIVITY,
  API_ENDPOINT_LEAD_UPDATE_DETAILS,
} from "app/scenes/leads/leads.constants";
import { useParams } from "react-router";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { useDispatch } from "react-redux";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { colorPicker } from "app/utils/helpers/color.helper";
import TagInput from "app/shared/TagInput";

export default function LeadUpdate(props) {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (props.data) handleInitFormData();
  }, [props.data]);

  let { leadId } = useParams();
  const dispatch = useDispatch();

  const handleInitFormData = () => {
    let data = {
      first_name: props.data?.first_name,
      last_name: props.data?.last_name,
      mobile: props.data?.mobile,
      email: props.data?.email,
      alternate_mobile: props.data?.alternate_mobile,
      company: props.data?.company,
    };
    setFormData(JSON.parse(JSON.stringify(data)));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleUpdateClientInfo = async () => {
    const payload = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      mobile: formData.mobile,
      alternate_mobile: formData.alternate_mobile,
      email: formData.email,
      company: formData.company,
    };
    console.log("payload:", payload);

    const response = await apiPut(
      API_ENDPOINT_LEAD_UPDATE_DETAILS + leadId,
      payload
    );
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onSuccess && props.onSuccess();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  const getLikedProperrty = (data) => {
    let codes = [];
    data.forEach((propertyData) => {
      codes.push("#PROP-" + propertyData?.property?.property_unique_code);
    });
    return codes;
  };

  return (
    <Form
      fields={[
        {
          name: ["first_name"],
          value: formData.first_name,
        },
        {
          name: ["last_name"],
          value: formData.last_name,
        },
        {
          name: ["mobile"],
          value: formData.mobile,
        },
        {
          name: ["alternate_mobile"],
          value: formData.alternate_mobile,
        },
        {
          name: ["email"],
          value: formData.email,
        },
        {
          name: ["company"],
          value: formData.company,
        },
      ]}
      onFinish={handleUpdateClientInfo}
    >
      <Form.Item
        name="first_name"
        // initialValue={formData.saleType}
        required
      >
        <Input
          placeholder="First Name"
          onChange={handleInputChange}
          name="first_name"
          defaultValue={formData.first_name}
        />
      </Form.Item>
      <Form.Item
        name="last_name"
        // initialValue={formData.saleType}
        required
      >
        <Input
          placeholder="Last Name"
          onChange={handleInputChange}
          name="last_name"
          defaultValue={formData.last_name}
        />
      </Form.Item>

      <Form.Item
        name="mobile"
        // initialValue={formData.saleType}
        required
        rules={[
          {
            required: true,
            min: 10,
            message: "Please check your mobile",
          },
        ]}
      >
        <Input
          placeholder="Mobile"
          onChange={handleInputChange}
          name="mobile"
          defaultValue={formData.mobile}
          maxLength={10}
        />
      </Form.Item>

      <Form.Item
        name="alternate_mobile"
        // initialValue={formData.saleType}
        required
        rules={[
          {
            min: 10,
            message: "Please check your mobile",
          },
        ]}
      >
        <Input
          placeholder="Alternamte Mobile"
          onChange={handleInputChange}
          name="alternate_mobile"
          defaultValue={formData.alternate_mobile}
          maxLength={10}
        />
      </Form.Item>
      <Form.Item
        name="email"
        // initialValue={formData.saleType}
        required
      >
        <Input
          placeholder="Email"
          onChange={handleInputChange}
          name="email"
          defaultValue={formData.email}
        />
      </Form.Item>
      <Form.Item
        name="company"
        // initialValue={formData.saleType}
        required
      >
        <Input
          placeholder="Company"
          onChange={handleInputChange}
          name="company"
          defaultValue={formData.company}
        />
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit">Update</Button>
      </Form.Item>
    </Form>
  );
}
