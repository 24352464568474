import React, { useState } from "react";
import { message, Modal, Upload } from "antd";
import Typography from "../Typography";
import { colorPicker } from "app/utils/helpers/color.helper";
import { CloseOutlined } from "@ant-design/icons";

const SimpleFileSelector = (props) => {
  const [uploadedFile, setUploadedFile] = useState(props.defaultValue || null);
  const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
    }
    setUploadedFile(file);
    handleUpload(file);
    return isLt2M;
  };

  const handleUpload = (file) => {
    if (props.name) {
      props.onUpload &&
        props.onUpload({ target: { name: props.name, value: file } });
    } else {
      props.onUpload && props.onUpload(file);
    }
  };
  return (
    <div
      className="SimpleFileSelector"
      style={{ width: "100%", display: "flex" }}
    >
      {uploadedFile ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "white",
            width: "100%",
            padding: 10,
            borderRadius: 12,
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "flex",
              backgroundColor: colorPicker("black"),
              borderRadius: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 30,
              minWidth: 100,
              paddingLeft: 6,
              paddingRight: 6,
            }}
          >
            <Typography
              variant="xs"
              color="white"
              style={{ width: 140 }}
              wrapped
            >
              {uploadedFile.name}
            </Typography>
            <CloseOutlined
              style={{
                color: colorPicker("white"),
                fontSize: 12,
                marginLeft: 6,
                cursor: "pointer",
              }}
              onClick={() => setUploadedFile(null)}
            />
          </div>
        </div>
      ) : (
        <Upload
          action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
          listType="picture-card"
          fileList={[]}
          // onPreview={handlePreview}
          // onChange={handleChange}
          beforeUpload={beforeUpload}
          style={{ width: "100%", height: 88 }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "white",
              width: "100%",
              padding: 10,
              borderRadius: 12,
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                width: 100,
                height: 30,
                borderRadius: 8,
                backgroundColor: colorPicker("gray_light"),
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="xs" color="gray-dark">
                Choose File
              </Typography>
            </div>
            <Typography
              style={{ marginLeft: 8 }}
              variant="xs"
              color="gray-dark"
            >
              No file chosen
            </Typography>
          </div>
        </Upload>
      )}
    </div>
  );
};
export default SimpleFileSelector;
