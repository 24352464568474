import {
  CheckOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  SearchOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { Drawer, Popconfirm, Switch, Tabs, Tag } from "antd";
import { ACCOUNT_STATUS } from "app/constants/global.constants";
import { apiGet, apiPost, apiPut } from "app/services/apiService";
import Button from "app/shared/Button";
import TabBar from "app/shared/TabBar";
import TableView from "app/shared/TableView";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { exportToExcel, parseURL } from "app/utils/helpers/common.helper";
import moment from "moment";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  API_ENDPOINT_BROKERS_LIST,
  API_ENDPOINT_BROKERS_PENDING_COMISSIONS,
  API_ENDPOINT_USER_UPDATE_ACCOUNT_STATUS,
  API_ENDPOINT_USER_UPDATE_PENDING_COMISSION_STATUS,
} from "../../../brokers.constants";
import BrokerDetails from "../../BorkerDetails";
import "./brokerslist.scss";
import { useNavigate } from "react-router";
import { FeatureFlags } from "app/utils/FeatureFlag/FeatrueFlag";

const tableColumn = [
  {
    title: "Member Code",
    index: "user.user_profile.member_id",
    renderTag: true,
  },
  {
    title: "Name",
    index: "user.user_profile.first_name",
    search: true,
    avatar: "user.user_profile.avatar",
  },

  {
    title: "Mobile",
    index: "user.user_profile.mobile",
  },

  {
    title: "Comission Amount",
    index: "amount",
    amount: true,
  },
  {
    title: "Level 2 Earning",
    index: "level_one_earning",
    amount: true,
  },
  {
    title: "Level 3 Earning",
    index: "level_two_earning",
    amount: true,
  },
  {
    title: "Property ID",
    customRender: (data) => {
      return <Tag>#PROP-{data?.property?.property_unique_code}</Tag>;
    },
  },
  {
    title: "Property Cost",
    index: "property.cost",
    amount: true,
  },
  // {
  //   title: "Company",
  //   index: "user_profile.company",
  // },
];

const actionColumnObj = {
  title: "Action",
  renderAction: true,
  handleAction: (propsData) => propsData.handleAction(propsData),
  actions: [
    {
      event: "approve",
      icon: <CheckOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#73AF00",
      popup: true,
      popupTitle: "Are you sure want to approve?",
      accessKey: "BROKER_MANAGE",
    },
    {
      event: "delete",
      icon: <CloseCircleOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#FF0000",
      popup: true,
      popupTitle: "Are you sure want to reject?",
      accessKey: "BROKER_MANAGE",
    },
  ],
};
const LIST_TYPE_MAP = {
  1: "ALL",
  2: "APPROVED",
  3: "PENDING",
  4: "REJECTED",
  5: "INVITED",
};

export default function PendingComissionList(props) {
  const [properties, setProperties] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [brokers, setBrokers] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [showDrawerFor, setShowDrawerFor] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hasAccess } = useContext(FeatureFlags);

  useEffect(() => {
    fetchPendingBrokerComissions();
    console.log("Active tab", activeTab);
  }, [activeTab]);
  const fetchPendingBrokerComissions = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_BROKERS_PENDING_COMISSIONS +
        (activeTab === "1" ? "PENDING" : "REVERSAL_INITIATED")
    );
    if (response.status) {
      setBrokers(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  const handleShowDrawer = (type) => {
    setShowDrawerFor(type);
    setShowDrawer(true);
  };

  const handleTableAction = (event) => {
    console.log(event);
    switch (event.eventType) {
      case "approve":
        handleUserAccountStatus(
          event.rowData.id,
          event.rowData.brokerId,
          "APPROVED"
        );
        return;
      case "delete":
        handleUserAccountStatus(
          event.rowData.id,
          event.rowData.brokerId,
          "REJECTED"
        );

      default:
        return null;
    }
  };

  const handleUserAccountStatus = async (comissionId, userId, status) => {
    const response = await apiPut(
      parseURL(API_ENDPOINT_USER_UPDATE_PENDING_COMISSION_STATUS, {
        brokerId: userId,
        comissionId: comissionId,
        status: status,
      })
    );
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      fetchPendingBrokerComissions();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  const items = [
    {
      key: "1",
      label: "Pending",
      children: (
        <TableView
          data={brokers}
          isLoading={isLoading}
          column={[...tableColumn, actionColumnObj]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      ),
    },
    {
      key: "3",
      label: "Reversed",
      children: (
        <TableView
          data={brokers}
          isLoading={isLoading}
          column={[...tableColumn]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      ),
    },
  ];

  return (
    <div className="BrokersList">
      <div className="TopSelectors" style={{ marginBottom: 20 }}>
        <div className="leftSelectors"></div>
        <div className="rightSelectors">
          <div style={{ width: 200 }}>
            <Button
              size="small"
              background="black"
              icon={<DownloadOutlined />}
              onClick={() => exportToExcel(brokers, tableColumn)}
            >
              Download
            </Button>
          </div>
        </div>
      </div>
      <div>
        <div id="tabBarContainer">
          {/* <TableView
            data={brokers}
            isLoading={isLoading}
            column={[...tableColumn, actionColumnObj]}
            handleAction={handleTableAction}
            hasAccess={hasAccess}
          /> */}

          <div id="tabBarContainer">
            <TabBar
              items={items}
              color="red"
              onChange={(tab) => setActiveTab(tab)}
              inActiveColor="gray_dark"
              activeTab={activeTab}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
