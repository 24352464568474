import {
  CheckOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Drawer, Popconfirm, Switch, Tabs, Tag } from "antd";
import {
  ACCOUNT_STATUS,
  LEAD_STATUS_COLOR_MAP,
} from "app/constants/global.constants";
import BrokerDetails from "app/scenes/brokers/components/BorkerDetails";
import InviteBroker from "app/scenes/brokers/components/BrokerList/InviteBroker";
import { apiGet, apiPost } from "app/services/apiService";
import Button from "app/shared/Button";
import CreateInternalUser from "app/shared/InternalUserCreate";
import TabBar from "app/shared/TabBar";
import TableView from "app/shared/TableView";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { FeatureFlags } from "app/utils/FeatureFlag/FeatrueFlag";
import { parseURL } from "app/utils/helpers/common.helper";

import { useContext, useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { API_ENDPOINT_LEADS_LIST } from "../../leads.constants";
import CreateLead from "./components/CreateLead";
import "./LeadList.scss";

const actionColumnObj = {
  title: "Action",
  renderAction: true,
  handleAction: (propsData) => propsData.handleAction(propsData),
  actions: [
    {
      event: "view_detail",
      icon: <SearchOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#69A2FD",
    },
  ],
};
const LIST_TYPE_MAP = {
  1: "ALL",
  2: "CONTACT_MADE",
  3: "PROPERTY_VISIT",
  4: "QUOATATION_GENERATED",
  5: "CLOSED",
};

export default function LeadList(props) {
  const [properties, setProperties] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [brokers, setBrokers] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [showDrawerFor, setShowDrawerFor] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hasAccess } = useContext(FeatureFlags);

  const tableColumn = [
    {
      title: "Lead Id",
      index: "leadCodeId",
      search: true,
      // renderTag: true,
      customRender: (data) => {
        return (
          <Tag
            color="blue"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/leads/" + data.id)}
          >
            {data.leadCodeId}
          </Tag>
        );
      },
    },
    {
      title: "Name",
      index: "fullname",
      search: true,
    },

    {
      title: "Mobile",
      index: "mobile",
    },

    {
      title: "Promoter/Creator",
      index: "creator.user_profile.first_name",
    },
    {
      title: "Assignee",
      index: "assignee.user_profile.first_name",
    },
    {
      title: "Email",
      index: "email",
    },
    {
      title: "Source",
      index: "source",
      renderTag: true,
    },
    {
      title: "Created On",
      index: "createdAt",
      date: true,
    },
  ];
  useEffect(() => {
    fetchBrokerList();
  }, [activeTab]);
  const fetchBrokerList = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_LEADS_LIST + "?status=" + LIST_TYPE_MAP[activeTab]
    );
    if (response.status) {
      setBrokers(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  const handleShowDrawer = (type) => {
    setShowDrawerFor(type);
    setShowDrawer(true);
  };

  const handleTableAction = (event) => {
    console.log(event);
    switch (event.eventType) {
      case "view_detail":
        navigate("/leads/" + event.rowData.id);
        return;
      default:
        return null;
    }
  };

  const handleUserAccountStatus = async (userId, status) => {};

  const items = [
    {
      key: "1",
      label: "All",
      children: (
        <TableView
          data={brokers}
          isLoading={isLoading}
          column={[
            ...tableColumn,
            {
              title: "Status",
              index: "lead_status.status_key",
              renderTag: true,
              tagColorMap: LEAD_STATUS_COLOR_MAP,
            },
            actionColumnObj,
          ]}
          handleAction={handleTableAction}
        />
      ),
    },

    {
      key: "2",
      label: "Contact Made",
      children: (
        <TableView
          data={brokers}
          isLoading={isLoading}
          column={[...tableColumn, actionColumnObj]}
          handleAction={handleTableAction}
        />
      ),
    },
    {
      key: "3",
      label: "Property Visit",
      children: (
        <TableView
          data={brokers}
          isLoading={isLoading}
          column={[...tableColumn, actionColumnObj]}
          handleAction={handleTableAction}
        />
      ),
    },
    {
      key: "4",
      label: "Quotation Generated",
      children: (
        <TableView
          data={brokers}
          isLoading={isLoading}
          column={[...tableColumn, actionColumnObj]}
          handleAction={handleTableAction}
        />
      ),
    },
    {
      key: "5",
      label: "Closed",
      children: (
        <TableView
          data={brokers}
          isLoading={isLoading}
          column={[...tableColumn, actionColumnObj]}
          handleAction={handleTableAction}
        />
      ),
    },
  ];
  return (
    <div className="LeadList">
      <div className="TopSelectors">
        <div className="leftSelectors"></div>
        <div className="rightSelectors">
          {hasAccess("PROMOTER_MANAGE") ? (
            <div className="WidgetContainer" style={{ minWidth: 0 }}>
              <Button onClick={() => setShowDrawer(true)}>Create Lead</Button>
            </div>
          ) : null}
        </div>
      </div>
      <div id="tabBarContainer">
        <div id="tabBarContainer">
          <TabBar
            items={items}
            color="red"
            onChange={(tab) => setActiveTab(tab)}
            inActiveColor="gray_dark"
            activeTab={activeTab}
          />
        </div>
      </div>
      <Drawer
        title={"Create Lead"}
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        width={500}
      >
        <CreateLead
          onSuccess={() => {
            setShowDrawer(false);
            fetchBrokerList();
          }}
        />
      </Drawer>
    </div>
  );
}
