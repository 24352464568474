import Typography from "app/shared/Typography";
import PromotorIcon from "assets/images/promote.svg";
import HomeIcon from "assets/images/home.svg";
import ComissionIcon from "assets/images/comission.svg";
import VisitIcon from "assets/images/visit.svg";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import "./ReferralList.scss";
import { colorPicker } from "app/utils/helpers/color.helper";
import { Avatar, Image, Tag } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { API_ENDPOINT_FETCH_BROKER_REFERRALS } from "app/scenes/brokers/brokers.constants";
import { useEffect, useState } from "react";
import Loader from "app/shared/Loader";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { apiGet } from "app/services/apiService";
import { parseURL } from "app/utils/helpers/common.helper";
const imageType = {
  HOME: HomeIcon,
  VISIT: VisitIcon,
  COMISSION: ComissionIcon,
};
export default function ReferralList(props) {
  const [referrals, setReferrals] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchBrokerReferrals();
  }, []);
  const fetchBrokerReferrals = async () => {
    setLoading(true);
    const response = await apiGet(
      parseURL(API_ENDPOINT_FETCH_BROKER_REFERRALS, {
        brokerId: props.brokerId,
      })
    );
    if (response.status) {
      setReferrals(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  return isLoading ? (
    <Loader />
  ) : (
    <div className="ReferralList">
      <div className="TopHeader">
        <Typography variant="h5">Referred Details</Typography>
        <Typography variant="xs" color="red" style={{ cursor: "pointer" }}>
          See All
        </Typography>
      </div>
      <div className="UserList">
        {referrals.slice(0, 3).map((user, key) => {
          return (
            <div className="UserInfo" key={key}>
              <Avatar
                size={28}
                src={user.user_profile?.avatar}
                icon={!user.user_profile?.avatar && <UserOutlined />}
              />
              <div className="UserInfoAttribute">
                <Typography variant="caption" color="gray-dark">
                  <Tag color="blue">
                    {user.user_profile?.member_id || "NA"}{" "}
                  </Tag>
                </Typography>
              </div>
              <div className="UserInfoAttribute">
                <Typography variant="caption" color="gray-dark">
                  {user.user_profile?.first_name || "NA"}{" "}
                  {user.user_profile?.last_name}
                </Typography>
              </div>
              <div className="UserInfoAttribute">
                <Typography variant="caption" color="gray-dark">
                  {user.email}
                </Typography>
              </div>
              <div className="UserInfoAttribute">
                <Typography variant="caption" color="gray-dark">
                  {user.user_profile?.mobile}
                </Typography>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
