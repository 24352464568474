import { Form, InputNumber, Modal, Slider, Tag, Upload } from "antd";
import {
  BEDROOM_TYPE_COUNT_MAP,
  COMISSION_TYPES,
  FLAT_PROPERTY_TYPE,
  FURNISHING_TYPES,
  PROPERTY_DOCUMENT_TYPE_FOR_CREATE,
  PROPERTY_FACING_TYPE,
} from "app/constants/global.constants";
import Input from "app/shared/InputBox";
import Selector from "app/shared/Selector";
import Typography from "app/shared/Typography";
import React, { useEffect, useState } from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

import "./AddUnit.scss";
import { colorPicker } from "app/utils/helpers/color.helper";
import MultiTag from "app/shared/MultiTag";
import BulkPhotoUploader from "app/shared/BulkPhotoUploader";
import AreaCalculator from "./MiniComponents/AreaCalculator";
import PriceCalculator from "./MiniComponents/PriceCalculator";
import AddPaymentPlan from "../PaymentPlans/components/AddPaymentPlan";
import Button from "app/shared/Button";
import {
  DeleteOutlined,
  InboxOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { parseURL } from "app/utils/helpers/common.helper";
import {
  apiDelete,
  apiGet,
  apiPost,
  apiPostForm,
  apiPut,
} from "app/services/apiService";
import {
  API_ENDPOINT_PROJECT_PAYMENT_PLAN_INFO,
  API_ENDPOINT_PROPERTY_CREATE_UNIT,
  API_ENDPOINT_PROPERTY_DELETE_IMAGE,
  API_ENDPOINT_PROPERTY_INFO,
  API_ENDPOINT_PROPERTY_UPDATE_DOCUMENT,
  API_ENDPOINT_PROPERTY_UPDATE_IMAGE,
  API_ENDPOINT_PROPERTY_UPDATE_UNIT,
} from "../../property.constants";
import { useLocation, useNavigate, useParams } from "react-router";
import Loader from "app/shared/Loader";
const { Dragger } = Upload;

export default function AddUnit(props) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    state: "Rajasthan",
    documents: [
      {
        type: "",
        data: "",
      },
    ],
    day_hold: 7,
  });
  const [proprtyType, setPropertyType] = useState("");
  const [propertyCategory, setPropertyCategory] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [openModal, setOpenModel] = useState(false);
  const [modalFor, setModalFor] = useState("");
  const [imageList, setImageList] = useState([]);
  const [docList, setDocList] = useState([]);
  const [backupImage, setBackupImages] = useState([]);
  const [newTags, setNewTags] = useState([]);
  const [unitInfo, setUnitInfo] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [unitUpdating, setUnitUpdating] = useState(false);
  const dispatch = useDispatch();

  let { paramProjectId, unitId } = useParams();
  const location = useLocation();
  const { projectName } = location.state || {};

  useEffect(() => {
    if (props.editMode) fetchUnitDetails();
  }, []);

  const fetchUnitDetails = async () => {
    setDetailsLoading(true);
    const response = await apiGet(API_ENDPOINT_PROPERTY_INFO + unitId);
    if (response.status) {
      setUnitInfo(response.data);
      handleInitialValues(response.data);
    } else {
      navigate("/property/projects");
    }
  };

  const handleInitialValues = (respData) => {
    const getDocumentlist = (docList) =>
      docList.map((item) => {
        return {
          type: item?.type,
          data: item?.link,
          isUploaded: true,
        };
      });

    let oldDocList = getDocumentlist(respData?.property_documents || []);

    let data = {
      name: respData.name || null,
      size: respData.size,
      cost: respData.cost,
      furnish_status: "FULLY_FURNISHED",
      property_age: 1,
      comission: respData.comission,
      comissionType: respData.comssionType,
      facing: respData.facing,
      propertyType: respData.property_type?.id,
      bedroom: respData.bedroom || 1,
      bathroom: respData.bathroom || 1,
      balcony: respData.balcony || 1,
      movein_status: respData?.movein_status || "READY_TO_MOVE",
      size_unit: respData.size_unit || "sqft",
      booking_amount: respData.booking_amount,
      // transaction_type: formData.saleType,
      floor: respData.floor || 1,
      seat_capacity: respData.seat_capacity,
      cabins: respData.cabins,
      cafeteria: respData.cafeteria,
      total_floor: respData.total_floor,
      eastRoad: respData.east_size || 0,
      westRoad: respData.west_size || 0,
      northRoad: respData.north_size || 0,
      southRoad: respData.south_size || 0,
      planType: respData.property_payment_plan?.custom_plan_id,
      unit_no:
        respData?.property_units_lists?.map((unitData) => unitData.unit_no) ||
        [],
      unitMap: respData?.property_units_lists,
      builtupArea: respData?.builtupArea,
      supertbuiltArea: respData?.supertbuiltArea,
      additionalCost: respData?.additionalCost,
      documents: oldDocList,
      totalUnit: respData?.property_units_lists.length || 1,
      propertyCategory: respData?.property_type?.property_category?.id,
      flatType:
        respData?.property_type?.name === "Flat" && !respData?.bedroom
          ? "STUDIO"
          : respData?.property_type?.name === "Flat" && respData?.bedroom
          ? respData?.bedroom + "BHK"
          : "NA",
      day_hold: respData?.day_hold,
    };

    console.log("images", data);
    let imageDataList = respData?.property_images || [];
    imageDataList = imageDataList.map((imageData) => ({
      ...imageData,
      isUploaded: true, // Replace 'newKey' and 'newValue' with your desired key-value pair
    }));
    setImageList(imageDataList);
    setPropertyCategory(respData?.property_type?.property_category);
    setPropertyType(respData?.property_type);

    setFormData(JSON.parse(JSON.stringify(data)));
    setDetailsLoading(false);
  };

  const handleInputChange = (e) => {
    console.log("data input:", e);
    const { name, value } = e.target;
    if (name === "propertyType" && e.target.obj) {
      setPropertyType(e.target.obj);
    }

    if (name === "propertyCategory" && e.target.obj) {
      setPropertyCategory(e.target.obj);
    }
    if (name === "propertyProject" && props.editMode && isLoading) {
      //   fetchProjectInfo(value);
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCalculatedValue = (value) => {
    if (modalFor === "AREA_CALCULATE")
      setFormData((prevFormData) => ({
        ...prevFormData,
        ["size"]: value,
      }));

    if (modalFor === "PRICE_CALCULATE")
      setFormData((prevFormData) => ({
        ...prevFormData,
        ["cost"]: value,
      }));

    setModalFor("");
    setOpenModel(false);
  };

  const dragProps = {
    name: "file",
    multiple: true,
    listType: "picture-card",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      const { status } = info.file;

      // handleInputChange({
      //   target: { name: "images", value: info.fileList },
      // });
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const renderModalContent = () => {
    switch (modalFor) {
      case "AREA_CALCULATE":
        return <AreaCalculator onCalculate={handleCalculatedValue} />;
      case "PRICE_CALCULATE":
        return (
          <PriceCalculator
            size={formData.size}
            onCalculate={handleCalculatedValue}
          />
        );
      case "CREATE_PLAN":
        return <AddPaymentPlan />;
      default:
        return <AreaCalculator onCalculate={handleCalculatedValue} />;
    }
  };

  const handleOpenModal = (modalType) => {
    setModalFor(modalType);
    setOpenModel(true);
  };

  const handleNestedInputChange = (e, rowIndex, objectName) => {
    const { name, value } = e.target;
    let newFormData = JSON.parse(JSON.stringify(formData));
    if (objectName === "documents") {
      setDocList([...docList, value]);
    }

    if (objectName === "images") {
      setDocList([...docList, value]);
    }
    newFormData[objectName][rowIndex][name] = value;
    // console.log("new form data:", newFormData);
    setFormData(newFormData);
  };

  const updateUnit = async () => {
    if (formData?.unit_no.length < formData.totalUnit) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: {
          message: "Enter all unit no as per total unit",
          type: 0,
        },
      });
      return;
    }
    setUnitUpdating(true);
    // setLoading(true);
    const payload = {
      property_name: formData.name || null,
      size: formData.size,
      cost: formData.cost,
      transaction_type: "NEW_PROPERTY",
      furnish_status: "FULLY_FURNISHED",
      property_age: 1,
      comission: formData.comission,
      comssionType: formData.comissionType,
      facing: formData.facing,
      property_type: formData.propertyType,
      bedroom: formData.bedroom && parseInt(formData.bedroom),
      bathroom: formData.bathroom && parseInt(formData.bathroom),
      balcony: formData.balcony && parseInt(formData.balcony),
      movein_status: "READY_TO_MOVE",
      size_unit: formData.size_unit || "sqft",
      booking_amount: formData.booking_amount,
      movein_status: formData.moveStatus,
      // transaction_type: formData.saleType,
      furnish_status: formData.furnishing,
      floor: formData.floor || 1,
      seat_capacity: formData.seat_capacity,
      cabins: formData.cabins,
      cafeteria: formData.cafeteria,
      total_floor: formData.total_floor,
      east_size: formData.east_size,
      west_size: formData.west_size,
      north_size: formData.north_size,
      south_size: formData.south_size,
      planType: formData.planType,
      unit_no: newTags,
      builtupArea: formData?.builtupArea,
      supertbuiltArea: formData?.supertbuiltArea,
      additionalCost: formData?.additionalCost,
      documents: formData?.documents || [],
      images: imageList || [],
      propertyProjectId: paramProjectId,
      total_units: formData.totalUnit,
      coveredArea: formData?.coveredArea,
      day_hold: formData?.day_hold,
    };
    console.log("payload is ", payload);

    if (!payload.comssionType) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: {
          message: "Property Comission type is not yet selected",
          type: 0,
        },
      });
      return;
    }

    if (!payload.property_type) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: {
          message:
            "Please check your input field some data are missing eg: property_type,Plan ",
          type: 0,
        },
      });
      return;
    }
    const response = await apiPut(
      API_ENDPOINT_PROPERTY_UPDATE_UNIT + unitId,
      payload
    );
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      updatePropertyDocument(unitId);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
    setUnitUpdating(false);
  };

  const createUnit = async () => {
    if (!formData?.planType) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: {
          message: "Cannot create unit without payment plan",
          type: 0,
        },
      });
      return;
    }

    if (!formData?.unit_no || formData?.unit_no.length < formData.totalUnit) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: {
          message: "Enter all unit no as per total unit",
          type: 0,
        },
      });
      return;
    }
    setUnitUpdating(true);

    // setLoading(true);
    const payload = {
      property_name: formData.name || null,
      size: formData.size,
      cost: formData.cost,
      transaction_type: "NEW_PROPERTY",
      furnish_status: "FULLY_FURNISHED",
      property_age: 1,
      comission: formData.comission,
      comssionType: formData.comissionType,
      facing: formData.facing,
      property_type: formData.propertyType,
      bedroom: formData.bedroom && parseInt(formData.bedroom),
      bathroom: formData.bathroom && parseInt(formData.bathroom),
      balcony: formData.balcony && parseInt(formData.balcony),
      movein_status: "READY_TO_MOVE",
      size_unit: formData.size_unit || "sqft",
      booking_amount: formData.booking_amount,
      movein_status: formData.moveStatus,
      // transaction_type: formData.saleType,
      furnish_status: formData.furnishing,
      floor: formData.floor || 1,
      seat_capacity: formData.seat_capacity,
      cabins: formData.cabins,
      cafeteria: formData.cafeteria,
      total_floor: formData.total_floor,
      east_size: formData.east_size,
      west_size: formData.west_size,
      north_size: formData.north_size,
      south_size: formData.south_size,
      planType: formData.planType,
      unit_no: formData?.unit_no || [],
      builtupArea: formData?.builtupArea,
      supertbuiltArea: formData?.supertbuiltArea,
      additionalCost: formData?.additionalCost,
      documents: formData?.documents || [],
      images: imageList || [],
      propertyProjectId: paramProjectId,
      total_units: formData.totalUnit,
      coveredArea: formData.coveredArea,
      day_hold: formData?.day_hold,
    };
    console.log("payload is ", payload);

    if (!payload.comssionType) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: {
          message: "Property Comission type is not yet selected",
          type: 0,
        },
      });
      return;
    }

    if (!payload.property_type) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: {
          message:
            "Please check your input field some data are missing eg: property_type,Plan ",
          type: 0,
        },
      });
      return;
    }
    const response = await apiPost(API_ENDPOINT_PROPERTY_CREATE_UNIT, payload);
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      updatePropertyDocument(response.data.id);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
    setUnitUpdating(false);
  };

  const updatePropertyDocument = async (newPropertyId) => {
    if (docList.length > 0) {
      let docList = formData?.documents || [];
      for (const doc_type of docList) {
        if (doc_type.isUploaded) continue;
        if (!doc_type.type) continue;
        try {
          let fileFormData = new FormData();
          fileFormData.append("file", doc_type.data?.originFileObj);
          fileFormData.append("doc_type", doc_type.type);

          const response = await apiPostForm(
            parseURL(API_ENDPOINT_PROPERTY_UPDATE_DOCUMENT, {
              propertyId: newPropertyId,
            }),
            fileFormData
          );

          if (response.status) {
            addImageToUnit(newPropertyId);
          } else {
            dispatch({
              type: ADD_TOAST_MESSAGE,
              payload: { message: response.message, type: 0 },
            });
          }
        } catch (error) {
          console.error("Failed to upload document", error);
          // Dispatch an error message or handle the error
        }
      }
    }
    if (backupImage.length > 0) {
      addImageToUnit(newPropertyId);
    } else {
      navigate(-1);
    }
  };

  const addImageToUnit = async (newPropertyId) => {
    for (const imageData of imageList) {
      if (imageData.isUploaded) continue;
      // Create a new instance of FormData for each image
      let uploadFormData = new FormData();
      uploadFormData.append("file", imageData.originFileObj); // Use the correct reference to images

      try {
        const response = await apiPostForm(
          parseURL(API_ENDPOINT_PROPERTY_UPDATE_IMAGE, {
            propertyId: newPropertyId,
          }),
          uploadFormData
        );

        if (response.status) {
          dispatch({
            type: ADD_TOAST_MESSAGE,
            payload: { message: response.message, type: 1 },
          });
          navigate(-1);
          // Handle success case
          console.log("Upload successful for image");
        } else {
          navigate(-1);

          // Handle failure case
          console.error("Upload failed for image");
        }
      } catch (error) {
        // Handle any errors that occur during the fetch call
        console.error("Error during image upload", error);
      }
    }
  };

  const handleChange = ({ fileList: newFileList }) => {};

  const deleteImageFromProperty = async (image) => {
    const response = await apiDelete(
      parseURL(API_ENDPOINT_PROPERTY_DELETE_IMAGE, {
        propertyId: unitId,
        imageId: image.id,
      })
    );
    if (response.status) {
    } else {
    }
  };

  const addMoreRowInForm = (objectName) => {
    let newFormData = JSON.parse(JSON.stringify(formData));
    let objectType = {
      documents: {
        type: "",
        data: "",
      },
    };

    newFormData[objectName].push(objectType[objectName]);
    setFormData(newFormData);
  };

  return detailsLoading ? (
    <Loader />
  ) : (
    <div className="AddUnit">
      <div
        className=""
        style={{ marginBottom: 24, display: "flex", alignItems: "center" }}
      >
        <ArrowBackRoundedIcon
          style={{ fontSize: 44, cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        <Typography style={{ fontSize: 22 }}>{projectName}</Typography>
      </div>

      <Form
        name="basic"
        onFinish={props.editMode ? updateUnit : createUnit}
        initialValues={formData}
        fields={[
          {
            name: ["size"],
            value: formData?.size,
          },
          {
            name: ["cost"],
            value: formData?.cost,
          },
          {
            name: ["totalCost"],
            value:
              parseFloat(formData?.cost || 0) +
              parseFloat(formData?.additionalCost || 0),
          },
        ]}
      >
        <div className="form-row">
          <div className="input-element">
            <Form.Item
              name="propertyCategory"
              initialValue={formData.propertyCategory}
              required
              status={{ errors: ["Fdsfs"] }}
            >
              <Typography
                variant="body"
                weight={400}
                style={{ marginLeft: 4, marginBottom: 4 }}
              >
                Property Category
              </Typography>
              <Selector
                placeholder="Property Category"
                labelKey="name"
                valueKey="id"
                serviceType="PROPERTY_CATEGORY"
                name="propertyCategory"
                fullObject
                defaultValue={formData.propertyCategory}
                onChange={handleInputChange}
              />
            </Form.Item>
          </div>
          <div className="input-element">
            <Form.Item name="propertyType">
              <Typography
                variant="body"
                weight={400}
                style={{ marginLeft: 4, marginBottom: 4 }}
              >
                Property Type
              </Typography>
              <Selector
                placeholder="Property Type"
                labelKey="name"
                valueKey="id"
                serviceType="PROPERTY_TYPES"
                name="propertyType"
                fullObject
                defaultValue={formData.propertyType}
                disabled={!formData.propertyCategory}
                queryFilter={{
                  key: "propertyCategoryId",
                  dataValue: formData.propertyCategory,
                }}
                onChange={handleInputChange}
              />
            </Form.Item>
          </div>
          {proprtyType?.name === "Flat" ||
          proprtyType?.name === "House/Villa" ? (
            <div className="input-element">
              <Form.Item name="flatType">
                <Typography
                  variant="body"
                  weight={400}
                  style={{ marginLeft: 4, marginBottom: 4 }}
                >
                  Flat Type
                </Typography>
                <Selector
                  placeholder="Flat Type"
                  labelKey="name"
                  valueKey="id"
                  serviceType="STATIC"
                  name="flatType"
                  fullObject
                  staticData={FLAT_PROPERTY_TYPE}
                  defaultValue={formData.flatType}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>
          ) : null}
          <div className="input-element">
            <Form.Item
              name="totalUnit"
              required
              rules={[
                {
                  required: true,
                  message: "Please provide your input!",
                },
              ]}
            >
              <Input
                placeholder="Total Unit"
                label="Total Unit"
                background="white"
                onChange={handleInputChange}
                name="totalUnit"
                defaultValue={formData.totalUnit}
                borderColor="gray"
              />
            </Form.Item>
          </div>
          {proprtyType?.name === "Office Space" ||
          proprtyType?.name === "Shop/Showroom" ||
          proprtyType?.name === "Flat" ||
          proprtyType?.name === "House/Villa" ? (
            <div className="input-element">
              <Form.Item name="totalFloor">
                <Input
                  placeholder="Total Floors"
                  label="Total Floors"
                  background="white"
                  onChange={handleInputChange}
                  borderColor="gray"
                  name="total_floor"
                  defaultValue={formData.totalFloor}
                />
              </Form.Item>
            </div>
          ) : null}
          <div className="input-element">
            <Form.Item
              name="size"
              initialValue={formData.size}
              rules={[
                {
                  required: true,
                  message: "Please provide your input!",
                },
              ]}
            >
              <Input
                placeholder="Size"
                label="Size"
                background="white"
                onChange={handleInputChange}
                borderColor="gray"
                name="size"
                defaultValue={formData.size}
                value={formData.size}
                suffix={
                  <Typography
                    variant="xs"
                    color="red"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleOpenModal("AREA_CALCULATE")}
                  >
                    Calculate
                  </Typography>
                }
              />
            </Form.Item>
          </div>
          <div className="input-element">
            <Form.Item name="supertbuiltArea">
              <Input
                placeholder="Super Built-up Area"
                label="Super Built-up Area"
                background="white"
                onChange={handleInputChange}
                borderColor="gray"
                name="supertbuiltArea"
                defaultValue={formData.supertbuiltArea}
              />
            </Form.Item>
          </div>
          {propertyCategory &&
          propertyCategory?.name === "Residential" &&
          proprtyType?.name !== "Plot" ? (
            <div className="input-element">
              <Form.Item name="builtupArea">
                <Input
                  placeholder="Built-up Area"
                  label="Built-up Area"
                  background="white"
                  onChange={handleInputChange}
                  borderColor="gray"
                  name="builtupArea"
                  defaultValue={formData.builtupArea}
                />
              </Form.Item>
            </div>
          ) : null}

          {proprtyType?.name === "Godown" ||
          proprtyType.name === "Warehouse" ? (
            <div className="input-element">
              <Form.Item name="coveredArea">
                <Input
                  placeholder="Covered Area"
                  label="Covered Area"
                  background="white"
                  onChange={handleInputChange}
                  borderColor="gray"
                  name="coveredArea"
                  defaultValue={formData.coveredArea}
                />
              </Form.Item>
            </div>
          ) : null}
          <div className="input-element">
            <Form.Item
              name="cost"
              rules={[
                {
                  required: true,
                  message: "Please provide your input!",
                },
              ]}
            >
              <Input
                placeholder="Cost"
                label="Cost"
                background="white"
                onChange={handleInputChange}
                borderColor="gray"
                name="cost"
                defaultValue={formData.cost}
                prefix={
                  <CurrencyRupeeIcon
                    style={{ fontSize: 16, color: colorPicker("gray_dark") }}
                  />
                }
                suffix={
                  <Typography
                    variant="xs"
                    color="red"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleOpenModal("PRICE_CALCULATE")}
                  >
                    Calculate
                  </Typography>
                }
              />
            </Form.Item>
          </div>
          <div className="input-element">
            <Form.Item name="additionalCost">
              <Input
                placeholder="Additional Charges"
                label="Additional Charges"
                background="white"
                onChange={handleInputChange}
                borderColor="gray"
                name="additionalCost"
                defaultValue={formData.additionalCost}
                prefix={
                  <CurrencyRupeeIcon
                    style={{ fontSize: 16, color: colorPicker("gray_dark") }}
                  />
                }
              />
            </Form.Item>
          </div>

          <div className="input-element">
            <Form.Item name="totalCost">
              <Input
                placeholder="Total Cost"
                label="Total Cost"
                background="white"
                onChange={handleInputChange}
                borderColor="gray"
                name="totalCost"
                defaultValue={formData?.totalCost}
                prefix={
                  <CurrencyRupeeIcon
                    style={{ fontSize: 16, color: colorPicker("gray_dark") }}
                  />
                }
              />
            </Form.Item>
          </div>
          {(proprtyType?.name === "Flat" ||
            proprtyType?.name === "House/Villa" ||
            proprtyType?.name === "Penthouse" ||
            proprtyType?.name === "Farmhouse") &&
          formData?.flatType !== "STUDIO" ? (
            <div className="input-element">
              <Form.Item name="bedroom">
                <Typography
                  variant="body"
                  weight={400}
                  style={{ marginLeft: 4, marginBottom: 4 }}
                >
                  Bedroom
                </Typography>
                <Selector
                  placeholder="Bedroom"
                  labelKey="label"
                  valueKey="value"
                  serviceType="STATIC"
                  name="bedroom"
                  fullObject
                  defaultValue={formData.bedroom}
                  onChange={handleInputChange}
                  staticData={BEDROOM_TYPE_COUNT_MAP}
                />

                {/* <Input
                  placeholder="Bedroom"
                  label="Bedroom"
                  background="white"
                  onChange={handleInputChange}
                  borderColor="gray"
                  name="bedroom"
                  defaultValue={formData.bedroom}
                /> */}
              </Form.Item>
            </div>
          ) : null}

          {proprtyType?.name === "Plot" ||
          proprtyType?.name === "Commercial Land" ||
          proprtyType?.name === "Industrial Land" ||
          proprtyType?.name === "Industrial Land" ? null : (
            <div className="input-element">
              <Form.Item name="bathroom">
                <Typography
                  variant="body"
                  weight={400}
                  style={{ marginLeft: 4, marginBottom: 4 }}
                >
                  Bathroom
                </Typography>
                <Selector
                  placeholder="Bathroom"
                  labelKey="label"
                  valueKey="value"
                  serviceType="STATIC"
                  name="bathroom"
                  fullObject
                  defaultValue={formData.bathroom}
                  onChange={handleInputChange}
                  staticData={BEDROOM_TYPE_COUNT_MAP}
                />
              </Form.Item>
            </div>
          )}
          {(proprtyType?.name === "Flat" ||
            proprtyType?.name === "House/Villa" ||
            proprtyType?.name === "Penthouse" ||
            proprtyType?.name === "Farmhouse") &&
          formData?.flatType !== "STUDIO" ? (
            <div className="input-element">
              <Form.Item name="balcony">
                <Typography
                  variant="body"
                  weight={400}
                  style={{ marginLeft: 4, marginBottom: 4 }}
                >
                  Balcony
                </Typography>
                <Selector
                  placeholder="Balcony"
                  labelKey="label"
                  valueKey="value"
                  serviceType="STATIC"
                  name="balcony"
                  fullObject
                  defaultValue={formData.balcony}
                  onChange={handleInputChange}
                  staticData={BEDROOM_TYPE_COUNT_MAP}
                />
              </Form.Item>
            </div>
          ) : null}

          <div className="input-element">
            <Form.Item name="facing">
              <Typography
                variant="body"
                weight={400}
                style={{ marginLeft: 4, marginBottom: 4 }}
              >
                Facing
              </Typography>
              <Selector
                placeholder="Facing"
                labelKey="name"
                valueKey="id"
                serviceType="STATIC"
                name="facing"
                fullObject
                staticData={PROPERTY_FACING_TYPE}
                defaultValue={formData.facing}
                onChange={handleInputChange}
              />
            </Form.Item>
          </div>
          {propertyCategory &&
          propertyCategory?.name === "Residential" &&
          proprtyType?.name !== "Plot" ? (
            <div className="input-element">
              <Form.Item name="furnishing">
                <Typography
                  variant="body"
                  weight={400}
                  style={{ marginLeft: 4, marginBottom: 4 }}
                >
                  Furnishing
                </Typography>
                <Selector
                  placeholder="Furnishing"
                  labelKey="name"
                  valueKey="id"
                  serviceType="STATIC"
                  name="furnishing"
                  fullObject
                  staticData={FURNISHING_TYPES}
                  defaultValue={formData.furnishing}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>
          ) : null}
          <div className="input-element">
            <Form.Item
              name="booking_amount"
              rules={[
                {
                  required: true,
                  message: "Please provide your input!",
                },
              ]}
            >
              <Input
                placeholder="Booking Amount"
                label="Booking Amount"
                background="white"
                onChange={handleInputChange}
                name="booking_amount"
                defaultValue={formData.booking_amount}
                borderColor="gray"
                prefix={
                  <CurrencyRupeeIcon
                    style={{ fontSize: 16, color: colorPicker("gray_dark") }}
                  />
                }
              />
            </Form.Item>
          </div>
          <div className="input-element" style={{ width: "46%" }}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please provide your input!",
                },
              ]}
            >
              <Input
                placeholder="Name"
                label="Name"
                background="white"
                onChange={handleInputChange}
                name="name"
                defaultValue={formData.name}
                borderColor="gray"
              />
            </Form.Item>
          </div>

          {proprtyType?.name === "Studio" ||
          proprtyType?.name === "Flat" ||
          !proprtyType ? null : (
            <>
              <div className="input-element">
                <Form.Item name="eastRoad">
                  <Input
                    placeholder="East Road (ft)"
                    label="East Road"
                    background="white"
                    onChange={handleInputChange}
                    name="east_size"
                    defaultValue={formData.eastRoad}
                    borderColor="gray"
                  />
                </Form.Item>
              </div>
              <div className="input-element">
                <Form.Item name="westRoad">
                  <Input
                    placeholder="West Road (ft)"
                    label="West Road"
                    background="white"
                    onChange={handleInputChange}
                    name="west_size"
                    defaultValue={formData.westRoad}
                    borderColor="gray"
                  />
                </Form.Item>
              </div>
              <div className="input-element">
                <Form.Item name="northRoad">
                  <Input
                    placeholder="North Road (ft)"
                    label="North Road"
                    background="white"
                    onChange={handleInputChange}
                    name="north_size"
                    defaultValue={formData.totalUnit}
                    borderColor="gray"
                  />
                </Form.Item>
              </div>
              <div className="input-element">
                <Form.Item name="southRoad">
                  <Input
                    placeholder="South Road (ft)"
                    label="South Road"
                    background="white"
                    onChange={handleInputChange}
                    name="south_size"
                    defaultValue={formData.southRoad}
                    borderColor="gray"
                  />
                </Form.Item>
              </div>
            </>
          )}

          {proprtyType &&
          (proprtyType.name === "Office Space" ||
            proprtyType.name === "Showroom" ||
            proprtyType.name === "Shop") ? (
            <>
              <div className="input-element">
                <Form.Item name="cafeteria">
                  <Typography
                    variant="body"
                    weight={400}
                    style={{ marginLeft: 4, marginBottom: 4 }}
                  >
                    Cafeteria
                  </Typography>
                  <Selector
                    placeholder="Cafeteria"
                    labelKey="name"
                    valueKey="id"
                    serviceType="STATIC"
                    staticData={[
                      {
                        name: "Dry",
                        id: "DRY",
                      },
                      {
                        name: "Wet",
                        id: "WET",
                      },
                    ]}
                    name="cafeteria"
                    onChange={handleInputChange}
                    defaultValue={formData.cafeteria}
                  />
                </Form.Item>
              </div>

              <div className="input-element">
                <Form.Item
                  name="seat_capacity"
                  rules={[
                    {
                      required: true,
                      message: "Please check your input",
                    },
                  ]}
                >
                  <Input
                    placeholder="Seat Capacity"
                    label="Seat Capacity"
                    background="white"
                    name="seat_capacity"
                    borderColor="gray"
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </div>
              <div className="input-element">
                <Form.Item
                  name="cabins"
                  rules={[
                    {
                      required: true,
                      message: "Please check your input",
                    },
                  ]}
                >
                  <Input
                    placeholder="Total Cabins"
                    label="Total Cabins"
                    background="white"
                    name="cabins"
                    onChange={handleInputChange}
                    borderColor="gray"
                  />
                </Form.Item>
              </div>
            </>
          ) : null}

          <div className="input-element">
            <Form.Item name="comissionType">
              <Typography
                variant="body"
                weight={400}
                style={{ marginLeft: 4, marginBottom: 4 }}
              >
                Comission Type
              </Typography>
              <Selector
                placeholder="Comission Type"
                labelKey="label"
                valueKey="value"
                serviceType="STATIC"
                staticData={COMISSION_TYPES}
                name="comissionType"
                onChange={handleInputChange}
                defaultValue={formData.comissionType}
              />
            </Form.Item>
          </div>
          {formData && formData?.comissionType === "FIXED" ? (
            <div className="input-element">
              <Form.Item name="comission">
                <Typography
                  variant="body"
                  weight={400}
                  style={{ marginLeft: 4, marginBottom: 4 }}
                >
                  Comission
                </Typography>

                <InputNumber
                  placeholder={"Comission (%)"}
                  name={"comission"}
                  onChange={(data) =>
                    handleInputChange({
                      target: { name: "comission", value: data },
                    })
                  }
                  //   defaultValue={}
                  min={0}
                  max={100}
                  size="small"
                  value={formData.comission}
                  style={{ height: 46, width: 200 }}
                />

                {/* <Input
                  placeholder="Comission"
                  label="Comission (%)"
                  background="white"
                  onChange={handleInputChange}
                  borderColor="gray"
                  name="comission"
                  defaultValue={formData.comission}
                  prefix={
                    <CurrencyRupeeIcon
                      style={{ fontSize: 16, color: colorPicker("gray_dark") }}
                    />
                  }
                /> */}
              </Form.Item>
            </div>
          ) : null}

          <div className="input-element">
            <Form.Item
              name="planType"
              initialValue={formData.planType}
              required
              status={{ errors: ["Fdsfs"] }}
            >
              <Typography
                variant="body"
                weight={400}
                style={{ marginLeft: 4, marginBottom: 4 }}
              >
                Select Plan
              </Typography>
              <Selector
                placeholder="Payment Plan"
                labelKey="name"
                valueKey="id"
                serviceType="PAYMENT_PLANS"
                name="planType"
                onChange={handleInputChange}
                defaultValue={formData.planType}
                queryFilter={{
                  key: "projectId",
                  dataValue: paramProjectId,
                }}
              />
            </Form.Item>
          </div>

          <div className="input-element">
            <Form.Item
              name="totalUnit"
              required
              rules={[
                {
                  required: true,
                  message: "Please provide your input!",
                },
              ]}
            >
              <Input
                placeholder="Total Unit"
                label="Total Unit"
                background="white"
                onChange={handleInputChange}
                name="totalUnit"
                defaultValue={formData.totalUnit}
                borderColor="gray"
                disabled
              />
            </Form.Item>
          </div>
          <div className="input-element">
            <Form.Item
              name="day_hold"
              rules={[
                {
                  required: true,
                  message: "Please check your input",
                },
              ]}
            >
              <Input
                placeholder="Hold Property When Booked"
                label="Hold Property When Booked (days)"
                background="white"
                name="day_hold"
                onChange={handleInputChange}
                borderColor="gray"
                max={15}
                maxLength={2}
              />
            </Form.Item>
          </div>
          <div className="input-element" style={{ width: "100%" }}>
            <Form.Item name="propertyType">
              <Typography
                variant="body"
                weight={400}
                style={{ marginLeft: 4, marginBottom: 4 }}
              >
                Unit Nos.
              </Typography>
              <MultiTag
                onChange={(tagItem) => {
                  handleInputChange({
                    target: { name: "unit_no", value: tagItem.value },
                  });
                  setNewTags(tagItem.newItems);
                }}
                editMode
                defaultValue={formData?.unit_no}
                refData={formData?.unitMap}
                totalUnit={formData?.totalUnit}
              />
            </Form.Item>
          </div>
          <div className="input-element" style={{ width: "100%" }}>
            <Typography
              variant="body"
              weight={400}
              style={{ marginLeft: 4, marginBottom: 4 }}
            >
              Unit Images
            </Typography>

            <Dragger
              {...dragProps}
              fileList={imageList || []}
              height={200}
              beforeUpload={(file, list) => {
                return false;
              }}
              onChange={({ fileList: newFileList }) => {
                console.log("fileList", newFileList);
                setImageList(newFileList);
                setBackupImages(newFileList);
              }}
              onRemove={deleteImageFromProperty}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">Maximum file size upload: 10 MB</p>
            </Dragger>
          </div>
          <div className="input-element" style={{ width: "100%" }}>
            <Typography
              variant="body"
              weight={400}
              style={{ marginLeft: 4, marginBottom: 4 }}
            >
              Unit Documents
            </Typography>
            <div className="ItemVerticleContainer">
              {formData?.documents &&
                formData?.documents?.map((document, key) => {
                  return (
                    <div
                      className="ItemContainer"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div
                        className="ItemWidget"
                        style={{ width: 200, marginRight: 12 }}
                      >
                        <Form.Item name={key + "_document"}>
                          <Selector
                            placeholder="Document Type"
                            labelKey="type"
                            valueKey="type"
                            serviceType="STATIC"
                            onChange={(e) =>
                              handleNestedInputChange(e, key, "documents")
                            }
                            staticData={PROPERTY_DOCUMENT_TYPE_FOR_CREATE}
                            name={"type"}
                            fullObject
                            defaultValue={document.type}
                          />
                        </Form.Item>
                      </div>
                      <div className="ItemWidget" style={{ marginBottom: 24 }}>
                        <Upload
                          onChange={(e) =>
                            handleNestedInputChange(
                              { target: { name: "data", value: e.file } },
                              key,
                              "documents"
                            )
                          }
                          name="file"
                          action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                        >
                          <Button icon={<UploadOutlined />} size="medium">
                            {document.type ? "Update Document" : "Add Document"}
                          </Button>
                        </Upload>
                      </div>
                    </div>
                  );
                })}

              <Tag
                style={{ cursor: "pointer" }}
                onClick={() => addMoreRowInForm("documents")}
              >
                Add More
              </Tag>
            </div>
          </div>
        </div>
        {/* <MapPicker /> */}
        <div className="FormAction">
          <Button
            style={{ width: 200 }}
            // onClick={() => setShowImageDialog(true)}
            htmlType="submit"
            loading={unitUpdating}
          >
            {props.editMode ? "Update" : "Add"} Unit
          </Button>
        </div>
      </Form>

      <Modal
        title={
          modalFor === "PRICE_CALCULATE" ? "Price Calculate" : "Calculate Area"
        }
        open={openModal}
        onCancel={() => setOpenModel(false)}
        footer={false}
      >
        {renderModalContent()}
      </Modal>
    </div>
  );
}
