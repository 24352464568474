import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  HomeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Drawer, Modal, Popconfirm, Popover, Switch, Tabs, Tag } from "antd";
import { ACCOUNT_STATUS } from "app/constants/global.constants";
import BrokerDetails from "app/scenes/brokers/components/BorkerDetails";
import InviteBroker from "app/scenes/brokers/components/BrokerList/InviteBroker";
import { apiDelete, apiGet, apiPost } from "app/services/apiService";
import Button from "app/shared/Button";
import CreateInternalUser from "app/shared/InternalUserCreate";
import TabBar from "app/shared/TabBar";
import TableView from "app/shared/TableView";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { FeatureFlags } from "app/utils/FeatureFlag/FeatrueFlag";
import { parseURL } from "app/utils/helpers/common.helper";

import { useContext, useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {
  API_ENDPOINT_PROJECT_DELETE,
  API_ENDPOINT_PROJECT_INVESTORS_LIST,
  API_ENDPOINT_PROJECT_LIST,
} from "../../property.constants";
import InvestorDetail from "./components/InvestorDetail";

import "./ProjectList.scss";

const getDistinctInvestors = (data) => {
  let investors = [];
  data.forEach((investor) => {
    let findInvestor = investors.findIndex(
      (item) => item.investorId === investor.investorId
    );
    if (findInvestor !== -1) {
      investors[findInvestor].amount =
        parseInt(investors[findInvestor].amount) + parseInt(investor.amount);
    } else {
      investors.push(investor);
    }
  });
  return investors;
};
const renderInvestorChip = (data) => {
  let chips = [];
  getDistinctInvestors(data).forEach((element) => {
    chips.push(
      <Tag color="blue">{element?.investments?.user_profile?.fullname}</Tag>
    );
  });

  return chips;
};

const tableColumn = [
  {
    title: "Name",
    index: "name",
    search: true,
    avatar: "user_profile.logo",
    dummyAvatar: <HomeOutlined />,
  },
  {
    title: "Project ID",
    index: "project_unique_id",
    search: true,
  },

  {
    title: "Raised Invesement",
    index: "total_investment",
    amount: true,
  },

  {
    title: "Total Investors",
    index: "total_investor",
    render: (renderData) => (
      <Popover
        content={renderInvestorChip(renderData?.investor_fundings)}
        title="Investors"
        trigger="hover"
      >
        <Tag
          style={{ cursor: "pointer" }}
          color={renderData?.investor_fundings.length > 0 ? "green" : "red"}
        >
          {renderData?.total_investor || "No"} Investors
        </Tag>
      </Popover>
    ),
  },

  {
    title: "Created On",
    index: "createdAt",
    date: true,
  },

  {
    title: "Status",
    index: "status",
    renderTag: true,
  },
];

const actionColumnObj = {
  title: "Action",
  renderAction: true,
  handleAction: (propsData) => propsData.handleAction(propsData),
  actions: [
    {
      event: "view_detail",
      icon: <SearchOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#69A2FD",
    },

    {
      event: "edit_detail",
      icon: <EditOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#f1c40f",
    },
    {
      event: "delete",
      icon: <DeleteOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#FF0000",
      popup: true,
      popupTitle: "Are you sure want to delete?",
      showOn: (data) => data.hasAccess,
      accessKey: "PROMOTER_MANAGE",
    },
  ],
};
const LIST_TYPE_MAP = {
  1: "ALL",
  2: "APPROVED",
  3: "PENDING",
  4: "REJECTED",
  5: "INVITED",
};

export default function ProjectList(props) {
  const [properties, setProperties] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [investors, setInvestors] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [selectedUser, setSelectedUser] = useState("");
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hasAccess } = useContext(FeatureFlags);

  useEffect(() => {
    fetchProjectList();
  }, [activeTab]);
  const fetchProjectList = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_PROJECT_LIST);
    if (response.status) {
      setInvestors(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  const handleShowDrawer = (type) => {
    // setShowDrawerFor(type);
    setShowDrawer(true);
  };

  const handleTableAction = (event) => {
    console.log(event);
    switch (event.eventType) {
      case "edit_detail":
        navigate("/property/project/edit/" + event.rowData.id);
        return;
      case "view_detail":
        navigate("/property/project/" + event.rowData.id);
        return;
      case "delete":
        handleDeleteProject(event.rowData.id);
      default:
        return null;
    }
  };

  const handleDeleteProject = async (projectId) => {
    const response = await apiDelete(
      parseURL(API_ENDPOINT_PROJECT_DELETE + projectId)
    );
    if (response.status) {
      fetchProjectList();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    }
  };

  return (
    <div className="PromoterList">
      <div className="TopSelectors">
        <div className="leftSelectors"></div>
        <div className="rightSelectors">
          {hasAccess("PROMOTER_MANAGE") ? (
            <div className="WidgetContainer" style={{ minWidth: 0 }}>
              <Button onClick={() => navigate("/property/project/create")}>
                Create Project
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      <div id="tabBarContainer">
        <TableView
          data={investors}
          isLoading={isLoading}
          column={[...tableColumn, actionColumnObj]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      </div>
      <Modal
        title={"Investor Detail"}
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
        width={800}
      >
        <InvestorDetail investorId={selectedUser} />
      </Modal>
    </div>
  );
}
