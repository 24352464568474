import { DatePicker, Switch } from "antd";
import {
  ACCOUNT_STATUS_MAP_COLOR,
  GLOBAL_TIME_FORMAT,
} from "app/constants/global.constants";
import { apiGet, apiPost } from "app/services/apiService";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/helpers/color.helper";
import { parseURL } from "app/utils/helpers/common.helper";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  API_ENDPOINT_FETCH_BROKER_COMISSION_GIST,
  API_ENDPOINT_USER_UPDATE_ACCOUNT_STATUS,
} from "../../brokers.constants";
import BrokerDashCard from "../BrokerDashboard/components/BrokerDashCard";
import "./BrokerDashboardComission.scss";
import EarningList from "./components/EarningList";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import dayjs from "dayjs";
export default function BrokerDashboardComission(props) {
  const [gistData, setGistData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(
    dayjs(moment().format(GLOBAL_TIME_FORMAT), GLOBAL_TIME_FORMAT)
  );

  let { brokerId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchComissionGist();
  }, [selectedMonth]);

  const fetchComissionGist = async () => {
    // setLoading(true);
    const response = await apiGet(
      parseURL(API_ENDPOINT_FETCH_BROKER_COMISSION_GIST, {
        brokerId: brokerId,
      }) +
        "?start=" +
        moment(selectedMonth).format(GLOBAL_TIME_FORMAT) +
        "&end=" +
        moment(selectedMonth).format(GLOBAL_TIME_FORMAT)
    );
    if (response.status) {
      setGistData(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setSelectedMonth(moment(dateString, "YYYY-MM").format(GLOBAL_TIME_FORMAT));
  };
  return (
    <div className="BrokerDashboardComission">
      <div className="" style={{ marginBottom: 24 }}>
        <ArrowBackRoundedIcon
          style={{ fontSize: 44, cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
      </div>
      <DatePicker
        defaultValue={selectedMonth}
        onChange={onChange}
        // format="YYYY/MM"
        picker="month"
      />
      <div className="Row">
        <div className="WidgetContainer">
          <BrokerDashCard
            type="EARNING"
            data={gistData?.expected_earning}
            isLoading={isLoading}
            dataType="AMOUNT"
          />
        </div>

        <div className="WidgetContainer">
          <BrokerDashCard
            type="PAID"
            data={gistData?.paid_earning}
            isLoading={isLoading}
            dataType="AMOUNT"
          />
        </div>
        <div className="WidgetContainer">
          <BrokerDashCard
            type="UNPAID"
            data={gistData?.unpaid_earning}
            isLoading={isLoading}
            dataType="AMOUNT"
          />
        </div>
      </div>
      <div className="Row">
        <div className="WidgetContainer" style={{ width: "100%" }}>
          <EarningList brokerId={brokerId} />
        </div>
      </div>
    </div>
  );
}
