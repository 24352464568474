import { ConfigProvider, Tabs } from "antd";
import { colorPicker } from "app/utils/helpers/color.helper";
import "./tabbar.scss";
export default function TabBar(props) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            inkBarColor: colorPicker(props.color || "black"),
            itemSelectedColor: colorPicker(props.color || "black"),
            itemColor: colorPicker(props.inActiveColor || "black"),
          },
        },
      }}
    >
      <Tabs
        defaultActiveKey={props.activeTab || "1"}
        centered={!props.leftAligned}
        color="black"
        items={props.items}
        tabBarStyle={{ fontSize: 10 }}
        titleFontSize={16}
        tabBarGutter={140}
        onChange={props.onChange && props.onChange}

        //   margin={100}
      />
    </ConfigProvider>
  );
}
