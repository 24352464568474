import {
  EditOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Drawer, Modal, Select, Spin, Switch, Tag } from "antd";
import { apiGet, apiPut } from "app/services/apiService";
import Button from "app/shared/Button";
import Loader from "app/shared/Loader";
import LocationFinder from "app/shared/LocationFinder";
import PropertyCard from "app/shared/PropertyCard";
import Selector from "app/shared/Selector";
import TableView from "app/shared/TableView";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { parseURL } from "app/utils/helpers/common.helper";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  API_ENDPOINT_PROPERTY_FETCH_MY_PROPERTY,
  API_ENDPOINT_PROPERTY_UPDATE_STATUS,
} from "../../property.constants";
import PropertyDetail from "./PropertyDetail";
import PropertyFilters from "./PropertyFilter";
import "./propertylist.scss";
import { useNavigate } from "react-router";
import {
  PROPERTY_TYPES_BHK_FILTERS,
  PROPRERTY_STATUS_FILTER_MAP,
} from "app/constants/global.constants";

const tableColumn = [
  {
    title: "Property ID",
    index: "property_code",
    renderTag: true,
    search: true,
  },
  {
    title: "Name",
    index: "name",
    ellipsis: true,
    search: true,
  },
  {
    title: "Type",
    index: "property_type.name",
    renderTag: true,
  },

  {
    title: "BHK",
    render: (data) => {
      return data.bedroom ? data.bedroom + "BHK" : "NA";
    },
    filters: PROPERTY_TYPES_BHK_FILTERS,
    onFilter: (value, record) =>
      value >= 6 ? record.bedroom >= value : record.bedroom === value,
  },
  {
    title: "Total Units",
    render: (data) => {
      return data?.property_units_lists.length;
    },
  },
  {
    title: "Project",
    index: "property_project.name",
    renderTag: true,
    search: true,
  },

  {
    title: "City",
    index: "property_project.property_project_address.city",
    ellipsis: true,
    search: true,
  },
  {
    title: "Location",
    index: "property_project.property_project_address.address_one",
    ellipsis: true,
    search: true,
  },
  {
    title: "Cost",
    index: "cost",
    amount: true,
  },

  {
    title: "Status",
    index: "status",
    renderTag: true,
    // handleAction: (propsData) => propsData.handleAction(propsData),
    // customRender: (props) => {
    //   return (
    //     <Select
    //       defaultValue={props.status}
    //       style={{
    //         width: 120,
    //         height: 26,
    //       }}
    //       onChange={(data) =>
    //         props.handleAction({
    //           newStatus: data,
    //           eventType: "UPDATE_STATUS",
    //           ...props,
    //         })
    //       }
    //       options={[
    //         {
    //           value: "RESALE",
    //           label: "Resale",
    //         },
    //         {
    //           value: "APPROVED",
    //           label: "Approved",
    //         },
    //         {
    //           value: "DISABLED",
    //           label: "Disabeld",
    //         },
    //         {
    //           value: "UNDER_REVIEW",
    //           label: "Under Review",
    //         },
    //         {
    //           value: "SOLD",
    //           label: "Sold",
    //         },
    //       ]}
    //     />
    //   );
    // },
  },
];

const actionColumnObj = {
  title: "Action",
  renderAction: true,
  handleAction: (propsData) => propsData.handleAction(propsData),
  actions: [
    {
      event: "view_detail",
      icon: <SearchOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#69A2FD",
    },

    {
      event: "edit_detail",
      icon: <EditOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#f1c40f",
    },
  ],
};
export default function PropertyList(props) {
  const [properties, setProperties] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [showDrawerFor, setShowDrawerFor] = useState("");
  const [selectedProperty, setSelectedProperty] = useState("");
  const [openFilter, setOpenFileter] = useState(false);
  const [enableTableView, setEnableTableView] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    fetchProperties();
  }, []);

  const fetchProperties = async (filters = "") => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_PROPERTY_FETCH_MY_PROPERTY + filters
    );
    if (response.status) {
      setProperties(response.data);
    }
    setLoading(false);
  };
  const onFilterApply = (filterURL) => {
    fetchProperties(filterURL);
  };

  const handleOpenDrawer = (type) => {
    setShowDrawerFor(type);
    setOpenFileter(true);
  };

  const handleUpdatePropertyStatus = async (propertyId, status) => {
    const response = await apiPut(
      parseURL(API_ENDPOINT_PROPERTY_UPDATE_STATUS, {
        propertyId: propertyId,
        statusID: status,
      })
    );
    if (response.status) {
      fetchProperties();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    }
  };

  const handleTableAction = (event) => {
    switch (event.eventType) {
      case "edit_detail":
        navigate(
          "/property/project/unit/edit/" +
            event.rowData.propertyProjectId +
            "/" +
            event.rowData.id
        );
        return;
      case "view_detail":
        navigate("/property/" + event.rowData.id);
        return;
      case "UPDATE_STATUS":
        modal.confirm({
          title: "Confirm",
          icon: <ExclamationCircleOutlined />,
          content: "Are you sure want to change status",
          okText: "Yes",
          cancelText: "No",
          onOk: () => handleUpdatePropertyStatus(event.id, event.newStatus),
        });
      default:
        return null;
    }
  };

  return (
    <div className="PropertyList">
      <div className="TopSelectors">
        <div className="leftSelectors">
          <PropertyFilters onFilterApply={onFilterApply} />
        </div>
        {/* <div className="rightSelectors">
          <div className="WidgetContainer" style={{ minWidth: 0 }}>
            <Button
              background="black"
              onClick={() => handleOpenDrawer("FILTER")}
            >
              Filters
            </Button>
          </div>
          <div className="WidgetContainer" style={{ minWidth: 0 }}>
            <Button>Assign Property</Button>
          </div>
        </div> */}
      </div>
      <div className="ViewSwith">
        <Switch
          checked={enableTableView}
          style={{ marginRight: 8 }}
          onChange={() => setEnableTableView(!enableTableView)}
        />
        <Typography>Table View</Typography>
      </div>
      {enableTableView ? (
        <div>
          <TableView
            data={properties}
            isLoading={isLoading}
            column={[...tableColumn, actionColumnObj]}
            handleAction={handleTableAction}
          />
        </div>
      ) : (
        <div className="ListContainer">
          {isLoading ? (
            <Loader />
          ) : (
            properties.map((card, key) => {
              return (
                <div className="CardContainer" key={key}>
                  <PropertyCard
                    data={card}
                    onClick={() => {
                      handleOpenDrawer("DETAIL");
                      setSelectedProperty(card.id);
                    }}
                  />
                </div>
              );
            })
          )}
        </div>
      )}

      <Drawer
        title={
          showDrawerFor === "FILTER"
            ? "Filter your property"
            : "Property Detail"
        }
        open={openFilter}
        onClose={() => setOpenFileter(false)}
        width={showDrawerFor === "FILTER" ? 400 : 500}
      >
        {showDrawerFor === "FILTER" ? (
          <PropertyFilters onFilterApply={onFilterApply} />
        ) : (
          <PropertyDetail propertyId={selectedProperty} />
        )}
      </Drawer>
      {contextHolder}
    </div>
  );
}
