import Loader from "app/shared/Loader";
import { useEffect, useState } from "react";
import "./PropertyDetail.scss";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import AspectRatioOutlinedIcon from "@mui/icons-material/AspectRatioOutlined";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/helpers/color.helper";
import { Carousel } from "antd";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { apiGet } from "app/services/apiService";
import {
  API_ENDPOINT_PROPERTY_FETCH_IMAGES,
  API_ENDPOINT_PROPERTY_INFO,
} from "app/scenes/property/property.constants";
import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { useNavigate } from "react-router";
import { parseURL } from "app/utils/helpers/common.helper";
import { FormattedNumber } from "react-intl";
import { GLOBAL_VIDEO_FORMATS } from "app/constants/global.constants";
import DownloadIcon from "@mui/icons-material/Download";
const contentStyle = {
  margin: 0,
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};
export default function PropertyDetail(props) {
  const [isLoading, setLoading] = useState(false);
  const [propertyInfo, setPropertyInfo] = useState(null);
  const [images, setImages] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.propertyId) return;
    fetchPropertyInfo();
  }, [props.propertyId]);

  const fetchImages = async () => {
    const response = await apiGet(
      parseURL(API_ENDPOINT_PROPERTY_FETCH_IMAGES, {
        propertyId: props.propertyId,
      })
    );
    if (response.status && response.data.length > 0) {
      setImages(response.data);
    } else {
      setImages([
        {
          url: "https://images.ctfassets.net/bed00l8ra6lf/5gauKvJAKwG7FJQtEVz7vJ/a57c984e42f71fa8f216eca14f77cd84/Before_you_start.svg",
        },
      ]);
    }
  };

  const fetchPropertyInfo = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_PROPERTY_INFO + props.propertyId
    );
    if (response.status) {
      setPropertyInfo(response.data);
      fetchImages();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    }
    setLoading(false);
  };
  return isLoading ? (
    <Loader />
  ) : (
    <div className="PropertyDetail">
      <div className="Images">
        <Carousel autoplay>
          {images.map((image, key) => {
            return (
              <div key={key}>
                {GLOBAL_VIDEO_FORMATS.includes(
                  image.url.substring(image.url.lastIndexOf(".") + 1)
                ) ? (
                  <video
                    id="myVideo"
                    width="100%"
                    height="260"
                    autoplay
                    muted
                    controls="controls"
                  >
                    <source src={image.url} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    src={image.url}
                    alt="propertyImage"
                    style={{
                      height: 260,
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: 16,
                    }}
                  />
                )}
              </div>
            );
          })}
        </Carousel>
      </div>

      <div className="CardDetails">
        <Typography variant="body" style={{ marginTop: 14 }}>
          <b style={{ fontWeight: 700, color: colorPicker("red") }}>
            {" "}
            <FormattedNumber
              value={propertyInfo && propertyInfo.cost}
              style="currency"
              currency={"INR"}
            />
          </b>
        </Typography>
        <Typography variant="body" color="gray-dark" style={{ marginTop: 8 }}>
          {propertyInfo && propertyInfo.name}
        </Typography>

        <div className="Amentities">
          <div className="Amentity">
            <BedOutlinedIcon fontSize={"small"} style={{ color: "#000929" }} />
            <Typography variant="xs" style={{ marginLeft: 4 }} color="black">
              {propertyInfo && propertyInfo.BEDROOM_COUNT}
            </Typography>
          </div>

          <div className="Amentity">
            <BathtubOutlinedIcon
              fontSize={"small"}
              style={{ color: "#000929" }}
            />
            <Typography variant="xs" style={{ marginLeft: 4 }} color="black">
              {propertyInfo && propertyInfo.BATHROOM_COUNT}
            </Typography>
          </div>

          <div className="Amentity">
            <AspectRatioOutlinedIcon
              fontSize={"small"}
              style={{ color: "#000929" }}
            />
            <Typography variant="xs" style={{ marginLeft: 4 }} color="black">
              {propertyInfo && propertyInfo.size} sqft
            </Typography>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", marginTop: 12 }}>
          <LocationOnIcon fontSize={"small"} style={{ color: "#000929" }} />
          <Typography variant="caption" color="gray-dark">
            {propertyInfo && propertyInfo.property_address?.address_one}{" "}
            {propertyInfo && propertyInfo.property_address?.address_two}{" "}
            {propertyInfo && propertyInfo.property_address?.city}{" "}
            {propertyInfo && propertyInfo.property_address?.state}{" "}
            {propertyInfo && propertyInfo.property_address?.zip}
          </Typography>
        </div>
      </div>
      <div className="PaymentPlan">
        <Typography variant="caption" color="gray-dark">
          Payment Plan
        </Typography>
        <div className="cardContainer">
          <div className="CardHeader">
            <Typography color="white" variant="body">
              {(propertyInfo &&
                propertyInfo?.property_payment_plan?.plan_type) ||
                "NA"}
            </Typography>
            <Typography color="white" variant="body">
              {(propertyInfo &&
                propertyInfo?.property_payment_plan?.plan_duration) ||
                "NA"}{" "}
              Months Plan
            </Typography>
          </div>
          <div className="CardBody">
            <div className="InfoRow">
              <Typography
                color="gray-dark"
                variant="caption"
                style={{ width: "50%" }}
              >
                Downpayment
              </Typography>
              <Typography
                color="gray-dark"
                variant="caption"
                style={{ width: "50%", textAlign: "left" }}
              >
                {(propertyInfo &&
                  propertyInfo?.property_payment_plan?.downpayment) ||
                  "NA"}
              </Typography>
            </div>
            <div className="InfoRow">
              <Typography
                color="gray-dark"
                variant="caption"
                style={{ width: "50%" }}
              >
                Additional Cost
              </Typography>
              <Typography
                color="gray-dark"
                variant="caption"
                style={{ width: "50%", textAlign: "left" }}
              >
                {(propertyInfo &&
                  propertyInfo?.property_payment_plan?.additional_cost) ||
                  0}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="DocumentList">
        <Typography variant="caption" color="gray-dark">
          Property Documents
        </Typography>
        <div className="Documents">
          {propertyInfo &&
            propertyInfo.property_documents.map((document, key) => {
              return (
                <div
                  className="DocumentCard"
                  onClick={() => window.open(document.link, "_blank")}
                >
                  <Typography color="gray-dark" variant="caption">
                    {document.name || document.type.split("_").join(" ")}
                  </Typography>
                  <DownloadIcon
                    style={{ color: colorPicker("gray_dark"), fontSize: 22 }}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
