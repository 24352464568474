/* API ENDPOINTS */
export const API_ENDPOINT_PROPERTY_FETCH_MY_PROPERTY = `${process.env.REACT_APP_API_URL}/property/list`;
export const API_ENDPOINT_PROPERTY_FETCH_LIST_COMMON = `${process.env.REACT_APP_API_URL}/property/common/list`;
export const API_ENDPOINT_PROPERTY_FETCH_IMAGES = `${process.env.REACT_APP_API_URL}/property/{{propertyId}}/image`;
export const API_ENDPOINT_PROPERTY_UPDATE_IMAGE = `${process.env.REACT_APP_API_URL}/property/{{propertyId}}/image`;
export const API_ENDPOINT_PROPERTY_DELETE_IMAGE = `${process.env.REACT_APP_API_URL}/property/{{propertyId}}/image/{{imageId}}`;
export const API_ENDPOINT_PROPERTY_CREATE = `${process.env.REACT_APP_API_URL}/property/create`;
export const API_ENDPOINT_PROPERTY_ADD_PLAN = `${process.env.REACT_APP_API_URL}/property/{{propertyId}}/payment_plan`;
export const API_ENDPOINT_PROPERTY_UPDATE_DOCUMENT = `${process.env.REACT_APP_API_URL}/property/{{propertyId}}/document`;
export const API_ENDPOINT_PROPERTY_UPDATE_ADDRESS = `${process.env.REACT_APP_API_URL}/property/{{propertyId}}/address`;
export const API_ENDPOINT_PROPERTY_INFO = `${process.env.REACT_APP_API_URL}/property/`;
export const API_ENDPOINT_PROPERTY_UPDATE = `${process.env.REACT_APP_API_URL}/property/`;
export const API_ENDPOINT_PROJECT_PAYMENT_PLAN_INFO = `${process.env.REACT_APP_API_URL}/payment/project/{{projectId}}/plan/{{planId}}`;
export const API_ENDPOINT_PROJECT_INVESTORS_LIST = `${process.env.REACT_APP_API_URL}/projects/investors`;
export const API_ENDPOINT_PROJECT_INVESTOR_INFO = `${process.env.REACT_APP_API_URL}/projects/investors/`;
export const API_ENDPOINT_PROJECT_INVESTOR_ADD_INVESTMENT = `${process.env.REACT_APP_API_URL}/projects/investors/{{investorId}}/add_investment`;
export const API_ENDPOINT_PROJECT_LIST = `${process.env.REACT_APP_API_URL}/projects/list`;
export const API_ENDPOINT_PROJECT_DELETE = `${process.env.REACT_APP_API_URL}/projects/`;
export const API_ENDPOINT_PROJECT_INFO = `${process.env.REACT_APP_API_URL}/projects/`;
export const API_ENDPOINT_PROPERTY_SEARCH = `${process.env.REACT_APP_API_URL}/property/search/property`;
export const API_ENDPOINT_PROJECT_CREATE = `${process.env.REACT_APP_API_URL}/projects/create`;
export const API_ENDPOINT_PROJECT_ADDRESS_UPDATE = `${process.env.REACT_APP_API_URL}/projects/{{projectId}}/address`;
export const API_ENDPOINT_PROJECT_DELETE_IMAGE = `${process.env.REACT_APP_API_URL}/projects/{{projectId}}/image/{{imageId}}`;
export const API_ENDPOINT_PROJECT_BULK_UPDATE_IMAGE = `${process.env.REACT_APP_API_URL}/projects/{{projectId}}/images`;
export const API_ENDPOINT_PROJECT_UPDATE_INFO = `${process.env.REACT_APP_API_URL}/projects/`;
export const API_ENDPOINT_PROJECT_SEARCH = `${process.env.REACT_APP_API_URL}/projects/search/project`;
export const API_ENDPOINT_PROJECT_DELETE_DOCUMENT = `${process.env.REACT_APP_API_URL}/projects/{{projectId}}/documents/{{documentId}}`;

export const API_ENDPOINT_GLOBAL_FETCH_AMENTITY_LIST = `${process.env.REACT_APP_API_URL}/global/amentity/list`;
export const API_ENDPOINT_GLOBAL_DELETE_AMENTITY = `${process.env.REACT_APP_API_URL}/global/amentity/`;
export const API_ENDPOINT_GLOBAL_ADD_AMENTITY = `${process.env.REACT_APP_API_URL}/global/amentity`;
export const API_ENDPOINT_GLOBAL_ADD_FACILITY = `${process.env.REACT_APP_API_URL}/global/facility`;
export const API_ENDPOINT_GLOBAL_DELETE_FACILITY = `${process.env.REACT_APP_API_URL}/global/facility/`;
export const API_ENDPOINT_GLOBAL_LIST_PROPERTY_TYPE = `${process.env.REACT_APP_API_URL}/global/property_type/list`;
export const API_ENDPOINT_GLOBAL_DELETE_PROPERTY_TYPE = `${process.env.REACT_APP_API_URL}/global/property_type/`;
export const API_ENDPOINT_GLOBAL_ADD_PROPERTY_TYPES = `${process.env.REACT_APP_API_URL}/global/property_type`;
export const API_ENDPOINT_GLOBAL_LIST_PAYMENT_PLAN = `${process.env.REACT_APP_API_URL}/global/payment_plan/list`;
export const API_ENDPOINT_GLOBAL_ADD_PAYMENT_PLAN = `${process.env.REACT_APP_API_URL}/global/payment_plan`;
export const API_ENDPOINT_GLOBAL_DELETE_PAYMENT_PLAN = `${process.env.REACT_APP_API_URL}/global/payment_plan/`;
export const API_ENDPOINT_GLOBAL_UPDATE_MASTER_DATA = `${process.env.REACT_APP_API_URL}/global/master_data/`;
export const API_ENDPOINT_GLOBAL_UPDATE_PROPERTY_TYPE = `${process.env.REACT_APP_API_URL}/global/property_type/`;
export const API_ENDPOINT_GLOBAL_UPDATE_PAYMENT_PLAN = `${process.env.REACT_APP_API_URL}/global/payment_plan/{{projectId}}/{{planId}}`;
export const API_ENDPOINT_PROJECT_BULK_UPDATE_DOCUMENT = `${process.env.REACT_APP_API_URL}/projects/{{projectId}}/documents`;
export const API_ENDPOINT_GLOBAL_DELETE_BANK = `${process.env.REACT_APP_API_URL}/global/bank/`;
export const API_ENDPOINT_GLOBAL_ADD_BANK = `${process.env.REACT_APP_API_URL}/global/banks`;
export const API_ENDPOINT_GLOBAL_UPDATE_MASTER_DATA_STATUS = `${process.env.REACT_APP_API_URL}/global/master_data/{{masterDataId}}/status/{{statusId}}`;
export const API_ENDPOINT_PROPERTY_MANAGE_LIST = `${process.env.REACT_APP_API_URL}/property/listunapproved?type=`;
export const API_ENDPOINT_PROPERTY_UPDATE_STATUS = `${process.env.REACT_APP_API_URL}/property/{{propertyId}}/{{statusID}}`;
export const API_ENDPOINT_PROPERTY_DEVELOPER_UPDATE = `${process.env.REACT_APP_API_URL}/global/property/developer/`;
export const API_ENDPOINT_PROPERTY_DEVELOPER_ADD = `${process.env.REACT_APP_API_URL}/global/property/developer/add`;
export const API_ENDPOINT_PROPERTY_COMMUNITY_UPDATE = `${process.env.REACT_APP_API_URL}/global/property/community/`;
export const API_ENDPOINT_PROPERTY_COMMUNITY_ADD = `${process.env.REACT_APP_API_URL}/global/property/community/add`;
export const API_ENDPOINT_PROPERTY_COMMUNITY_DELETE = `${process.env.REACT_APP_API_URL}/global/property/community/`;
export const API_ENDPOINT_PROPERTY_FETCH_SALES_HISTORY = `${process.env.REACT_APP_API_URL}/property/sales/history?type=`;
export const API_ENDPOINT_PROPERTY_FETCH_SALES_POLICY_INFO = `${process.env.REACT_APP_API_URL}/property/sales/{{saleId}}/cancellation/brief`;
export const API_ENDPOINT_PROPERTY_FETCH_SALES_CANCEL = `${process.env.REACT_APP_API_URL}/property/sales/cancel/`;

export const API_ENDPOINT_PROPERTY_CREATE_BULK = `${process.env.REACT_APP_API_URL}/property/create/bulk`;
export const API_ENDPOINT_PROPERTY_CREATE_UNIT = `${process.env.REACT_APP_API_URL}/property/create/unit`;
export const API_ENDPOINT_PROPERTY_UPDATE_UNIT = `${process.env.REACT_APP_API_URL}/property/unit/update/`;
export const API_ENDPOINT_PROPERTY_UPDATE_UNIT_STATUS = `${process.env.REACT_APP_API_URL}/property/{{propertyId}}/unit/{{statusID}}`;
export const API_ENDPOINT_PROPERTY_INVENTORY_LIST = `${process.env.REACT_APP_API_URL}/property/listinventory?type=`;
