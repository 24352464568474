import { DatePicker, Form } from "antd";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import SearchSelector from "app/shared/SearchSelector";
import "./CreateReport.scss";
import TextEditor from "app/shared/TextEditor";
import {
  ANNOUNCEMENT_SEND_TO_MAP,
  GLOBAL_TIME_FORMAT,
  REPORT_TYPE_MAP,
} from "app/constants/global.constants";
import Selector from "app/shared/Selector";
import { useState } from "react";
import { apiPost } from "app/services/apiService";
import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { API_ENDPOINT_REPORT_CREATE } from "../../reports.constants";
import moment from "moment";

export default function CreateReport(props) {
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");

  const dispatch = useDispatch();
  const createReportInit = async () => {
    if (!name || !type || !fromTime || !toTime) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: "Please check your input", type: 0 },
      });
      return;
    }
    const payload = {
      name: name,
      type: type,
      from: fromTime,
      to: toTime,
    };

    const response = await apiPost(API_ENDPOINT_REPORT_CREATE, payload);
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onCreate && props.onCreate();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };
  return (
    <div className="CreateReport">
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={createReportInit}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={{ marginTop: 32 }}
      >
        <div
          className="form-row"
          style={{
            justifyContent: "flex-start",
            display: "flex",
            alignItems: "center",
            marginBottom: 24,
          }}
        >
          <div
            className="input-elements"
            style={{ marginRight: 24, width: "48%" }}
          >
            <Form.Item
              name={"name"}
              rules={[
                {
                  required: true,
                  message: "Please check your input",
                },
              ]}
              // initialValue={planName}
              style={{ marginBottom: 0 }}
            >
              <Input
                placeholder={"Report Name"}
                name={"Report Name"}
                onChange={(e) => setName(e.target.value)}
                //   defaultValue={}
              />
            </Form.Item>
          </div>

          <div className="input-elements" style={{ width: "48%" }}>
            <Form.Item name={"type"} style={{ marginBottom: 0, width: "100%" }}>
              <Selector
                placeholder="Report Type"
                serviceType="STATIC"
                valueKey="value"
                labelKey="label"
                onChange={(value) => setType(value)}
                height={50}
                //   defaultValue={projectName}
                staticData={REPORT_TYPE_MAP}
              />
            </Form.Item>
          </div>
        </div>

        <div
          className="form-row"
          style={{
            justifyContent: "flex-start",
            display: "flex",
            alignItems: "center",
            marginBottom: 24,
          }}
        >
          <div
            className="input-elements"
            style={{ marginRight: 24, width: "48%" }}
          >
            <Form.Item name="fromDate">
              <Typography variant="caption" style={{ marginBottom: 4 }}>
                From
              </Typography>
              <DatePicker
                name="fromDate"
                onChange={(ojb, dateString) =>
                  setFromTime(moment(dateString).format(GLOBAL_TIME_FORMAT))
                }
              />
            </Form.Item>
          </div>

          <div className="input-elements" style={{ width: "48%" }}>
            <Form.Item name="fromDate">
              <Typography variant="caption" style={{ marginBottom: 4 }}>
                To
              </Typography>
              <DatePicker
                name="toDate"
                onChange={(ojb, dateString) =>
                  setToTime(moment(dateString).format(GLOBAL_TIME_FORMAT))
                }
              />
            </Form.Item>
          </div>
        </div>

        <div className="action-buttons">
          <Form.Item style={{ width: 240 }}>
            <Button
              type="primary"
              size="large"
              style={{ width: "100%" }}
              htmlType="submit"
              //   loading={isLoading}
            >
              Send
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
