import Typography from "app/shared/Typography";
import PromotorIcon from "assets/images/promote.svg";
import HomeIcon from "assets/images/home.svg";
import ComissionIcon from "assets/images/comission.svg";
import VisitIcon from "assets/images/visit.svg";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import "./EarningList.scss";
import { colorPicker } from "app/utils/helpers/color.helper";
import { Avatar, Image } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  API_ENDPOINT_FETCH_BROKER_COMISSION_EARNING_LIST,
  API_ENDPOINT_FETCH_BROKER_REFERRALS,
} from "app/scenes/brokers/brokers.constants";
import { useEffect, useState } from "react";
import Loader from "app/shared/Loader";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { apiGet } from "app/services/apiService";
import TabBar from "app/shared/TabBar";
import CallReceivedRoundedIcon from "@mui/icons-material/CallReceivedRounded";
import CallMadeRoundedIcon from "@mui/icons-material/CallMadeRounded";
import TableView from "app/shared/TableView";
import { parseURL } from "app/utils/helpers/common.helper";
import moment from "moment";
import { PROPERTY_TYPES } from "app/constants/global.constants";
const imageType = {
  HOME: HomeIcon,
  VISIT: VisitIcon,
  COMISSION: ComissionIcon,
};

const salesTableColumn = [
  {
    title: "Property Name",
    index: "property.name",
    search: true,
  },
  {
    title: "Property Type",
    index: "property.property_type.name",
    renderTag: true,
    filters: PROPERTY_TYPES,
    onFilter: (value, record) =>
      record.property.property_type.name.indexOf(value) === 0,
  },
  {
    title: "Cost",
    index: "property.cost",
    amount: true,
  },

  {
    title: "Sale Date",
    index: "createdAt",
    date: true,
    defaultSortOrder: "descend",
    sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
  },
  {
    title: "Comission (%)",
    index: "comission",
  },
  {
    title: "Comission Amount",
    index: "amount",
    amount: true,
  },
  {
    title: "Level 2 Earning",
    index: "level_one_earning",
    amount: true,
  },
  {
    title: "Level 3 Earning",
    index: "level_two_earning",
    amount: true,
  },

  {
    title: "Comission Amount",
    index: "amount",
    amount: true,
  },
  {
    title: "Transaction Status",
    index: "status",
    renderTag: true,
  },
];
const referralTableColumn = [
  {
    title: "Referred On",
    index: "createdAt",
    date: true,
    defaultSortOrder: "descend",
    sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
  },

  {
    title: "Comission Amount",
    index: "amount",
    amount: true,
  },
  {
    title: "Transaction Status",
    index: "status",
    renderTag: true,
  },
];

export default function EarningList(props) {
  const [listData, setListData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("active TA", activeTab);
    fetchListData();
  }, [activeTab]);
  const fetchListData = async () => {
    setLoading(true);
    const parsedURL = parseURL(
      API_ENDPOINT_FETCH_BROKER_COMISSION_EARNING_LIST,
      {
        brokerId: props.brokerId,
      }
    );
    const response = await apiGet(
      parsedURL + (activeTab === "2" ? "REFERRAL" : "SALE")
    );
    if (response.status) {
      setListData(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  const onChange = (key) => {
    console.log(key);
  };

  const renderTabContent = () => {
    return (
      <div className="EarningListContent">
        {/* <div className="EarningTypes">
          <div className="EarningTypeCard">
            <div className="IconBox">
              <CallReceivedRoundedIcon />
            </div>
            <div className="EarningDetail">
              <Typography variant="h5" color="green">
                5000
              </Typography>
              <Typography color="gray-dark">Paid</Typography>
            </div>
          </div>

          <div className="EarningTypeCard">
            <div className="IconBox">
              <CallMadeRoundedIcon />
            </div>
            <div className="EarningDetail">
              <Typography variant="h5" color="green">
                2400
              </Typography>
              <Typography color="gray-dark">UnPaid</Typography>
            </div>
          </div>
        </div> */}
        <div className="TableContainer">
          <TableView
            data={listData}
            isLoading={isLoading}
            column={activeTab === "2" ? referralTableColumn : salesTableColumn}
            // handleAction={handleTableAction}
          />
        </div>
      </div>
    );
  };
  const items = [
    {
      key: "1",
      label: "Sales Comission",
      children: renderTabContent(),
    },
    {
      key: "2",
      label: "Referral Comission",
      children: renderTabContent(),
    },
  ];

  return (
    <div className="EarningList">
      <TabBar
        items={items}
        color="red"
        leftAligned
        inActiveColor="gray_dark"
        onChange={(tab) => setActiveTab(tab)}
      />
    </div>
  );
}
