import { Area } from "@ant-design/plots";
import { ResponsiveLine } from "@nivo/line";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/helpers/color.helper";
import {
  API_ENDPOINT_DASHBOARD_FETCH_LEAD_OVERVIEW,
  API_ENDPOINT_DASHBOARD_FETCH_SALES_OVERVIEW,
} from "../../dashboard.constants";
import { apiGet } from "app/services/apiService";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { SignalCellularNodata } from "@mui/icons-material";
import moment from "moment";
import { GLOBAL_TIME_FORMAT } from "app/constants/global.constants";
import { Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

export default function LeadOverview(props) {
  const [isLoading, setLoading] = useState(true);
  const [overviewData, setOverviewData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchOverviewData();
  }, []);
  const fetchOverviewData = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_DASHBOARD_FETCH_LEAD_OVERVIEW +
        "?start=2024-01-14T16:04:35.000Z&end=2024-01-30T16:04:35.000Z"
    );
    if (response.status) {
      setOverviewData(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  const processData = (arrayData) => {
    let addedResp = {
      id: "Added",
      data: [],
    };
    let convertedResp = {
      id: "Converted",
      data: [],
    };
    arrayData &&
      arrayData.forEach((saleData) => {
        addedResp.data.push({
          x: moment(saleData.date).format("YYYY-MM-DD"),
          y: saleData?.added,
          color: "hsl(40, 70%, 50%)",
        });

        convertedResp.data.push({
          x: moment(saleData.date).format("YYYY-MM-DD"),
          y: saleData?.converted,
          color: "hsl(40, 70%, 50%)",
        });
      });

    return [addedResp, convertedResp];
  };

  const config = {
    xField: "Date",
    yField: "scales",
    xAxis: {
      range: [0, 1],
      tickCount: 5,
    },
    yAxis: {
      tickCount: 5,
    },
  };
  return (
    <div
      className="SalesOverview"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Typography>Lead Overview</Typography>

        <Popover
          placement="bottom"
          title={"Lead Overview"}
          content={
            "Understand how your leads are progressing by understanding no of leads created and converted"
          }
        >
          <InfoCircleOutlined />
        </Popover>
      </div>
      <div style={{ height: "90%" }}>
        <ResponsiveLine
          data={processData(overviewData || [])}
          margin={{ top: 40, right: 20, bottom: 30, left: 30 }}
          yScale={{
            type: "linear",
            min: "0",
            max: "auto",
          }}
          xFormat="time:%Y-%m-%d"
          curve="monotoneX"
          axisTop={null}
          colors={[colorPicker("green"), colorPicker("red")]}
          xScale={{
            type: "time",
            format: "%Y-%m-%d",
            useUTC: false,
            precision: "day",
          }}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 30,
            legendOffset: 36,
            legendPosition: "middle",
            format: "%b %d",
          }}
          defs={[
            {
              colors: [
                {
                  color: "inherit",
                  offset: 0,
                },
                {
                  color: "inherit",
                  offset: 100,
                  opacity: 0,
                },
              ],
              id: "gradientA",
              type: "linearGradient",
            },
          ]}
          // axisLeft={{
          //   tickValues: [0, 5, 10, 20],
          //   format: (tick) => `${tick}`,
          // }}
          enableGridX={false}
          enableGridY={false}
          enablePoints={false}
          pointSize={10}
          pointBorderWidth={2}
          enableArea={true}
          enableCrosshair={false}
          useMesh={true}
          fill={[
            {
              id: "gradientA",
              match: "*",
            },
          ]}
          legends={[]}
        />
      </div>
    </div>
  );
}
