import { ConfigProvider, Form, Modal, Select, Slider, Tag, Upload } from "antd";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import Selector from "app/shared/Selector";
import SimpleFileSelector from "app/shared/SimpleFileSelector";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/helpers/color.helper";
import STATE_LIST from "app/constants/states.json";
import "../../property_create.scss";
import { useEffect, useState } from "react";
import { apiGet, apiPost, apiPostForm, apiPut } from "app/services/apiService";
import {
  API_ENDPOINT_PROPERTY_CREATE,
  API_ENDPOINT_PROPERTY_UPDATE_ADDRESS,
  API_ENDPOINT_PROPERTY_UPDATE_DOCUMENT,
  API_ENDPOINT_PROPERTY_INFO,
  API_ENDPOINT_PROPERTY_UPDATE,
  API_ENDPOINT_PROJECT_INFO,
} from "../../../../property.constants";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { useDispatch } from "react-redux";
import {
  COMISSION_TYPES,
  FLOOR_MAP,
  LAND_AREA_SIZE_UNITS,
  PROPERTY_DOCUMENT_TYPE_FOR_CREATE,
} from "app/constants/global.constants";
import MapPicker from "app/shared/MapPicker";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { parseURL } from "app/utils/helpers/common.helper";
import { useParams } from "react-router";
import Loader from "app/shared/Loader";
import LocationPicker from "app/shared/LocationPicker";
import SearchSelector from "app/shared/SearchSelector";

export default function BasicPropertyInfo(props) {
  const [showImageDialog, setShowImageDialog] = useState(false);
  const [createdPropertyId, setCreatedPropertyId] = useState(null);
  const [proprtyType, setPropertyType] = useState("");
  const [propertyCategory, setPropertyCategory] = useState("");

  const [formData, setFormData] = useState({
    size: 500,
    size_unit: "sqft",
    state: "Rajasthan",
  });
  const [isLoading, setLoading] = useState(false);
  const [fetchingProjectInfo, setFetchingProjectInfo] = useState(false);

  const [updateLoading, setUpdatedLoading] = useState(false);
  const [projectInfo, setProjectInfo] = useState(null);
  const dispatch = useDispatch();

  let { propertyId } = useParams();
  useEffect(() => {
    if (props.editMode) fetchPropertyDetails();
  }, []);
  const fetchPropertyDetails = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_PROPERTY_INFO + propertyId);
    if (response.status) {
      handleInitialValues(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };
  const handleInitialValues = (respData) => {
    let data = {
      propertyName: respData?.name,
      size: respData?.size,
      size_unit: respData?.size_unit,
      productPrice: respData?.cost,
      propertyType:
        (respData.property_type && respData.property_type?.id) || "",
      propertyCategory:
        (respData.property_type &&
          respData.property_type?.property_category?.id) ||
        "",
      propertyDeveloper:
        (respData.property_developer && respData.property_developer?.id) || "",
      propertyProject:
        respData.property_project && respData.property_project?.id,
      propertyCommunity:
        (respData.property_community && respData.property_community?.id) ||
        null,
      bedroom: respData.BEDROOM_COUNT && respData.BEDROOM_COUNT,
      bathroom: respData.BATHROOM_COUNT && respData.BATHROOM_COUNT,
      address_one:
        respData.property_address && respData.property_address?.address_one,
      landmark:
        respData.property_address && respData.property_address?.landmark,
      district_city:
        respData?.property_address && respData?.property_address?.city,
      zip: respData?.property_address && respData?.property_address?.zip,
      state: respData?.property_address && respData?.property_address?.state,
      comission: respData?.comission,
      comissionType: respData?.comssionType,
      token_amount: respData?.booking_amount,
      latlong:
        respData?.property_address && respData?.property_address?.latlong,
      saleType: respData?.transaction_type,
      furnishing: respData?.furnish_status,
      moveStatus: respData?.movein_status,
      floor: respData?.floor || 0,
      prop_age: respData?.property_age,
      facing: respData?.facing,
      cafeteria: respData?.cafeteria,
      seat_capacity: respData?.seat_capacity,
      cabins: respData?.cabins,
      total_floor: respData?.total_floor,
      east_size: respData?.property_plot_road?.east_road,
      west_size: respData?.property_plot_road?.west_road,
      north_size: respData?.property_plot_road?.north_road,
      south_size: respData?.property_plot_road?.south_road,
    };
    data.amentities =
      respData?.property_amentities &&
      respData?.property_amentities.map((item) => item.amentity.id);

    // set property docs
    respData?.property_documents &&
      respData?.property_documents.map((document) => {
        data[document.type.toLowerCase()] = {
          name:
            (document.name || document.type.toLowerCase()) +
            "." +
            document.link.split(".")[document.link.split(".").length - 1],
          uploaded: true,
        };
      });
    setPropertyType(respData?.property_type && respData?.property_type);
    setPropertyCategory(
      respData?.property_type && respData.property_type?.property_category
    );

    setFormData(JSON.parse(JSON.stringify(data)));
    setLoading(false);
  };

  const updateProperty = async () => {
    setUpdatedLoading(true);
    const payload = {
      property_name: formData.propertyName || null,
      size: formData.size,
      cost: formData.productPrice,
      comission: formData.comission,
      comssionType: formData.comissionType,
      property_type: formData.propertyType,
      propertyDeveloperId: formData.propertyDeveloper,
      propertyProjectId: formData.propertyProject,
      propertyCommunityId: formData.propertyCommunity,
      bedroom: formData.bedroom && parseInt(formData.bedroom),
      bathroom: formData.bathroom && parseInt(formData.bathroom),
      amentities: formData.amentities,
      size_unit: formData.size_unit,
      booking_amount: formData.token_amount,
      movein_status: formData.moveStatus,
      transaction_type: formData.saleType,
      furnish_status: formData.furnishing,
      facing: formData.facing,
      property_age: formData.prop_age,
      floor: formData.floor,
      seat_capacity: formData.seat_capacity,
      cabins: formData.cabins,
      cafeteria: formData.cafeteria,
      total_floor: formData.total_floor,
      east_size: formData.east_size,
      west_size: formData.west_size,
      north_size: formData.north_size,
      south_size: formData.south_size,
    };

    const response = await apiPut(
      API_ENDPOINT_PROPERTY_UPDATE + propertyId,
      payload
    );
    if (response.status) {
      setCreatedPropertyId(response.data.id);
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      updatePropertyDocument(propertyId);
      updatePropertyAddress(propertyId);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    // setLoading(false);
  };

  const createProperty = async () => {
    // setLoading(true);
    const payload = {
      property_name: formData.propertyName || null,
      size: formData.size,
      cost: formData.productPrice,
      transaction_type: "NEW_PROPERTY",
      furnish_status: "FULLY_FURNISHED",
      property_age: 1,
      comission: formData.comission,
      comssionType: formData.comissionType,
      facing: "WEST",
      property_type: formData.propertyType,
      propertyDeveloperId: formData.propertyDeveloper,
      propertyProjectId: formData.propertyProject,
      propertyCommunityId: formData.propertyCommunity,
      bedroom: formData.bedroom && parseInt(formData.bedroom),
      bathroom: formData.bathroom && parseInt(formData.bathroom),
      amentities: formData.amentities,
      movein_status: "READY_TO_MOVE",
      size_unit: formData.size_unit,
      booking_amount: formData.token_amount,
      movein_status: formData.moveStatus,
      transaction_type: formData.saleType,
      furnish_status: formData.furnishing,
      facing: formData.facing,
      property_age: formData.prop_age,
      floor: formData.floor,
      seat_capacity: formData.seat_capacity,
      cabins: formData.cabins,
      cafeteria: formData.cafeteria,
      total_floor: formData.total_floor,
      east_size: formData.east_size,
      west_size: formData.west_size,
      north_size: formData.north_size,
      south_size: formData.south_size,
    };
    if (!payload.comssionType) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: {
          message: "Property Comission type is not yet selected",
          type: 0,
        },
      });
      return;
    }

    if (
      !payload.property_type ||
      !payload.propertyProjectId ||
      !formData.state
    ) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: {
          message:
            "Please check your input field some data are missing eg: property_type,projectId,state ",
          type: 0,
        },
      });
      return;
    }

    const response = await apiPost(API_ENDPOINT_PROPERTY_CREATE, payload);
    if (response.status) {
      setCreatedPropertyId(response.data.propertyProjectId);
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      updatePropertyDocument(
        response.data.id,
        response.data.propertyProjectId,
        response.data?.cost
      );
      updatePropertyAddress(response.data.id);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  const updatePropertyAddress = async (newPropertyId) => {
    const payload = {
      address_one: formData.address_one || null,
      landmark: formData.landmark || null,
      city: formData.district_city,
      state: formData.state,
      zip: formData.zip,
      country: "India",
      propertyId: newPropertyId,
      latlong: formData.latlong,
    };

    const response = await apiPost(
      parseURL(API_ENDPOINT_PROPERTY_UPDATE_ADDRESS, {
        propertyId: newPropertyId,
      }),
      payload
    );
    if (response.status) {
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };
  const updatePropertyDocument = async (
    newPropertyId,
    newProjectId = formData.propertyProject,
    newPropertyCost = 0
  ) => {
    PROPERTY_DOCUMENT_TYPE_FOR_CREATE.map(async (doc_type) => {
      if (!formData[doc_type.key] || formData[doc_type.key].uploaded) return;
      let fileFormData = new FormData();
      fileFormData.append("file", formData[doc_type.key]);
      fileFormData.append("doc_type", doc_type.type);

      const response = await apiPostForm(
        parseURL(API_ENDPOINT_PROPERTY_UPDATE_DOCUMENT, {
          propertyId: newPropertyId,
        }),
        fileFormData
      );
      if (response.status) {
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: response.message, type: 1 },
        });
      } else {
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: response.message, type: 0 },
        });
      }
    });
    props.onSuccess &&
      props.onSuccess(newPropertyId, newProjectId, newPropertyCost);

    setUpdatedLoading(false);
  };
  const handleInputChange = (e) => {
    console.log("data input:", e);
    const { name, value } = e.target;
    if (name === "propertyType" && e.target.obj) {
      setPropertyType(e.target.obj);
    }

    if (name === "propertyCategory" && e.target.obj) {
      setPropertyCategory(e.target.obj);
    }
    if (name === "propertyProject" && props.editMode && isLoading) {
      fetchProjectInfo(value);
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const fetchProjectInfo = async (projectId) => {
    setFetchingProjectInfo(true);
    const response = await apiGet(API_ENDPOINT_PROJECT_INFO + projectId);
    if (response.status) {
      setProjectInfo(response.data);
      // handleInitialValues(response.data);`
      let prevObjData = JSON.parse(JSON.stringify(formData));
      prevObjData.address_one =
        response.data.property_project_address &&
        response.data.property_project_address?.address_one;
      prevObjData.landmark =
        response.data.property_project_address &&
        response.data.property_project_address?.landmark;
      prevObjData.district_city =
        response.data.property_project_address &&
        response.data.property_project_address?.city;
      prevObjData.zip =
        response.data.property_project_address &&
        response.data.property_project_address?.zip;
      prevObjData.state =
        response.data.property_project_address &&
        response.data.property_project_address?.state;
      setFormData(prevObjData);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setFetchingProjectInfo(false);
  };
  const sliderTooltipFormatPerc = (value) => `${value}%`;
  const sliderTooltipFormat = (value) => `${value} ${formData.size_unit}`;
  return (props.editMode && isLoading) || fetchingProjectInfo ? (
    <Loader />
  ) : (
    <ConfigProvider
      theme={{
        components: {
          Slider: {
            railBg: "#7C8091",
            dotActiveBorderColor: colorPicker("red"),
            colorText: colorPicker("red"),
            trackBg: colorPicker("red"),
            trackHoverBg: colorPicker("red"),
            handleColor: colorPicker("red"),
            handleActiveColor: colorPicker("red"),
          },
        },
      }}
    >
      <div className="PropertyCreate">
        <Form
          name="basic"
          fields={[
            // {
            //   name: "propertyCategory",
            //   errors: !formData.propertyCategory
            //     ? ["Property Category cannot be empty"]
            //     : [],
            // },
            // {
            //   name: "propertyCategory",
            //   errors: !formData.propertyType
            //     ? ["Property Type cannot be empty"]
            //     : [],
            // },
            {
              name: "size",
              errors: !formData.size ? ["Property Size is not set"] : [],
            },
          ]}
          // initialValues={{ remember: true, ...formData }}
          onFinish={props.editMode ? updateProperty : createProperty}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{
            ...formData,
            ["district_city"]: formData.district_city,
          }}
        >
          <div className="form-row" style={{ marginBottom: 12 }}>
            <div className="input-element">
              <Form.Item
                name="propertyCategory"
                initialValue={formData.propertyCategory}
                required
                status={{ errors: ["Fdsfs"] }}
              >
                <Typography
                  variant="body"
                  weight={400}
                  style={{ marginLeft: 4, marginBottom: 4 }}
                >
                  Property Category
                </Typography>
                <Selector
                  placeholder="Property Category"
                  labelKey="name"
                  valueKey="id"
                  serviceType="PROPERTY_CATEGORY"
                  onChange={handleInputChange}
                  name="propertyCategory"
                  fullObject
                  defaultValue={formData.propertyCategory}
                />
              </Form.Item>
            </div>
            <div className="input-element">
              <Form.Item name="propertyType">
                <Typography
                  variant="body"
                  weight={400}
                  style={{ marginLeft: 4, marginBottom: 4 }}
                >
                  Property Type
                </Typography>
                <Selector
                  placeholder="Property Type"
                  labelKey="name"
                  valueKey="id"
                  serviceType="PROPERTY_TYPES"
                  onChange={handleInputChange}
                  name="propertyType"
                  fullObject
                  defaultValue={formData.propertyType}
                  disabled={!formData.propertyCategory}
                  queryFilter={{
                    key: "propertyCategoryId",
                    dataValue: formData.propertyCategory,
                  }}
                />
              </Form.Item>
            </div>
            <div className="input-element">
              <LocationPicker
                selectCordinate={(cordinate) =>
                  handleInputChange({
                    target: {
                      name: "latlong",
                      value: cordinate.lat + "," + cordinate.long,
                    },
                  })
                }
                defaultValue={formData.latlong}
              />
            </div>
          </div>
          <div className="form-row" style={{ marginBottom: 24 }}>
            <div className="input-element">
              <Form.Item name="propertyDeveloper">
                <Typography
                  variant="body"
                  weight={400}
                  style={{ marginLeft: 4, marginBottom: 4 }}
                >
                  Property Developer
                </Typography>
                <Selector
                  placeholder="Property Developer"
                  labelKey="name"
                  valueKey="id"
                  serviceType="PROPERTY_DEVELOPERS"
                  onChange={handleInputChange}
                  name="propertyDeveloper"
                  defaultValue={formData.propertyDeveloper}
                />
              </Form.Item>
            </div>

            <div className="input-element">
              <Form.Item name="propertyProject">
                <Typography
                  variant="body"
                  weight={400}
                  style={{ marginLeft: 4, marginBottom: 4 }}
                >
                  Property Project
                </Typography>
                <Selector
                  placeholder="Property Project"
                  labelKey="name"
                  valueKey="id"
                  serviceType="PROPERTY_PROJECTS"
                  onChange={handleInputChange}
                  name="propertyProject"
                  defaultValue={formData.propertyProject}
                />
              </Form.Item>
            </div>

            <div className="input-element">
              <Form.Item name="propertyCommunity">
                <Typography
                  variant="body"
                  weight={400}
                  style={{ marginLeft: 4, marginBottom: 4 }}
                >
                  Property Community
                </Typography>
                <Selector
                  placeholder=" Property Community"
                  labelKey="name"
                  valueKey="id"
                  serviceType="PROPERTY_COMMUNITY"
                  onChange={handleInputChange}
                  name="propertyCommunity"
                  defaultValue={formData.propertyCommunity}
                />
              </Form.Item>
            </div>
          </div>

          <div className="form-row" style={{ marginBottom: 12 }}>
            <div className="input-element">
              <Form.Item
                name="propertyName"
                initialValue={formData.propertyName}
                rules={[
                  {
                    required: true,
                    message: "Please check your input",
                  },
                ]}
              >
                <Input
                  placeholder="Property Name"
                  label="Property Name"
                  background="white"
                  onChange={handleInputChange}
                  name="propertyName"
                  defaultValue={formData.propertyName}
                />
              </Form.Item>
            </div>
            <div className="input-element">
              <Form.Item
                name="productprice"
                rules={[
                  {
                    required: true,
                    message: "Please check your input",
                  },
                ]}
                initialValue={formData.productPrice}
              >
                <Input
                  placeholder="Price"
                  label="Enter Price"
                  background="white"
                  onChange={handleInputChange}
                  name="productPrice"
                  defaultValue={formData.productPrice}
                  prefix={
                    <CurrencyRupeeIcon
                      style={{ fontSize: 16, color: colorPicker("gray_dark") }}
                    />
                  }
                />
              </Form.Item>
            </div>
            <div className="input-element">
              <Form.Item
                name="token_amount"
                rules={[
                  {
                    required: true,
                    message: "Please check your input",
                  },
                ]}
                initialValue={formData.token_amount}
              >
                <Input
                  placeholder="Booking Amount"
                  label="Booking Amount"
                  background="white"
                  onChange={handleInputChange}
                  name="token_amount"
                  defaultValue={formData.token_amount}
                  prefix={
                    <CurrencyRupeeIcon
                      style={{ fontSize: 16, color: colorPicker("gray_dark") }}
                    />
                  }
                />
              </Form.Item>
            </div>
          </div>
          {proprtyType?.name !== "Plot" ? (
            <div className="form-row" style={{ marginBottom: 12 }}>
              <div className="input-element">
                <Form.Item
                  name="bathroom"
                  rules={[
                    {
                      required: true,
                      message: "Please check your input",
                    },
                  ]}
                  initialValue={formData.bathroom}
                >
                  <Input
                    placeholder="Bathroom"
                    label="Bathroom"
                    background="white"
                    name="bathroom"
                    onChange={handleInputChange}
                    defaultValue={formData.bathroom}
                  />
                </Form.Item>
              </div>
              {propertyCategory?.name === "Commercial" ? null : (
                <div className="input-element">
                  <Form.Item
                    name="Bedroom"
                    rules={[
                      {
                        required: true,
                        message: "Please check your input",
                      },
                    ]}
                    initialValue={formData.bedroom}
                  >
                    <Input
                      placeholder="Bedroom"
                      label="Bedroom"
                      background="white"
                      name="bedroom"
                      onChange={handleInputChange}
                      defaultValue={formData.bedroom}
                    />
                  </Form.Item>
                </div>
              )}

              <div className="input-element">
                <Form.Item name="amentities">
                  <Typography
                    variant="body"
                    weight={400}
                    style={{ marginLeft: 4, marginBottom: 4 }}
                  >
                    Amentities
                  </Typography>
                  <Selector
                    mode="multiple"
                    placeholder="Amentities"
                    labelKey="name"
                    valueKey="id"
                    serviceType="AMENTITIES"
                    name="amentities"
                    onChange={handleInputChange}
                    defaultValue={formData.amentities}
                  />
                </Form.Item>
              </div>
              {propertyCategory?.name === "Commercial" ? (
                <div className="input-element"></div>
              ) : null}
            </div>
          ) : null}
          {proprtyType?.name !== "Plot" ? (
            <div className="form-row" style={{ marginBottom: 12 }}>
              <div className="input-element">
                <Form.Item
                  name="saleType"
                  initialValue={formData.saleType}
                  required
                  status={{ errors: ["Fdsfs"] }}
                >
                  <Typography
                    variant="body"
                    weight={400}
                    style={{ marginLeft: 4, marginBottom: 4 }}
                  >
                    Sale Type
                  </Typography>
                  <Selector
                    placeholder="Sale Type"
                    labelKey="name"
                    valueKey="id"
                    serviceType="STATIC"
                    onChange={handleInputChange}
                    name="saleType"
                    fullObject
                    staticData={[
                      {
                        name: "New Sale",
                        id: "NEW_PROPERTY",
                      },
                      {
                        name: "Resale",
                        id: "RESALE",
                      },
                    ]}
                    defaultValue={formData.saleType}
                  />
                </Form.Item>
              </div>
              <div className="input-element">
                <Form.Item name="moveStatus">
                  <Typography
                    variant="body"
                    weight={400}
                    style={{ marginLeft: 4, marginBottom: 4 }}
                  >
                    Posession Status
                  </Typography>
                  <Selector
                    placeholder="Posession Status"
                    labelKey="name"
                    valueKey="id"
                    serviceType="STATIC"
                    onChange={handleInputChange}
                    name="moveStatus"
                    staticData={[
                      {
                        name: "Under Construction",
                        id: "UNDER_CONSTRUCTION",
                      },
                      {
                        name: "Ready To Move",
                        id: "READY_TO_MOVE",
                      },
                    ]}
                    fullObject
                    defaultValue={formData.moveStatus}
                  />
                </Form.Item>
              </div>
              <div className="input-element">
                <Form.Item
                  name="furnishing"
                  initialValue={formData.propertyCategory}
                  required
                  status={{ errors: ["Fdsfs"] }}
                >
                  <Typography
                    variant="body"
                    weight={400}
                    style={{ marginLeft: 4, marginBottom: 4 }}
                  >
                    Furnishing
                  </Typography>
                  <Selector
                    placeholder="Furnishing"
                    labelKey="name"
                    valueKey="id"
                    serviceType="STATIC"
                    onChange={handleInputChange}
                    name="furnishing"
                    fullObject
                    staticData={[
                      {
                        name: "Full Furnished",
                        id: "FULLY_FURNISHED",
                      },
                      {
                        name: "Semi Furnished",
                        id: "SEMI_FURNISHED",
                      },
                      {
                        name: "Unfurnished",
                        id: "UNFURNISHED",
                      },
                    ]}
                    defaultValue={formData.furnishing}
                  />
                </Form.Item>
              </div>
            </div>
          ) : null}
          {proprtyType?.name !== "Plot" ? (
            <div className="form-row" style={{ marginBottom: 24 }}>
              <div className="input-element">
                <Form.Item name="facing">
                  <Typography
                    variant="body"
                    weight={400}
                    style={{ marginLeft: 4, marginBottom: 4 }}
                  >
                    Facing
                  </Typography>
                  <Selector
                    placeholder="Facing"
                    labelKey="name"
                    valueKey="id"
                    serviceType="STATIC"
                    staticData={[
                      {
                        name: "East",
                        id: "EAST",
                      },
                      {
                        name: "West",
                        id: "WEST",
                      },
                      {
                        name: "North",
                        id: "NORTH",
                      },
                      {
                        name: "South",
                        id: "SOUTH",
                      },
                    ]}
                    name="facing"
                    onChange={handleInputChange}
                    defaultValue={formData.facing}
                  />
                </Form.Item>
              </div>

              <div className="input-element">
                <Form.Item name="floor">
                  <Typography
                    variant="body"
                    weight={400}
                    style={{ marginLeft: 4, marginBottom: 4 }}
                  >
                    Floor
                  </Typography>
                  <Selector
                    placeholder="Floor"
                    labelKey="name"
                    valueKey="id"
                    serviceType="STATIC"
                    staticData={FLOOR_MAP}
                    name="floor"
                    onChange={handleInputChange}
                    defaultValue={formData.floor}
                  />
                </Form.Item>
              </div>
              <div className="input-element">
                <Form.Item
                  name="prop_age"
                  rules={[
                    {
                      required: true,
                      message: "Please check your input",
                    },
                  ]}
                >
                  <Input
                    placeholder="Property Age"
                    label="Property Age"
                    background="white"
                    name="prop_age"
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </div>
            </div>
          ) : null}
          {propertyCategory && propertyCategory.name !== "Residential" ? (
            <div className="form-row" style={{ marginBottom: 24 }}>
              <div className="input-element">
                <Form.Item name="cafeteria">
                  <Typography
                    variant="body"
                    weight={400}
                    style={{ marginLeft: 4, marginBottom: 4 }}
                  >
                    Cafeteria
                  </Typography>
                  <Selector
                    placeholder="Cafeteria"
                    labelKey="name"
                    valueKey="id"
                    serviceType="STATIC"
                    staticData={[
                      {
                        name: "Dry",
                        id: "DRY",
                      },
                      {
                        name: "Wet",
                        id: "WET",
                      },
                    ]}
                    name="cafeteria"
                    onChange={handleInputChange}
                    defaultValue={formData.cafeteria}
                  />
                </Form.Item>
              </div>

              <div className="input-element">
                <Form.Item
                  name="seat_capacity"
                  rules={[
                    {
                      required: true,
                      message: "Please check your input",
                    },
                  ]}
                >
                  <Input
                    placeholder="Seat Capacity"
                    label="Seat Capacity"
                    background="white"
                    name="seat_capacity"
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </div>
              <div className="input-element">
                <Form.Item
                  name="cabins"
                  rules={[
                    {
                      required: true,
                      message: "Please check your input",
                    },
                  ]}
                >
                  <Input
                    placeholder="Total Cabins"
                    label="Total Cabins"
                    background="white"
                    name="cabins"
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </div>
            </div>
          ) : null}
          <div className="form-row" style={{ marginBottom: 24 }}>
            <div className="input-element">
              <Form.Item name="comissionType">
                <Typography
                  variant="body"
                  weight={400}
                  style={{ marginLeft: 4, marginBottom: 4 }}
                >
                  Comission Type
                </Typography>
                <Selector
                  placeholder="Comission Type"
                  labelKey="label"
                  valueKey="value"
                  serviceType="STATIC"
                  staticData={COMISSION_TYPES}
                  name="comissionType"
                  onChange={handleInputChange}
                  defaultValue={formData.comissionType}
                />
              </Form.Item>
            </div>
            {formData && formData.comissionType === "FIXED" && (
              <div className="input-element">
                <Form.Item name="comission">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="body"
                      weight={400}
                      style={{ marginLeft: 4, marginBottom: 4 }}
                    >
                      Comission (max: 20%)
                    </Typography>
                    <Input
                      placeholder="(eg: 0.25%)"
                      background="white"
                      name="comission"
                      // onChange={handleInputChange}
                      value={formData.comission || ""}
                      maxLength={5}
                      max={20.0}
                      size="small"
                      height={38}
                      onChange={(e) =>
                        handleInputChange({
                          target: {
                            name: "comission",
                            value: parseFloat(e.target.value),
                          },
                        })
                      }
                    />
                  </div>

                  <Slider
                    value={formData.comission || 0}
                    marks={{ 0.1: "0%", 20.0: "20%" }}
                    onChange={(item) =>
                      handleInputChange({
                        target: { name: "comission", value: item },
                      })
                    }
                    tooltip={{
                      open: true,
                      placement: "bottom",
                      formatter: sliderTooltipFormatPerc,
                    }}
                    min={0.1}
                    max={20.0}
                    name="comission"
                    step={0.01}
                  />
                </Form.Item>
              </div>
            )}
            {proprtyType?.name === "Plot" ||
            propertyCategory?.name === "Commercial" ? (
              <div className="input-element"></div>
            ) : (
              <div className="input-element">
                <Form.Item
                  name="total_floor"
                  rules={[
                    {
                      required: true,
                      message: "Please check your input",
                    },
                  ]}
                  initialValue={formData.total_floor}
                >
                  <Input
                    placeholder="Total Floor"
                    label="Total Floor"
                    background="white"
                    name="total_floor"
                    onChange={handleInputChange}
                    defaultValue={formData.total_floor}
                  />
                </Form.Item>
              </div>
            )}
            {formData && formData.comissionType === "FIXED" ? null : (
              <div className="input-element"></div>
            )}
          </div>
          {proprtyType?.name === "Plot" ? (
            <div className="form-row" style={{ marginBottom: 24 }}>
              <div className="input-element" style={{ width: "22%" }}>
                <Form.Item name="east_size">
                  <Input
                    placeholder="East (ft)"
                    label="East Road Size"
                    background="white"
                    name="east_size"
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </div>
              <div className="input-element" style={{ width: "22%" }}>
                <Form.Item name="west_size">
                  <Input
                    placeholder="West (ft)"
                    label="West Road Size"
                    background="white"
                    name="west_size"
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </div>

              <div className="input-element" style={{ width: "22%" }}>
                <Form.Item name="north_size">
                  <Input
                    placeholder="North (ft)"
                    label="North Road Size"
                    background="white"
                    name="north_size"
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </div>
              <div className="input-element" style={{ width: "22%" }}>
                <Form.Item name="south_size">
                  <Input
                    placeholder="South (ft)"
                    label="South Road Size"
                    background="white"
                    name="south_size"
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </div>
            </div>
          ) : null}

          <div className="form-row" style={{ marginBottom: 24 }}>
            <div className="input-element">
              <Form.Item name="size">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="body"
                      weight={400}
                      style={{ marginLeft: 4, marginBottom: 4 }}
                    >
                      Area Size
                      <Select
                        defaultValue="sqft"
                        name="size_unit"
                        style={{
                          width: 80,
                        }}
                        bordered={false}
                        options={LAND_AREA_SIZE_UNITS}
                        onChange={(item) =>
                          handleInputChange({
                            target: {
                              name: "size_unit",
                              value: item,
                            },
                          })
                        }
                      />
                    </Typography>
                  </div>

                  <Input
                    placeholder="(eg: 1200sqft)"
                    background="white"
                    name="size"
                    // onChange={handleInputChange}
                    defaultValue={formData.size}
                    value={formData.size || ""}
                    size="small"
                    height={38}
                    type
                    onChange={(e) =>
                      handleInputChange({
                        target: {
                          name: "size",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </div>

                <Slider
                  value={
                    parseFloat(formData.size) ||
                    (formData.size_unit === "sqft" ? 500 : 1)
                  }
                  marks={
                    formData.size_unit === "sqft"
                      ? { 100: 100, 5000: `5000 ${formData.size_unit}` }
                      : { 1: 1, 500: `500 ${formData.size_unit}` }
                  }
                  onChange={(item) =>
                    handleInputChange({ target: { name: "size", value: item } })
                  }
                  tooltip={{
                    open: true,
                    placement: "bottom",
                    formatter: sliderTooltipFormat,
                  }}
                  min={formData.size_unit === "sqft" ? 500 : 1}
                  max={formData.size_unit === "sqft" ? 5000 : 500}
                  name="slider"
                  step={0.01}
                />
              </Form.Item>
            </div>
            <div className="input-element">
              <Form.Item
                name="address_one"
                rules={[
                  {
                    required: true,
                    message: "Please check your input",
                  },
                ]}
              >
                <Input
                  placeholder="Address"
                  label="Address"
                  background="white"
                  name="address_one"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>
            <div className="input-element">
              <Form.Item
                name="landmark"
                rules={[
                  {
                    required: true,
                    message: "Please check your input",
                  },
                ]}
              >
                <Input
                  placeholder="Landmark"
                  label="Landmark"
                  background="white"
                  name="landmark"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>
          </div>
          <div className="form-row" style={{ marginBottom: 24 }}>
            <div className="input-element">
              <Form.Item name="state">
                <Typography
                  variant="body"
                  weight={400}
                  style={{ marginLeft: 4, marginBottom: 4 }}
                >
                  State
                </Typography>
                {/* <Selector
                  placeholder="State"
                  labelKey="label"
                  valueKey="value"
                  serviceType="STATIC"
                  staticData={STATE_LIST}
                  name="state"
                  onChange={handleInputChange}
                  defaultValue={formData.state}
                /> */}

                <SearchSelector
                  height={50}
                  placeholder="State"
                  staticData={STATE_LIST}
                  serviceType="STATIC"
                  labelKey="label"
                  valueKey="value"
                  onChange={(e) =>
                    handleInputChange({
                      target: { name: "state", value: e },
                    })
                  }
                  defaultValue={formData.state || "Rajasthan"}
                />
              </Form.Item>
            </div>

            <div className="input-element">
              <Form.Item
                name="district_city"
                rules={[
                  {
                    required: true,
                    message: "Please check your input",
                  },
                ]}
                initialValue={formData.district_city}
              >
                <Input
                  placeholder="District/City"
                  label="District/City"
                  background="white"
                  name="district_city"
                  onChange={handleInputChange}
                  defaultValue={formData.district_city}
                />
              </Form.Item>
            </div>
            <div className="input-element">
              <Form.Item
                name="zip"
                rules={[
                  {
                    required: true,
                    message: "Please check your input",
                  },
                ]}
              >
                <Input
                  placeholder="Zipcode"
                  label="Zipcode"
                  background="white"
                  name="zip"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>
          </div>

          <div className="form-row" style={{ marginBottom: 24 }}>
            {proprtyType?.name !== "Plot" ? (
              <div className="input-element">
                <Form.Item name="unit_plan">
                  <Typography
                    variant="body"
                    weight={400}
                    style={{ marginLeft: 4, marginBottom: 4 }}
                  >
                    Unit Plan
                  </Typography>

                  <SimpleFileSelector
                    name="unit_plan"
                    onUpload={handleInputChange}
                    defaultValue={formData.unit_plan}
                  />
                </Form.Item>
              </div>
            ) : null}
            {proprtyType?.name === "Plot" ? (
              <div className="input-element">
                <Form.Item name="plot_plan">
                  <Typography
                    variant="body"
                    weight={400}
                    style={{ marginLeft: 4, marginBottom: 4 }}
                  >
                    Plot Plan
                  </Typography>

                  <SimpleFileSelector
                    name="plot_plan"
                    onUpload={handleInputChange}
                    defaultValue={formData.plot_plan}
                  />
                </Form.Item>
              </div>
            ) : null}
            <div className="input-element">
              <Form.Item name="brochure">
                <Typography
                  variant="body"
                  weight={400}
                  style={{ marginLeft: 4, marginBottom: 4 }}
                >
                  Brochure
                </Typography>

                <SimpleFileSelector
                  name="brochure"
                  onUpload={handleInputChange}
                  defaultValue={formData.brochure}
                />
              </Form.Item>
            </div>
            {proprtyType?.name !== "Plot" ? (
              <div className="input-element">
                <Form.Item name="floor_plan">
                  <Typography
                    variant="body"
                    weight={400}
                    style={{ marginLeft: 4, marginBottom: 4 }}
                  >
                    Floor Plan
                  </Typography>

                  <SimpleFileSelector
                    name="floor_plan"
                    onUpload={handleInputChange}
                    defaultValue={formData.floor_plan}
                  />
                </Form.Item>
              </div>
            ) : (
              <div className="input-element"></div>
            )}
          </div>

          {/* <MapPicker /> */}
          <div className="FormAction">
            <Button
              style={{ width: 360 }}
              // onClick={() => setShowImageDialog(true)}
              htmlType="submit"
              loading={props.editMode ? updateLoading : isLoading}
            >
              Next
            </Button>
          </div>
        </Form>
      </div>
    </ConfigProvider>
  );
}
