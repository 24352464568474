import { Outlet, useNavigate } from "react-router";
import AuthIllustration from "assets/images/illustrations/authIllustration.png";
import AppLogo from "assets/images/appLogo.svg";
import "./styles/auth.scss";
import { useEffect } from "react";

export default function Auth(props) {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("token")) navigate("/dashboard");
  }, []);
  return (
    <div className="auth">
      <div className="container">
        <div className="left-container">
          <div className="forms">
            <div className="appLogo">
              <img src={AppLogo} alt="appLogo" />
            </div>
            <Outlet />
          </div>
        </div>
        <div className="right-container">
          <img src={AuthIllustration} alt="illustrationAuth" />
        </div>
      </div>
    </div>
  );
}
