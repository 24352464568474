import { Form, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  GLOBAL_TIME_FORMAT,
  LEAD_ACTIVITY_TYPES,
} from "app/constants/global.constants";
import Button from "app/shared/Button";
import DateTimePicker from "app/shared/DateTimePicker";
import Input from "app/shared/InputBox";
import Selector from "app/shared/Selector";
import Typography from "app/shared/Typography";
import React, { useEffect, useState } from "react";
import "../../LeadDetails.scss";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import moment from "moment";
import { apiPost, apiPut } from "app/services/apiService";
import { parseURL } from "app/utils/helpers/common.helper";
import {
  API_ENDPOINT_LEAD_ACTIVITY_ADD_ATTACHMENT,
  API_ENDPOINT_LEAD_CREATE_ACTIVITY,
  API_ENDPOINT_LEAD_UPDATE_LEAD_INTRESTS,
} from "app/scenes/leads/leads.constants";
import { useParams } from "react-router";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { useDispatch } from "react-redux";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { colorPicker } from "app/utils/helpers/color.helper";
import TagInput from "app/shared/TagInput";

export default function LeadIterests(props) {
  const [formData, setFormData] = useState({});
  console.log("intrest id:", props.intrestId);
  useEffect(() => {
    if (props.data) handleInitFormData();
  }, [props.data]);

  let { leadId } = useParams();
  const dispatch = useDispatch();

  const handleInitFormData = () => {
    let data = {
      propertyType: props.data?.property_type,
      location: props.data?.location,
      budget: props.data?.budget,
      interested_in: props.data?.interested_in,
      ready_to_move: props.data?.ready_to_move,
      lead_liked_properties: props.data?.lead_liked_properties,
    };
    setFormData(JSON.parse(JSON.stringify(data)));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleUpdateIntrests = async () => {
    const payload = {
      property_type: formData.propertyType,
      location: formData.location,
      budget: formData.budget,
      interested_in: formData.interested_in,
      ready_to_move: formData.ready_to_move,
      lead_liked_properties: formData.lead_liked_properties,
    };
    console.log("payload:", payload);

    const response = await apiPut(
      parseURL(API_ENDPOINT_LEAD_UPDATE_LEAD_INTRESTS, {
        leadId: leadId,
        intrestId: props.intrestId,
      }),
      payload
    );
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onSuccess && props.onSuccess();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  const getLikedProperrty = (data) => {
    let codes = [];
    data.forEach((propertyData) => {
      codes.push(propertyData?.property?.property_code || propertyData);
    });
    return codes;
  };

  return (
    <Form
      fields={[
        {
          name: ["interested_in"],
          value: formData.interested_in,
        },
        {
          name: ["ready_to_move"],
          value: formData.ready_to_move,
        },
        {
          name: ["propertyType"],
          value: formData.propertyType,
        },
        {
          name: ["location"],
          value: formData.location,
        },
        {
          name: ["budget"],
          value: formData.budget,
        },
      ]}
      onFinish={handleUpdateIntrests}
    >
      <Form.Item name="interested_in" required>
        <Selector
          placeholder="Plan Type"
          labelKey="name"
          valueKey="id"
          serviceType="STATIC"
          onChange={handleInputChange}
          name="interested_in"
          height={40}
          fullObject
          staticData={[
            {
              id: "OFF_PLAN",
              name: "Off Plan",
            },
            {
              id: "READY_TO_MOVEIN",
              name: "Ready To Move",
            },
          ]}
          defaultValue={formData.interested_in}
        />
      </Form.Item>

      <div style={{ marginBottom: 24 }}>
        <Typography
          variant="body"
          weight={400}
          style={{ marginLeft: 4, marginBottom: 4 }}
        >
          Properties client liked
        </Typography>
        <TagInput
          defaultValue={getLikedProperrty(formData.lead_liked_properties || [])}
          onChange={(value) =>
            handleInputChange({
              target: { name: "lead_liked_properties", value: value },
            })
          }
        />
      </div>

      <Form.Item
        name="budget"
        // initialValue={formData.saleType}
        required
      >
        <Input
          placeholder="Budget"
          onChange={handleInputChange}
          name="budget"
          prefix={
            <CurrencyRupeeIcon
              style={{ fontSize: 16, color: colorPicker("gray_dark") }}
            />
          }
          defaultValue={formData.budget}
        />
      </Form.Item>
      <Form.Item
        name="location"
        // initialValue={formData.saleType}
        required
      >
        <Input
          placeholder="Preferred Location"
          onChange={handleInputChange}
          name="location"
          defaultValue={formData.location}
        />
      </Form.Item>
      <Form.Item
        name="ready_to_move"
        // initialValue={formData.saleType}
        required
      >
        <Input
          placeholder="Moving In Days"
          onChange={handleInputChange}
          name="ready_to_move"
          defaultValue={formData.ready_to_move}
        />
      </Form.Item>
      <Form.Item
        name="propertyType"
        // initialValue={formData.saleType}
        required
      >
        <Input
          placeholder="Property Type (eg:House/Villa)"
          onChange={handleInputChange}
          name="propertyType"
          defaultValue={formData.propertyType}
        />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit">Update</Button>
      </Form.Item>
    </Form>
  );
}
