import React, { useContext, useEffect, useState } from "react";
import "./PropertyDetails.scss";
import { Gallery } from "react-grid-gallery";
import {
  ENUM_KEY_TITLES,
  GLOBAL_TIME_FORMAT,
  GLOBAL_VIDEO_FORMATS,
} from "app/constants/global.constants";
import { Avatar, Carousel, Image, Modal, Radio, Tag } from "antd";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/helpers/color.helper";
import { FormattedNumber } from "react-intl";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import AspectRatioOutlinedIcon from "@mui/icons-material/AspectRatioOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DummyAvatar from "assets/images/DummyAvatar.png";
import Button from "app/shared/Button";
import PhoneIcon from "@mui/icons-material/Phone";
import MapBox from "app/shared/MapBox";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import EscalatorOutlinedIcon from "@mui/icons-material/EscalatorOutlined";
import FitnessCenterOutlinedIcon from "@mui/icons-material/FitnessCenterOutlined";
import NaturePeopleOutlinedIcon from "@mui/icons-material/NaturePeopleOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import {
  API_ENDPOINT_PROJECT_INFO,
  API_ENDPOINT_PROJECT_PAYMENT_PLAN_INFO,
  API_ENDPOINT_PROPERTY_FETCH_IMAGES,
  API_ENDPOINT_PROPERTY_INFO,
} from "../../property.constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { parseURL } from "app/utils/helpers/common.helper";
import { apiGet } from "app/services/apiService";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Loader from "app/shared/Loader";
import { API_ENDPOINT_USER_INFO } from "app/scenes/brokers/brokers.constants";
import TableView from "app/shared/TableView";
import LocalGroceryStoreRoundedIcon from "@mui/icons-material/LocalGroceryStoreRounded";
import DirectionsBusFilledRoundedIcon from "@mui/icons-material/DirectionsBusFilledRounded";
import TheatersRoundedIcon from "@mui/icons-material/TheatersRounded";
import ParkRoundedIcon from "@mui/icons-material/ParkRounded";
import PhotoGallery from "app/shared/PhotoGallery";
import { ResponsiveHeatMap } from "@nivo/heatmap";
import {
  CalendarOutlined,
  CheckCircleOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { FeatureFlags } from "app/utils/FeatureFlag/FeatrueFlag";

const unitTableColumns = [
  {
    title: "Category",
    index: "property_type.property_category.name",
    key: "property_type.property_category.name",
  },
  {
    title: "Type",
    index: "property_type.name",
    key: "property_type.name",
  },
  {
    title: "BHK",
    render: (data) => {
      console.log("table data:?", data);
      return data?.property_type?.name === "Flat" && !data?.bedroom
        ? "Studio"
        : data?.property_type?.name === "Flat" ||
          data?.property_type?.name === "House/Villa"
        ? data?.bedroom + "BHK"
        : "NA";
    },
  },

  {
    title: "Total Unit",
    render: (data) => {
      return data?.property_units_lists?.length || 1;
    },
  },
];

export default function ProjectDetails(props) {
  const [isLoading, setLoading] = useState(false);
  const [projectInfo, setProjectInfo] = useState(null);
  const [showFullImage, setShowFullImage] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState(0);
  const [showModal, setShowModal] = useState(null);
  let { projectId } = useParams();
  const { hasAccess } = useContext(FeatureFlags);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const processHeatMapData = (floor, units) => {
    return new Array(parseInt(units)).fill(null);
  };

  useEffect(() => {
    if (!projectId) return;
    fetchProjectInfo();
  }, [projectId]);

  const fetchProjectInfo = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_PROJECT_INFO + projectId);
    if (response.status) {
      setProjectInfo(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    }
    setLoading(false);
  };

  const getFileTypeFromUrl = (url) => {
    const fileExtension = url?.split(".").pop().toLowerCase();
    console.log("file extenstion", fileExtension, url);
    if (["jpg", "jpeg", "png", "gif", "bmp"].includes(fileExtension)) {
      return "image";
    } else if (fileExtension === "pdf") {
      return "pdf";
    } else {
      return "unknown";
    }
  };

  const getDistinctInvestors = (data) => {
    let investors = [];
    (data || []).forEach((investor) => {
      let findInvestor = investors.findIndex(
        (item) => item.investorId === investor.investorId
      );
      if (findInvestor !== -1) {
        investors[findInvestor].amount =
          parseInt(investors[findInvestor].amount) + parseInt(investor.amount);
      } else {
        investors.push(investor);
      }
    });
    return investors;
  };
  return isLoading ? (
    <Loader />
  ) : (
    <div className="ProjectDetails">
      <div className="TopActionsContainer">
        <div>
          <ArrowBackIcon
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
        </div>
        <div>
          <Button
            size="small"
            background="black"
            icon={<EditOutlined />}
            onClick={() => navigate("/property/project/edit/" + projectId)}
          >
            Edit Project
          </Button>
        </div>
      </div>
      <div className="DetailColumnOne">
        <div className="PropertyInfoContainer">
          <div className="PropertyImages">
            <div className="CarasoulContainer">
              <Carousel autoplay>
                {(
                  (projectInfo && projectInfo?.property_project_images) ||
                  []
                )?.map((image, key) => {
                  return (
                    <div key={key}>
                      {GLOBAL_VIDEO_FORMATS.includes(
                        image.url?.substring(image.url.lastIndexOf(".") + 1)
                      ) ? (
                        <video
                          id="myVideo"
                          width="100%"
                          height="260"
                          autoplay
                          muted
                          controls="controls"
                        >
                          <source src={image.url} type="video/mp4" />
                        </video>
                      ) : (
                        <img
                          src={image.url}
                          alt="propertyImage"
                          style={{
                            height: 260,
                            width: "100%",
                            objectFit: "cover",
                            borderRadius: 16,
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </Carousel>
            </div>
            <div className="MoreImages" onClick={() => setShowFullImage(true)}>
              <div className="PropertyImageLast">
                <img
                  src={
                    projectInfo &&
                    projectInfo?.property_project_images &&
                    projectInfo &&
                    projectInfo?.property_project_images[2]?.url
                  }
                  alt="propertyImage"
                />
              </div>
              <div className="PropertyImageLast">
                <img
                  src={
                    projectInfo &&
                    projectInfo?.property_project_images &&
                    projectInfo &&
                    projectInfo?.property_project_images[1]?.url
                  }
                  alt="propertyImage"
                />
                <div className="imageCount">
                  <Typography variant="xs">
                    {projectInfo && projectInfo?.property_project_images.length}{" "}
                    photos
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className="BasicPropertyInfo">
            <div className="LeftInformation">
              <Typography variant="h5" style={{ marginTop: 14 }}>
                {projectInfo?.name}
              </Typography>

              <div
                style={{ display: "flex", alignItems: "center", marginTop: 12 }}
              >
                <LocationOnIcon
                  fontSize={"small"}
                  style={{ color: "#000929" }}
                />
                <Typography variant="caption" color="gray-dark">
                  {projectInfo &&
                    projectInfo.property_project_address?.address_one}{" "}
                  {projectInfo &&
                    projectInfo.property_project_address?.address_two}{" "}
                  {projectInfo && projectInfo.property_project_address?.city}{" "}
                  {projectInfo && projectInfo.property_project_address?.state}{" "}
                  {projectInfo && projectInfo.property_project_address?.zip}
                  {/* Near Petrol Pum , Koramangala 24d block, 456044,Bangalore */}
                </Typography>
              </div>
              <div>
                <Typography variant="body2" style={{ marginTop: 14 }}>
                  Starting from
                  <b style={{ fontWeight: 700, color: colorPicker("red") }}>
                    {" "}
                    <FormattedNumber
                      value={
                        (projectInfo && projectInfo.starting_price) || 12999999
                      }
                      style="currency"
                      currency={"INR"}
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                      maximumSignificantDigits={10}
                      minimumSignificantDigits={1}
                      useGrouping
                    />
                  </b>
                </Typography>
                <Tag style={{ marginTop: 6 }} color="blue">
                  {projectInfo && projectInfo?.status}
                </Tag>
                <div style={{ marginTop: 12 }}>
                  Developer -{" "}
                  <Tag color="blue">
                    {projectInfo?.property_developer?.name}
                  </Tag>
                </div>
                <div style={{ marginTop: 12 }}>
                  Community -{" "}
                  <Tag color="blue">
                    {projectInfo?.property_community?.name}
                  </Tag>
                </div>
              </div>
            </div>
            {projectInfo?.status === "BUILDING" && projectInfo?.start_date ? (
              <div className="RightInformation">
                <div className="DateItem">
                  <CalendarOutlined style={{ marginRight: 12 }} />
                  <Typography variant="caption">
                    Started -{" "}
                    {projectInfo?.start_date &&
                      moment(projectInfo?.start_date).format("Do MMM 24")}
                  </Typography>
                </div>

                <div className="DateItem">
                  <CalendarOutlined style={{ marginRight: 12 }} />
                  <Typography variant="caption">
                    End -{" "}
                    {projectInfo?.start_date &&
                      moment(projectInfo?.end_date).format("Do MMM 24")}
                  </Typography>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="MoreInfoContainer">
          <Typography variant="h6">Description</Typography>
          <Typography
            variant="caption"
            color="gray-dark"
            style={{ marginTop: 18 }}
          >
            {projectInfo && projectInfo?.description}
          </Typography>
        </div>

        <div className="MoreInfoContainer">
          <Typography variant="h6">Distance Key Between Facilities</Typography>
          <div className="InfoList" style={{ flexDirection: "row" }}>
            {projectInfo &&
              projectInfo?.property_project_facilites?.map((facility, key) => {
                return (
                  <div className="InfoListItem" key={key}>
                    <CheckCircleOutlined
                      style={{ fontSize: 18, marginRight: 6 }}
                    />
                    <Typography color="gray-dark">
                      {facility.facilite?.name} - {facility?.distance} Km
                    </Typography>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="MoreInfoContainer">
          <Typography variant="h6">Amentities</Typography>
          <div className="InfoList" style={{ flexDirection: "row" }}>
            {projectInfo &&
              projectInfo?.project_amentities?.map((amentity, key) => {
                return (
                  <div className="InfoListItem" key={key}>
                    {/* <CheckCircleOutlined /> */}
                    <CheckCircleOutlined
                      style={{ fontSize: 18, marginRight: 6 }}
                      color={colorPicker("green")}
                    />
                    <Typography color="gray-dark">
                      {amentity.amentity?.name}
                    </Typography>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="MoreInfoContainer">
          <Typography variant="h6">Unit Details</Typography>
          <div className="InfoList" style={{ flexDirection: "row" }}>
            {/* {projectInfo?.properties.map((property, key) => {
              return (
                <div
                  className="InfoListItem"
                  style={{
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    padding: 8,
                    width: "auto",
                    marginRight: 24,
                  }}
                >
                  <Tag style={{ marginLeft: 6 }} color="blue">
                    {property?.property_type?.property_category?.name}
                  </Tag>{" "}
                  <Typography color="gray-dark">
                    {property?.property_type?.name === "Flat"
                      ? property?.property_type?.name +
                        " (" +
                        property?.bedroom +
                        "BHK) "
                      : property?.property_type?.name}
                  </Typography>
                  <Tag style={{ marginLeft: 6 }} color="gray">
                    {property?.property_units_lists?.length}
                  </Tag>
                </div>
              );
            })} */}
            <TableView
              column={[...unitTableColumns]}
              data={projectInfo?.properties || []}
              style={{ width: "100%" }}
              hasAccess={hasAccess}
            />
          </div>
        </div>
        <div className="MoreInfoContainer">
          <Typography variant="h6">Investors</Typography>
          <div className="InvestorList">
            {getDistinctInvestors(projectInfo?.investor_fundings)?.map(
              (investor, key) => {
                return (
                  <div
                    className="InvestorInfo"
                    key={key}
                    onClick={() =>
                      navigate(
                        "/property/investors?selected=" + investor?.investorId
                      )
                    }
                  >
                    <Avatar
                      size={68}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjFUS-3gXVAvCThJ1sPSI4ZWLC1lHCxY53Mg&usqp=CAU"
                    />
                    <div className="InvestorName">
                      <Typography>
                        {investor?.investments?.user_profile.fullname ||
                          "Investor"}
                      </Typography>
                      <Typography variant="caption">
                        <b
                          style={{
                            fontWeight: 700,
                            color: colorPicker("green"),
                            fontSize: 12,
                          }}
                        >
                          {" "}
                          <FormattedNumber
                            value={(investor && investor.amount) || 12999999}
                            style="currency"
                            currency={"INR"}
                            minimumFractionDigits={0}
                            maximumFractionDigits={0}
                          />
                        </b>
                      </Typography>
                      <Tag style={{ marginTop: 4, fontSize: 10 }} color="blue">
                        {" "}
                        {investor?.investments?.user_profile.userType ||
                          "Individual"}
                      </Tag>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
        <div className="MapBoxContainer">
          <MapBox
            defaultValue={{
              latitude:
                (projectInfo &&
                  projectInfo?.property_project_address?.latlong &&
                  projectInfo &&
                  parseFloat(
                    projectInfo?.property_project_address?.latlong.split(",")[0]
                  )) ||
                0,
              longitude:
                (projectInfo &&
                  projectInfo?.property_project_address?.latlong &&
                  projectInfo &&
                  parseFloat(
                    projectInfo?.property_project_address?.latlong.split(",")[1]
                  )) ||
                0,
            }}
          />
        </div>
        <div className="MoreInfoContainer">
          <Typography variant="h6">Gallery</Typography>
          <div style={{ marginTop: 18 }}>
            <PhotoGallery
              images={projectInfo?.property_project_images}
              imageKey={"url"}
            />
          </div>
        </div>

        <div className="MoreInfoContainer">
          <Typography variant="h6">Documents</Typography>
          <div
            className="docCardContainer"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            {projectInfo?.property_project_documents?.map((document, key) => {
              return (
                <div
                  key={key}
                  className="docCard"
                  // onClick={() => window.open(document.url, "_blank")}
                  style={{
                    marginRight: 18,
                    marginBottom: 18,
                    justifyContent: "space-between",
                  }}
                >
                  <Typography color="red" variant="caption">
                    {document.type}
                  </Typography>
                  <div>
                    <EyeOutlined
                      style={{
                        marginRight: 12,
                        color: colorPicker("red"),
                        cursor: "pointer",
                      }}
                      onClick={() => setShowModal(document.url)}
                    />
                    <DownloadOutlined
                      style={{
                        marginRight: 4,
                        color: colorPicker("red"),
                        cursor: "pointer",
                      }}
                      onClick={() => window.open(document.url, "_blank")}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* 
      <Modal
        title="Property Images"
        centered
        open={showFullImage}
        onCancel={() => setShowFullImage(false)}
        width={1000}
        footer={false}
      >
        <div
          className="PropertyImageList"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          {images.map((image, key) => {
            <Image key={key} width={200} src={image.url} />;
          })}

          {images.map((image, key) => {
            return (
              <div key={key} style={{ marginRight: 6, marginBottom: 6 }}>
                {GLOBAL_VIDEO_FORMATS.includes(
                  image.url.substring(image.url.lastIndexOf(".") + 1)
                ) ? (
                  <video
                    id="myVideo"
                    width="100%"
                    height="260"
                    autoplay
                    muted
                    controls="controls"
                  >
                    <source src={image.url} type="video/mp4" />
                  </video>
                ) : (
                  <Image key={key} width={200} src={image.url} />
                )}
              </div>
            );
          })}
        </div>
      </Modal> */}

      <Modal
        title="File Preview"
        visible={showModal !== null}
        onCancel={() => setShowModal(null)}
        footer={false}
      >
        {getFileTypeFromUrl(showModal) === "image" ? (
          <img src={showModal} alt="Image" style={{ width: "100%" }} />
        ) : (
          <embed
            src={showModal}
            type="application/pdf"
            style={{ width: "100%", height: "500px" }}
          />
        )}
      </Modal>
    </div>
  );
}
