import { Form, Select } from "antd";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import Typography from "app/shared/Typography";
import { useState } from "react";
import "../../styles/onboarding.scss";

import { connect, useDispatch } from "react-redux";
import OTPBox from "app/shared/OTPBox";
import { DOCUMENT_TYPE } from "app/constants/global.constants";
import Uploader from "app/shared/Uploader";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { apiPost } from "app/services/apiService";
import { API_ENDPOINT_PROFILE_UPDATE_BANK } from "../../onboard.constants";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { useNavigate } from "react-router";

function OnboardingBank(props) {
  const [bankName, setBankName] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [ifscCode, setIFSC] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const updateBankDetail = async () => {
    const payload = {
      account_no: bankAccount,
      bank_name: bankName,
      ifsc_code: ifscCode,
    };
    const response = await apiPost(API_ENDPOINT_PROFILE_UPDATE_BANK, payload);
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      navigate("/dashboard");
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    console.log("payload:", payload);
  };
  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={updateBankDetail}
      // onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Typography
        style={{ textAlign: "center", marginBottom: 64 }}
        variant="h5"
      >
        Update Bank
      </Typography>

      <div className="input-element" style={{ marginBottom: 24 }}>
        <Form.Item
          name="bank_name"
          rules={[
            {
              required: true,
              message: "Please enter bank name!",
              fontSize: 12,
            },
          ]}
          style={{ fontSize: 12 }}
        >
          <Input
            placeholder="Bank Name"
            label="Bank Name"
            onChange={(e) => setBankName(e.target.value)}
          />
        </Form.Item>
      </div>

      <div className="input-element" style={{ marginBottom: 24 }}>
        <Form.Item
          name="bank_account"
          rules={[
            {
              required: true,
              message: "Please enter account number!",
              fontSize: 12,
            },
          ]}
          style={{ fontSize: 12 }}
        >
          <Input
            placeholder="Account Number"
            label="Account Number"
            onChange={(e) => setBankAccount(e.target.value)}
          />
        </Form.Item>
      </div>

      <div className="input-element" style={{ marginBottom: 24 }}>
        <Form.Item
          name="back_ifscs"
          rules={[
            {
              required: true,
              message: "Please enter IFSC code!",
              fontSize: 12,
            },
          ]}
          style={{ fontSize: 12 }}
        >
          <Input
            placeholder="IFSC Code"
            label="IFSC Code"
            onChange={(e) => setIFSC(e.target.value)}
          />
        </Form.Item>
      </div>

      <div className="action-buttons">
        <Form.Item style={{ width: "100%" }}>
          <Button
            type="primary"
            size="large"
            style={{ width: "100%" }}
            htmlType="submit"
          >
            Update
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, {})(OnboardingBank);
