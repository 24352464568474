import { Slider, Popover } from "antd";
import Button from "app/shared/Button";
import LocationFinder from "app/shared/LocationFinder";
import Selector from "app/shared/Selector";
import { useEffect, useState } from "react";
import Typography from "app/shared/Typography";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import "./PropertyFilter.scss";
import {
  CABIN_MAPS,
  FLOOR_MAP,
  FURNISHING_TYPES,
  PROPERTY_FACING_TYPE,
  SEAT_CAPACITY_MAPS,
} from "app/constants/global.constants";
import { colorPicker } from "app/utils/helpers/color.helper";
import { FormattedNumber } from "react-intl";
import { apiGet } from "app/services/apiService";
import { API_ENDPOINT_PROPERTY_FETCH_LIST_COMMON } from "app/scenes/property/property.constants";
const moreFilterConfig = [
  {
    title: "Floors",
    data: FLOOR_MAP,
    dataKey: "floor",
    type: "ALL",
  },
  {
    title: "Amentities",
    data: [],
    dataKey: "amentities",
    type: "ALL",
  },
  {
    title: "Furnishing",
    data: FURNISHING_TYPES,
    dataKey: "furnishing",
    type: "ALL",
  },
  {
    title: "Facing",
    data: PROPERTY_FACING_TYPE,
    dataKey: "facing",
    type: "ALL",
  },
  {
    title: "Posession Status",
    data: [
      {
        name: "Ready To Move",
        id: "READY_TO_MOVE",
      },
      {
        name: "Under Construction",
        id: "UNDER_CONSTRUCTION",
      },
    ],
    type: "ALL",
    dataKey: "moveStatus",
  },
  {
    title: "Sale Type",
    data: [
      {
        name: "New Sale",
        id: "NEW_PROPERTY",
      },
      {
        name: "Resale",
        id: "RESALE",
      },
    ],
    dataKey: "saleType",
    type: "ALL",
  },
  {
    title: "Seat Capacity",
    data: SEAT_CAPACITY_MAPS,
    dataKey: "seat_capacity",
    type: "OFFICE_TYPE",
    isMinMax: true,
  },
  {
    title: "Cabins",
    data: CABIN_MAPS,
    dataKey: "cabins",
    type: "OFFICE_TYPE",
  },
  {
    title: "Cafeteria",
    data: [
      {
        name: "Dry",
        id: "DRY",
      },
      {
        name: "Wet",
        id: "WET",
      },
    ],
    dataKey: "cafeteria",
    type: "OFFICE_TYPE",
  },
];
const roomData = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
];
export default function PropertyFilters(props) {
  const [filterData, setFilterData] = useState({});
  const [moreFilterCount, setMoreFilterCount] = useState(0);
  const [propertyCategory, setPropertyCategory] = useState();
  const [amentityList, setAmentitiyList] = useState([]);
  const formatter = (value) => `${value}sqft`;
  const priceFormatter = (value) => `${value} Lac`;

  useEffect(() => {
    fetchAmentitiyList();
  }, []);
  const fetchAmentitiyList = async () => {
    const response = await apiGet(
      API_ENDPOINT_PROPERTY_FETCH_LIST_COMMON + "?service=AMENTITIES"
    );
    if (response.status) {
      setAmentitiyList(response.data);
    }
  };

  const sendFilterPayload = () => {
    const payload = {
      location: filterData.location,
      property_type: filterData.property_type,
      bedroom: filterData.bedroom,
      bathroom: filterData.bathroom,
      price: filterData.price,
      size: filterData.size,
      furnishing: filterData.furnishing,
      moveStatus: filterData.moveStatus,
      floor: filterData.floor,
      facing: filterData.facing,
      cafeteria: filterData.cafeteria,
      cabins: filterData.cabins,
      seat_capacity: filterData.seat_capacity,
      saleType: filterData.saleType,
      amentities: filterData.amentities,
    };
    let filterURL = "?";

    let keys = Object.keys(payload);
    keys.forEach((element) => {
      let filterConfig = moreFilterConfig.find(
        (item) => item.dataKey === element
      );

      if (payload[element]) {
        if (
          (filterConfig && filterConfig.isMinMax) ||
          typeof payload[element] === "object"
        ) {
          filterURL += `${element}_min=${payload[element][0]}&${element}_max=${payload[element][1]}&`;
        } else {
          filterURL += `${element}=${payload[element]}&`;
        }
      }
    });

    props.onFilterApply && props.onFilterApply(filterURL);
  };

  useEffect(() => {
    sendFilterPayload();
  }, [filterData]);

  const handleFilterQuery = (data) => {
    const { name, value } = data;

    if (name === "property_type" && value?.target?.obj) {
      setPropertyCategory(value.target.obj.property_category);
      setFilterData((prevFormData) => ({
        ...prevFormData,
        [name]: value.target.value,
      }));
    } else {
      setFilterData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const clearAllFilter = () => {
    setFilterData({});
    setMoreFilterCount(0);
  };
  return (
    <div className="PropertyFilter">
      <div className="filterWidget" style={{ minWidth: 200 }}>
        <LocationFinder
          onChange={(items) =>
            handleFilterQuery({
              name: "location",
              value: items[0]?.label || null,
            })
          }
          rounded
          allowClear
        />
      </div>
      <div className="filterWidget" style={{}}>
        <Selector
          placeholder="Property Type"
          labelKey="name"
          valueKey="id"
          serviceType="PROPERTY_TYPES"
          onChange={(items) =>
            handleFilterQuery({ name: "property_type", value: items })
          }
          name="property_type"
          height={40}
          includeModel="PROPERTY_CATEGORY"
          allowClear
        />
      </div>

      <div className="filterWidget" style={{ minWidth: 100 }}>
        <Selector
          placeholder="BHK"
          labelKey="label"
          valueKey="value"
          serviceType="STATIC"
          staticData={roomData}
          onChange={(items) =>
            handleFilterQuery({ name: "bedroom", value: items })
          }
          height={40}
          allowClear
        />
      </div>

      <div className="filterWidget" style={{ minWidth: 100 }}>
        <Popover
          placement="bottom"
          trigger="click"
          content={
            <div
              style={{
                width: 260,
                padding: "12px 34px",
              }}
            >
              <Slider
                range
                defaultValue={[1000, 10000]}
                tooltip={{ formatter }}
                min={1000}
                max={10000}
                onChangeComplete={(value) =>
                  handleFilterQuery({ name: "size", value: value })
                }
                marks={{ 1000: "1000 sqft", 10000: `10000 sqft` }}
              />
            </div>
          }
        >
          <div className="filterButton">
            <Typography
              variant="caption"
              style={{ color: filterData.size ? "black" : "#d5d1d1" }}
            >
              {filterData.size
                ? `${filterData.size[0]} - ${filterData.size[1]} sqft`
                : "Area"}{" "}
            </Typography>
            {filterData.size ? (
              <CancelIcon
                style={{
                  color: colorPicker("gray_dark"),
                  fontSize: 14,
                  marginLeft: 4,
                }}
                onClick={() => handleFilterQuery({ name: "size", value: null })}
              />
            ) : null}
          </div>
        </Popover>
      </div>

      <div className="filterWidget" style={{ minWidth: 100 }}>
        <Popover
          placement="bottom"
          trigger="click"
          content={
            <div
              style={{
                width: 260,
                padding: "12px 34px",
              }}
            >
              <Slider
                range
                defaultValue={[500000, 20000000]}
                tooltip={{ priceFormatter }}
                min={500000}
                max={20000000}
                onChangeComplete={(value) =>
                  handleFilterQuery({ name: "price", value: value })
                }
                marks={{ 500000: "5 Lac", 20000000: `20Cr` }}
              />
            </div>
          }
        >
          <div className="filterButton">
            <Typography
              variant="caption"
              style={{ color: filterData.price ? "black" : "#d5d1d1" }}
            >
              {filterData.price ? (
                <>
                  <FormattedNumber
                    value={filterData.price[0] || 0}
                    style="currency"
                    currency={"INR"}
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                  {" - "}
                  <FormattedNumber
                    value={filterData.price[1] || 0}
                    style="currency"
                    currency={"INR"}
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                </>
              ) : (
                "Budget"
              )}{" "}
            </Typography>
            {filterData.price ? (
              <CancelIcon
                style={{
                  color: colorPicker("gray_dark"),
                  fontSize: 14,
                  marginLeft: 4,
                }}
                onClick={() =>
                  handleFilterQuery({ name: "price", value: null })
                }
              />
            ) : null}
          </div>
        </Popover>
      </div>
      <div className="filterWidget" style={{ minWidth: 100 }}>
        <Popover
          placement="bottom"
          trigger="click"
          content={
            <div
              style={{
                width: 460,
                padding: "12px",
              }}
            >
              <MoreFilterComponent
                handleFilterQuery={handleFilterQuery}
                queryFilter={filterData}
                addMoreFilter={() => setMoreFilterCount(moreFilterCount + 1)}
                removeMoreFilter={() => setMoreFilterCount(moreFilterCount - 1)}
                propertyCategory={propertyCategory}
                amentityList={amentityList}
              />
            </div>
          }
        >
          <div className="filterButton">
            <div className="buttonTitle">
              {moreFilterCount > 0 ? (
                <div className="filterCount">
                  <Typography variant="xs" style={{ color: "white" }}>
                    {moreFilterCount}
                  </Typography>
                </div>
              ) : null}
              <Typography variant="caption" style={{ color: "#d5d1d1" }}>
                More Filters
              </Typography>{" "}
              <MoreHorizRoundedIcon
                style={{ color: "#d5d1d1", maringLeft: 8 }}
              />
            </div>
          </div>
        </Popover>
      </div>
      {Object.keys(filterData).length > 0 ? (
        <div className="filterWidget">
          <Button size="medium" background="black" onClick={clearAllFilter}>
            Clear All
          </Button>
        </div>
      ) : null}
      {/* <div className="filterWidget" style={{ marginTop: 44 }}>
        <Button onClick={sendFilterPayload}>Apply</Button>
      </div> */}
    </div>
  );
}

const MoreFilterComponent = (props) => {
  return (
    <div className="MoreFilterAddon">
      <div className="FilterContainer">
        {moreFilterConfig.map((category, key) => {
          return category.type === "OFFICE_TYPE" &&
            props.propertyCategory?.name !== "Commercial" ? null : (
            <div className="FilterCategory" kye={key}>
              <Typography variant="h6">{category.title}</Typography>
              <div className="Content">
                {(category.dataKey === "amentities"
                  ? props.amentityList
                  : category.data
                ).map((catData, catKey) => {
                  return (
                    <div
                      className={
                        "Chip " +
                        (JSON.stringify(props.queryFilter[category.dataKey]) ===
                        JSON.stringify(catData.id)
                          ? " --selected"
                          : "")
                      }
                      key={catKey}
                      onClick={() => {
                        if (
                          JSON.stringify(
                            props.queryFilter[category.dataKey]
                          ) !== JSON.stringify(catData.id)
                        ) {
                          props.handleFilterQuery({
                            name: category.dataKey,
                            value: catData.id,
                          });
                          !props.queryFilter[category.dataKey] &&
                            props.addMoreFilter();
                        } else {
                          props.handleFilterQuery({
                            name: category.dataKey,
                            value: null,
                          });
                          props.removeMoreFilter();
                        }
                      }}
                    >
                      {/* <AddOutlinedIcon style={{ fontSize: 18, marginRight: 4 }} /> */}
                      <Typography variant="caption" color="gray-dark">
                        {catData.name}
                      </Typography>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
