import { Form } from "antd";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import Typography from "app/shared/Typography";
import { useState } from "react";
import { MailOutlined } from "@ant-design/icons";

import "../../styles/auth.scss";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { apiPost } from "app/services/apiService";
import { API_ENDPOINT_AUTH_REQUEST_PASSWORD_RESET_LINK } from "../../auth.constants";
import { connect, useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { colorPicker } from "app/utils/helpers/color.helper";

function ForgotPassowrd(props) {
  const [email, setEmail] = useState("");
  const [sceneTab, setSceneTab] = useState(0);
  const [counter, setCounter] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      // Must use callback form here; outer `counter` is in stale closure
      setCounter((counter) => (counter === 0 ? counter : counter - 1));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const requestResetLink = async () => {
    setCounter(60);
    setLoading(true);
    const response = await apiPost(
      API_ENDPOINT_AUTH_REQUEST_PASSWORD_RESET_LINK,
      { email: email }
    );
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      setSceneTab(1);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  return sceneTab === 0 ? (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={requestResetLink}
      autoComplete="off"
    >
      <Typography style={{ textAlign: "center" }} variant="h5">
        Reset Password
      </Typography>
      <div className="input-element" style={{ marginBottom: 24 }}>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please check your email",
            },
          ]}
        >
          <Input
            placeholder="Email"
            type="email"
            label="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
      </div>

      <div className="action-buttons">
        <Form.Item style={{ width: "100%" }}>
          <Button
            type="primary"
            size="large"
            style={{ width: "100%" }}
            htmlType="submit"
            loading={isLoading}
            // onClick={() => setSceneTab(1)}
          >
            Request Reset Link
          </Button>
        </Form.Item>
      </div>
    </Form>
  ) : (
    <div>
      <Typography style={{ textAlign: "center" }} variant="h5">
        <MailOutlined style={{ color: colorPicker("red") }} /> We have sent you
        an reset link to your registered email
      </Typography>
      {email ? (
        <Button
          size="large"
          style={{ width: "100%" }}
          type="link"
          danger
          disabled={counter > 0}
          onClick={requestResetLink}
          loading={isLoading}
        >
          {counter === 0 ? "Send again" : `Send again in ${counter}`}
        </Button>
      ) : (
        <Button
          size="large"
          style={{ width: "100%" }}
          type="link"
          danger
          onClick={() => navigate("/auth/authenticate")}
        >
          Try login to get verification link again
        </Button>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, {})(ForgotPassowrd);
