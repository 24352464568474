import Button from "app/shared/Button";
import Typography from "app/shared/Typography";
import React from "react";

export default function LogSkip(props) {
  return (
    <div
      className="LogSkip"
      style={{
        height: 200,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography varinat="h5">
        Would you like to add any activity before you change lead stage?
      </Typography>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: 24, marginTop: 16, minWidth: 140 }}>
          <Button background="black" onClick={props.onNo && props.onNo}>
            No
          </Button>
        </div>
        <div style={{ marginTop: 16, minWidth: 140 }}>
          <Button onClick={props.onYes && props.onYes}>Yes</Button>
        </div>
      </div>
    </div>
  );
}
