import React, { useMemo, useRef, useState } from "react";
import { Select, Spin, Tag } from "antd";
import debounce from "lodash/debounce";
import { SearchOutlined } from "@ant-design/icons";
import Typography from "app/shared/Typography";
import { useNavigate } from "react-router";
function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
      suffixIcon={<SearchOutlined />}
    />
  );
}

// Usage of DebounceSelect

const getProcessedaData = (data) => {
  let dataMap = [];
  let propertyPushableObj = {
    label: <span>Property</span>,
    title: "property",
    options: [],
  };
  let projectPushableObj = {
    label: <span>Project</span>,
    title: "project",
    options: [],
  };
  let usersPushableObj = {
    label: <span>User</span>,
    title: "user",
    options: [],
  };
  data?.properties.forEach((property) => {
    propertyPushableObj.options.push({
      label: `#PROP-${property.property_unique_code} ${property.name}`,
      value: property.id,
      title: "property",
    });
  });

  data?.projects.forEach((project) => {
    projectPushableObj.options.push({
      label: `#PROJ-${project.project_unique_code} ${project.name}`,
      value: project.id,
      title: "project",
    });
  });

  data?.users.forEach((user) => {
    usersPushableObj.options.push({
      label: `${user.first_name} ${user.last_name}`,
      value: user.id,
      title: "user",
    });
  });
  if (propertyPushableObj.options.length > 0) dataMap.push(propertyPushableObj);
  if (projectPushableObj.options.length > 0) dataMap.push(projectPushableObj);
  if (usersPushableObj.options.length > 0) dataMap.push(usersPushableObj);

  return dataMap;
};
async function fetchUserList(username) {
  console.log("fetching user", username);
  return fetch(
    `${process.env.REACT_APP_API_URL}/global/search?query=` + username,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then((response) => response.json())
    .then((body) => getProcessedaData(body.data));
}
const GlobalSearch = () => {
  const [value, setValue] = useState([]);
  const navigate = useNavigate();
  return (
    <DebounceSelect
      mode="multiple"
      value={value}
      placeholder="Search"
      fetchOptions={fetchUserList}
      onChange={(newValue, fullObj) => {
        if (newValue[0].title === "property")
          navigate("/property/" + newValue[0].value);
        if (newValue[0].title === "project")
          navigate("/property/project/" + newValue[0].value);
        if (newValue[0].title === "user")
          navigate("/brokers/" + newValue[0].value + "/dashboard");

        console.log("new value", newValue, fullObj);
      }}
      style={{
        width: "100%",
        height: 36,
      }}
    />
  );
};
export default GlobalSearch;
