import Typography from "app/shared/Typography";
import PromotorIcon from "assets/images/promote.svg";
import BrokersIcon from "assets/images/brokers.svg";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import "./QuickInsight.scss";
import { colorPicker } from "app/utils/helpers/color.helper";
const imageType = {
  BROKER: BrokersIcon,
  PROMOTER: PromotorIcon,
};
export default function QuickInsight(props) {
  return (
    <div
      className="QuickInsight"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <img src={imageType[props.type]} alt="Icon" style={{ width: 62 }} />
      </div>
      <div className="Stats">
        <Typography variant="h5" style={{ fontWeight: 600 }}>
          32
        </Typography>
        <div className="DetailView">
          <Typography variant="caption" color="gray-dark">
            Total {props.type === "BROKER" ? "Broker" : "Promoter"}
          </Typography>
          <ArrowRightAltIcon style={{ color: colorPicker("gray_dark") }} />
        </div>
      </div>
    </div>
  );
}
