import { DatePicker, Switch } from "antd";
import {
  ACCOUNT_STATUS_MAP_COLOR,
  GLOBAL_TIME_FORMAT,
} from "app/constants/global.constants";
import { apiGet, apiPost } from "app/services/apiService";
import Loader from "app/shared/Loader";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/helpers/color.helper";
import { parseURL } from "app/utils/helpers/common.helper";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  API_ENDPOINT_FETCH_BROKER_COMISSION_GIST,
  API_ENDPOINT_FETCH_BROKER_SALES_INFO,
  API_ENDPOINT_FETCH_BROKER_VISIT_INFO,
  API_ENDPOINT_USER_UPDATE_ACCOUNT_STATUS,
} from "../../brokers.constants";
import HouseInfoCard from "../BrokerDashboardSales/components/HouseInfoCard";
import SaleList from "../BrokerDashboardSales/components/SaleList";
import "./BrokerDashboardVisit.scss";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import dayjs from "dayjs";
export default function BrokerDashboardVisit(props) {
  const [visitInfo, setVisitInfo] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(
    dayjs(moment().format(GLOBAL_TIME_FORMAT), GLOBAL_TIME_FORMAT)
  );

  let { brokerId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    fetchBrokerSalesInfo();
  }, [selectedMonth]);

  const fetchBrokerSalesInfo = async () => {
    // setLoading(true);
    const response = await apiGet(
      parseURL(API_ENDPOINT_FETCH_BROKER_VISIT_INFO, {
        brokerId: brokerId,
      }) +
        "?start=" +
        moment(selectedMonth).format(GLOBAL_TIME_FORMAT) +
        "&end=" +
        moment(selectedMonth).format(GLOBAL_TIME_FORMAT)
    );
    if (response.status) {
      setVisitInfo(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setSelectedMonth(moment(dateString, "YYYY-MM").format(GLOBAL_TIME_FORMAT));
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="BrokerDashboardSales">
      <div className="" style={{ marginBottom: 24 }}>
        <ArrowBackRoundedIcon
          style={{ fontSize: 44, cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
      </div>
      <DatePicker
        defaultValue={selectedMonth}
        onChange={onChange}
        // format="MMM YYYY"
        picker="month"
      />

      <div className="Row">
        <div className="WidgetContainer">
          <HouseInfoCard
            data={visitInfo.residentials}
            type="Residential"
            titleType="VISIT"
          />
        </div>

        <div className="WidgetContainer">
          <HouseInfoCard
            data={visitInfo.commercials}
            type="Commercial"
            titleType="VISIT"
          />
        </div>
      </div>
      <div className="Row">
        <div className="WidgetContainer" style={{ width: "100%" }}>
          <SaleList data={visitInfo} type="VISIT" />
        </div>
      </div>
    </div>
  );
}
