// import ProtectedRoute from "app/utils/ProtectedRoute";
import ProtectedRoute from "app/utils/ProtectedRoute";
import Leads from ".";
import LeadDetails from "./components/LeadDetails";
import LeadList from "./components/LeadList";
//eslint-disable-next-line
export default {
  path: "/leads",
  element: <Leads />,
  children: [
    {
      path: "/leads/list",
      element: (
        <ProtectedRoute accessKey="LEAD_VIEW">
          <LeadList />
        </ProtectedRoute>
      ),
      exact: true,
    },
    {
      path: "/leads/:leadId",
      element: (
        <ProtectedRoute accessKey="LEAD_VIEW">
          <LeadDetails />
        </ProtectedRoute>
      ),
      exact: true,
    },
  ],
};
