/* API ENDPOINTS */
export const API_ENDPOINT_LEADS_LIST = `${process.env.REACT_APP_API_URL}/leads/list`;
export const API_ENDPOINT_LEAD_ACTIVITIES = `${process.env.REACT_APP_API_URL}/leads/{{leadId}}/activities`;
export const API_ENDPOINT_LEAD_FETCH_INFO = `${process.env.REACT_APP_API_URL}/leads/`;
export const API_ENDPOINT_LEAD_CREATE_ACTIVITY = `${process.env.REACT_APP_API_URL}/leads/{{leadId}}/activity`;
export const API_ENDPOINT_LEAD_ACTIVITY_ADD_ATTACHMENT = `${process.env.REACT_APP_API_URL}/leads/{{leadId}}/{{activityId}}/attachment`;
export const API_ENDPOINT_LEAD_UPDATE_DETAILS = `${process.env.REACT_APP_API_URL}/leads/`;
export const API_ENDPOINT_LEAD_UPDATE_LEAD_INTRESTS = `${process.env.REACT_APP_API_URL}/leads/{{leadId}}/intrests/{{intrestId}}`;
export const API_ENDPOINT_LEAD_UPDATE_LEAD_STATUS = `${process.env.REACT_APP_API_URL}/leads/{{leadId}}/status/{{statusId}}`;
export const API_ENDPOINT_USER_SEARCH = `${process.env.REACT_APP_API_URL}/user/search`;
export const API_ENDPOINT_LEAD_UPDATE_LEAD_ASSIGNEE = `${process.env.REACT_APP_API_URL}/leads/{{leadId}}/assignee/{{assigneeId}}`;
export const API_ENDPOINT_LEAD_CREAETE = `${process.env.REACT_APP_API_URL}/leads/create`;
