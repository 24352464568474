// import ProtectedRoute from "app/utils/ProtectedRoute";
import ProtectedRoute from "app/utils/ProtectedRoute";
import Customers from ".";
import CustomerList from "./components/CustomerList";
//eslint-disable-next-line
export default {
  path: "/customer",
  element: <Customers />,
  children: [
    {
      path: "/customer/list",
      element: (
        <ProtectedRoute accessKey="EMPLOYEE_VIEW">
          <CustomerList />
        </ProtectedRoute>
      ),
      exact: true,
    },
  ],
};
