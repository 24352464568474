import { Form, Upload, message } from "antd";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import SearchSelector from "app/shared/SearchSelector";
import "./CreateOffer.scss"
import TextEditor from "app/shared/TextEditor";
import { ANNOUNCEMENT_SEND_TO_MAP } from "app/constants/global.constants";
import Selector from "app/shared/Selector";
import { useState } from "react";
import { apiPost, apiPostForm } from "app/services/apiService";
import { API_ENDPOINT_ANNOUNCEMENT_CREATE, API_ENDPOINT_OFFER_CREATE } from "../../offers.constants";
import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { InboxOutlined } from "@ant-design/icons";
const { Dragger } = Upload;


const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};



export default function CreateOffer(props){
  const [subject,setSubject]=useState("")
  const [sendTo,setSendTo]=useState("")
  const [description,setDescription]=useState("")
  const [expiryTime,setExpiryTime]=useState("")
  const [bannerImg,setBannerImg]=useState(null)
  const [originalImg,setOriginalImg]=useState(null)
  const dispatch = useDispatch()

  const handleCreateOffer=async()=>{
    let formData = new FormData();
    formData.append("file", originalImg);
    formData.append("description", description);
    // formData.append("subject", subject);
    // formData.append("expiry_at", expiryTime);


    const response = await apiPostForm(API_ENDPOINT_OFFER_CREATE,formData);
    if(response.status){
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onCreate && props.onCreate()
    }else{
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  }

  const draggerProps = {
    name: 'file',
    multiple: false,
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
      getBase64(info.file.originFileObj, (url) => {
        setBannerImg(url);
      });
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const handleChange=(file)=>{
    setOriginalImg(file.file?.originFileObj)
    getBase64(file.file.originFileObj, (url) => {
      setBannerImg(url);
    });
  }


    return(
        <div className="MakeAnnouncement">
             <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={handleCreateOffer}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={{marginTop:32}}
      >
          <div className="form-row" style={{ justifyContent: "flex-start",display:'flex',alignItems:'center' ,marginBottom:24}}>
            <div
              className="input-elements"
              style={{ width:'100%' }}
            >
              <Form.Item
                name={"description"}
                rules={[
                  {
                    required: true,
                    message: "Please check your input",
                  },
                ]}
                // initialValue={planName}
                style={{ marginBottom: 0 }}
              >
                <Input
                  placeholder={"Offer Description"}
                  name={"Offer Description"}
                  onChange={(e) => setDescription(e.target.value)}
                //   defaultValue={}
                />
              </Form.Item>
            </div>

          </div>

                <div className="form-row">
                    <div style={{width:'100%'}} >
            
                    <Dragger {...draggerProps}       onChange={handleChange}
>
                      {!bannerImg?
                      <>
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">Click or drag file to add offer banner/image</p>
    <p className="ant-upload-hint">
      Support for a single or bulk upload. Strictly prohibited from uploading company data or other
      banned files.
    </p></>: <img
            src={bannerImg}
            alt="avatar"
            style={{
              width: "100%",
              height:200,
              objectFit:'contain'
            }}
          />}
  </Dragger>
                    </div>

                </div>


        <div className="action-buttons">
          <Form.Item style={{ width: 240 }}>
            <Button
              type="primary"
              size="large"
              style={{ width: "100%" }}
              htmlType="submit"
            //   loading={isLoading}
            >
              Create
            </Button>
          </Form.Item>
        </div>
      </Form>
        </div>
    )
}