// import ProtectedRoute from "app/utils/ProtectedRoute";
import ProtectedRoute from "app/utils/ProtectedRoute";
import { Onboarding } from ".";
import OnboardingBank from "./components/OnboardingBank";
import OnboardingDocuments from "./components/OnboardingDocuments";
import OnboardingProfile from "./components/OnboardingProfile";
//eslint-disable-next-line
export default {
  path: "/onboard/",
  element: <Onboarding />,
  strict: true,
  children: [
    {
      path: "/onboard/profile",
      element: (
        <ProtectedRoute>
          <OnboardingProfile />
        </ProtectedRoute>
      ),
      exact: true,
    },
    {
      path: "/onboard/profile_document",
      element: (
        <ProtectedRoute>
          <OnboardingDocuments />
        </ProtectedRoute>
      ),
      exact: true,
    },

    {
      path: "/onboard/profile_bank",
      element: (
        <ProtectedRoute>
          <OnboardingBank />
        </ProtectedRoute>
      ),
      exact: true,
    },
  ],
};
