import Typography from "app/shared/Typography";
import PromotorIcon from "assets/images/promote.svg";
import HomeIcon from "assets/images/home.svg";
import ComissionIcon from "assets/images/comission.svg";
import VisitIcon from "assets/images/visit.svg";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import "./ReferralDetail.scss";
import DummyAvatar from "assets/images/DummyAvatar.png";
import { Image, Tag } from "antd";
import Loader from "app/shared/Loader";
import moment from "moment";
const imageType = {
  HOME: HomeIcon,
  VISIT: VisitIcon,
  COMISSION: ComissionIcon,
};
export default function ReferralDetail(props) {
  return props.isLoading ? (
    <Loader />
  ) : (
    <div className="ReferralDetail">
      <div className="TopHeader">
        <Typography variant="h5">Referral Details</Typography>
      </div>
      <div className="DetailInfo">
        <Image
          width={78}
          style={{ borderRadius: 12 }}
          src={
            (props.data && props.data.profileInfo?.user_profile?.avatar) ||
            DummyAvatar
          }
        />
        <div className="ReferalInfo">
          <div className="nameInfo">
            <Typography
              style={{ width: "35%" }}
              variant="caption"
              color="gray-dark"
            >
              Code :
            </Typography>
            <Typography variant="caption" color="gray-dark">
              <Tag color="blue">
                {props.data && props.data.profileInfo?.user_profile?.member_id}{" "}
              </Tag>
            </Typography>
          </div>

          <div className="nameInfo">
            <Typography
              style={{ width: "35%" }}
              variant="caption"
              color="gray-dark"
            >
              Name :
            </Typography>
            <Typography variant="caption" color="gray-dark">
              {props.data && props.data.profileInfo?.user_profile?.first_name}{" "}
              {props.data && props.data.profileInfo?.user_profile?.last_name}{" "}
            </Typography>
          </div>
          <div className="nameInfo">
            <Typography
              style={{ width: "35%" }}
              variant="caption"
              color="gray-dark"
            >
              Email :
            </Typography>
            <Typography variant="caption" color="gray-dark">
              {props.data && props.data.profileInfo?.email}
            </Typography>
          </div>
          <div className="nameInfo">
            <Typography
              style={{ width: "35%" }}
              variant="caption"
              color="gray-dark"
            >
              Phone :
            </Typography>
            <Typography variant="caption" color="gray-dark">
              {props.data && props.data.profileInfo?.user_profile?.mobile}
            </Typography>
          </div>
          <div className="nameInfo">
            <Typography
              style={{ width: "35%" }}
              variant="caption"
              color="gray-dark"
            >
              Joining Date :
            </Typography>
            <Typography variant="caption" color="gray-dark">
              {(props.data &&
                props.data.profileInfo?.createdAt &&
                moment(props.data.profileInfo?.createdAt).format(
                  "DD/MM/YYYY"
                )) ||
                "NA"}{" "}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
