import {
  CheckCircleOutlined,
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  HomeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Checkbox,
  Drawer,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Select,
  Switch,
  Tabs,
  Tag,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  ACCOUNT_STATUS,
  PROPERTY_TYPES_BHK_FILTERS,
  PROPRERTY_STATUS_FILTER_MAP,
} from "app/constants/global.constants";
import BrokerDetails from "app/scenes/brokers/components/BorkerDetails";
import InviteBroker from "app/scenes/brokers/components/BrokerList/InviteBroker";
import { apiDelete, apiGet, apiPost, apiPut } from "app/services/apiService";
import Button from "app/shared/Button";
import CreateInternalUser from "app/shared/InternalUserCreate";
import TabBar from "app/shared/TabBar";
import TableView from "app/shared/TableView";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { FeatureFlags } from "app/utils/FeatureFlag/FeatrueFlag";
import { parseURL } from "app/utils/helpers/common.helper";

import { useContext, useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {
  API_ENDPOINT_PROJECT_DELETE,
  API_ENDPOINT_PROJECT_INVESTORS_LIST,
  API_ENDPOINT_PROJECT_LIST,
  API_ENDPOINT_PROPERTY_MANAGE_LIST,
  API_ENDPOINT_PROPERTY_UPDATE_STATUS,
  API_ENDPOINT_PROPERTY_UPDATE_UNIT_STATUS,
} from "../../property.constants";
import InvestorDetail from "./components/InvestorDetail";

import "./ProjectList.scss";
import Loader from "app/shared/Loader";

const PROPERTY_STATUS_MAP = [
  {
    value: "APPROVED",
    label: "Approved",
  },
  {
    value: "AVAILABLE",
    label: "Available",
  },
  {
    value: "DISABLED",
    label: "Disabeld",
  },
  {
    value: "UNDER_REVIEW",
    label: "Under Review",
  },
  {
    value: "Booked",
    label: "BOOKED",
  },
  {
    value: "SOLD",
    label: "Sold",
  },
];
const getDistinctInvestors = (data) => {
  let investors = [];
  data.forEach((investor) => {
    let findInvestor = investors.findIndex(
      (item) => item.investorId === investor.investorId
    );
    if (findInvestor !== -1) {
      investors[findInvestor].amount =
        parseInt(investors[findInvestor].amount) + parseInt(investor.amount);
    } else {
      investors.push(investor);
    }
  });
  return investors;
};
const renderInvestorChip = (data) => {
  let chips = [];
  getDistinctInvestors(data).forEach((element) => {
    chips.push(
      <Tag color="blue">{element?.investments?.user_profile?.fullname}</Tag>
    );
  });

  return chips;
};

const tableColumn = [
  {
    title: "Property ID",
    index: "property_code",
    renderTag: true,
    search: true,
  },
  {
    title: "Name",
    index: "name",
    ellipsis: true,
    search: true,
  },
  {
    title: "Type",
    index: "property_type.name",
    renderTag: true,
  },
  {
    title: "BHK",
    render: (data) => {
      console.log("table data:?", data);
      return data?.property_type?.name === "Flat" && !data?.bedroom
        ? "Studio"
        : data?.property_type?.name === "Flat" ||
          data?.property_type?.name === "House/Villa"
        ? data?.bedroom + "BHK"
        : "NA";
    },
    filters: PROPERTY_TYPES_BHK_FILTERS,
    onFilter: (value, record) =>
      value >= 6 ? record.bedroom >= value : record.bedroom === value,
  },
  {
    title: "Total Units",
    render: (data) => {
      if (
        data?.property_units_lists &&
        typeof data?.property_units_lists === "array"
      ) {
        return data?.property_units_lists.length;
      } else {
        return <Tag color="blue">{data?.unitNo || "NA"}</Tag>;
      }
    },
  },
  {
    title: "Project",
    index: "property_project.name",
    renderTag: true,
    search: true,
  },
  {
    title: "City",
    index: "property_project.property_project_address.city",
    ellipsis: true,
    search: true,
  },
  {
    title: "Location",
    index: "property_project.property_project_address.address_one",
    ellipsis: true,
    search: true,
  },
  {
    title: "Cost",
    index: "cost",
    amount: true,
  },

  // {
  //   title: "Created By",
  //   index: "user.user_profile.fullname",
  // },
];

const statusColumn = {
  title: "Status",
  index: "property_project.name",
  filters: PROPERTY_STATUS_MAP,
  onFilter: (value, record) => record.status.indexOf(value) === 0,

  handleAction: (propsData) => propsData.handleAction(propsData),
  customRender: (props) => {
    console.log("Data", props.status);
    return (
      <Select
        defaultValue={props.status}
        style={{
          width: 120,
          height: 26,
        }}
        onChange={(data) =>
          props.handleAction({
            newStatus: data,
            eventType: "UPDATE_STATUS",
            ...props,
          })
        }
        options={PROPRERTY_STATUS_FILTER_MAP}
      />
    );
  },
};

const actionColumnObj = {
  title: "Action",
  renderAction: true,
  handleAction: (propsData) => propsData.handleAction(propsData),
  actions: [
    {
      event: "view_detail",
      icon: <SearchOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#69A2FD",
    },

    {
      event: "edit_detail",
      icon: <EditOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#f1c40f",
    },
  ],
};

const actionColumnObj2 = {
  title: "Action",
  renderAction: true,
  handleAction: (propsData) => propsData.handleAction(propsData),
  actions: [
    {
      event: "UPDATE_STATUS",
      icon: <CheckCircleOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#73AF00",
    },

    {
      event: "view_detail",
      icon: <SearchOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#69A2FD",
    },

    {
      event: "edit_detail",
      icon: <EditOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#f1c40f",
    },
  ],
};

const LIST_TYPE_MAP = {
  1: "ALL",
  2: "APPROVED",
  3: "PENDING",
  4: "REJECTED",
  5: "INVITED",
};

const manageListTypeMap = {
  1: "AVAILABLE",
  2: "BOOKED",
  3: "SOLD",
  4: "APPROVED",
  5: "UNDER_REVIEW",
  6: "ALL",
  7: "HOLD",
};
export default function PropertyManage(props) {
  const [properties, setProperties] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [statusUnitNo, setStatusUnitNo] = useState("");
  const [uniHoldDay, setUnitHoldDay] = useState("");
  const [validateUnit, setValidateUnit] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hasAccess } = useContext(FeatureFlags);
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    fetchPropertyList();
  }, [activeTab]);
  const fetchPropertyList = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_PROPERTY_MANAGE_LIST + manageListTypeMap[activeTab]
    );
    if (response.status) {
      setProperties(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  const handleShowDrawer = (type) => {
    // setShowDrawerFor(type);
    setShowDrawer(true);
  };
  const handleBulkUpdate = async () => {
    console.log("selectedRows", selectedRowKeys);

    try {
      const requests = [];

      for (let i = 0; i < selectedRowKeys.length; i++) {
        // Push each API call promise into the requests array
        requests.push(
          apiPut(
            parseURL(API_ENDPOINT_PROPERTY_UPDATE_STATUS, {
              propertyId: selectedRowKeys[i],
              statusID: activeTab === "5" ? "APPROVED" : "AVAILABLE",
            })
          )
        );
      }
      console.log("resultquee", requests);
      // Execute all API calls concurrently using Promise.all
      const results = await Promise.all(requests);
      fetchPropertyList();
      setSelectedRowKeys([]);
      setSelectedRows([]);
      // Update state with fetched data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleTableAction = (eData) => {
    let event = eData.id ? eData : eData.rowData;
    switch (eData.eventType) {
      case "edit_detail":
        navigate(
          "/property/project/unit/edit/" +
            event.propertyProjectId +
            "/" +
            event.id
        );
        return;
      case "view_detail":
        navigate("/property/" + event.id);
        return;
      case "UPDATE_STATUS":
        modal.confirm({
          title: "Confirm",
          icon: <ExclamationCircleOutlined />,
          content: `Are you sure want to ${
            activeTab === "5" ? "approve" : "mark available"
          } this property`,
          okText: "Yes",
          cancelText: "No",
          onOk: () =>
            handleUpdatePropertyStatus(event.id, event.newStatus, event.status),
        });
      default:
        return null;
    }
  };

  const handleDeleteProject = async (projectId) => {
    const response = await apiDelete(
      parseURL(API_ENDPOINT_PROJECT_DELETE + projectId)
    );
    if (response.status) {
      fetchPropertyList();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    }
  };

  const handleUpdatePropertyStatus = async (
    propertyId,
    status,
    oldStatus,
    pickUnit = false
  ) => {
    let findIndexForOld = PROPERTY_STATUS_MAP.findIndex(
      (item) => item.value === oldStatus
    );
    let findIndexNew = PROPERTY_STATUS_MAP.findIndex(
      (item) => item.value === status
    );

    const response = await apiPut(
      parseURL(API_ENDPOINT_PROPERTY_UPDATE_STATUS, {
        propertyId: propertyId,
        statusID: status || (activeTab === "5" ? "APPROVED" : "AVAILABLE"),
      })
    );
    if (response.status) {
      fetchPropertyList();
      setStatusUnitNo("");
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: {
          message: "Looks like no unit is available ",
          type: 0,
        },
      });
    }
  };

  const handleCheckboxChange = (e, record) => {
    const dataId = record.id;
    const { checked } = e.target;
    console.log("event is:", dataId);

    const newSelectedRowKeys = checked
      ? [...selectedRowKeys, dataId]
      : selectedRowKeys.filter((rowKey) => rowKey !== dataId);

    const newSelectedRows = checked
      ? [...selectedRows, record]
      : selectedRows.filter((row) => row.id !== dataId);

    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);
  };
  const selectColumn = {
    title: "All",
    render: (data) => (
      <Checkbox
        onChange={(e) => handleCheckboxChange(e, data)}
        checked={selectedRowKeys.includes(data.id)}
      />
    ),
  };
  const items = [
    {
      key: "1",
      label: "Available",
      children: isLoading ? (
        <Loader />
      ) : (
        <TableView
          data={properties}
          isLoading={isLoading}
          column={[...tableColumn, actionColumnObj]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      ),
    },
    {
      key: "2",
      label: "Booked",
      children: isLoading ? (
        <Loader />
      ) : (
        <TableView
          data={properties}
          isLoading={isLoading}
          column={[...tableColumn, actionColumnObj2]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      ),
    },
    {
      key: "3",
      label: "Sold",
      children: isLoading ? (
        <Loader />
      ) : (
        <TableView
          data={properties}
          isLoading={isLoading}
          column={[...tableColumn]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      ),
    },
    {
      key: "7",
      label: "On Hold",
      children: isLoading ? (
        <Loader />
      ) : (
        <TableView
          data={properties}
          isLoading={isLoading}
          column={[...tableColumn]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      ),
    },
    {
      key: "4",
      label: "Approved",
      children: isLoading ? (
        <Loader />
      ) : (
        <TableView
          data={properties}
          isLoading={isLoading}
          column={[selectColumn, ...tableColumn, actionColumnObj2]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      ),
    },
    {
      key: "5",
      label: "Pending for Approval",
      children: isLoading ? (
        <Loader />
      ) : (
        <TableView
          data={properties}
          isLoading={isLoading}
          column={[selectColumn, ...tableColumn, actionColumnObj2]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      ),
    },
    {
      key: "6",
      label: "All",
      children: isLoading ? (
        <Loader />
      ) : (
        <TableView
          data={properties}
          isLoading={isLoading}
          column={[...tableColumn, statusColumn, actionColumnObj]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      ),
    },
  ];

  return (
    <div className="PromoterList">
      <div className="TopSelectors">
        <div className="leftSelectors"></div>
        <div className="rightSelectors">
          {/* {hasAccess("PROPERTY_LISTING_MANAGE") ? (
            <div className="WidgetContainer" style={{ minWidth: 0 }}>
              <Button onClick={() => navigate("/property/create")}>
                Create Property
              </Button>
            </div>
          ) : null} */}
        </div>
      </div>
      <div id="tabBarContainer">
        {selectedRowKeys.length > 0 ? (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{ width: 200 }}
              background="black"
              onClick={handleBulkUpdate}
            >
              Bulk {activeTab === "5" ? "Approve" : "Mark Available"}
            </Button>
          </div>
        ) : null}
        <TabBar
          items={items}
          color="red"
          onChange={(tab) => setActiveTab(tab)}
          inActiveColor="gray_dark"
          activeTab={activeTab}
        />
      </div>
      {contextHolder}
    </div>
  );
}
