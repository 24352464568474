export async function apiPost(url = "", data = {}) {
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify(data),
  });

  return await response.json();
}

export async function apiDelete(url = "", data = {}) {
  const response = await fetch(url, {
    method: "DELETE",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify(data),
  });

  return await response.json();
}

export async function apiPut(url = "", data = {}) {
  const response = await fetch(url, {
    method: "PUT",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify(data),
  });

  return await response.json();
}

export async function apiPostForm(url = "", data = {}) {
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: data,
  });

  return await response.json();
}

export async function apiPatch(url = "", data = {}) {
  const response = await fetch(url, {
    method: "PATCH",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify(data),
  });

  return await response.json();
}

export async function apiGet(url = "") {
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  }).then((res) => res.json());
  return await response;
}

export async function apiGetDownload(url = "", filename) {
  await fetch(url).then(function (response) {
    response.blob().then(function (myBlob) {
      const objectURL = URL.createObjectURL(myBlob);
      const link = document.createElement("a");
      link.href = objectURL;
      link.setAttribute("download", filename + ".pdf");
      document.body.appendChild(link);
      link.click();
    });
  });
}
