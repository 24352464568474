import { DatePicker, Switch } from "antd";
import {
  ACCOUNT_STATUS_MAP_COLOR,
  GLOBAL_TIME_FORMAT,
} from "app/constants/global.constants";
import { apiGet, apiPost } from "app/services/apiService";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/helpers/color.helper";
import { parseURL } from "app/utils/helpers/common.helper";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
  API_ENDPOINT_FETCH_BROKER_DASHBOARD_GIST,
  API_ENDPOINT_USER_INFO,
  API_ENDPOINT_USER_UPDATE_ACCOUNT_STATUS,
} from "../../brokers.constants";
import "./brokerDashboard.scss";
import BrokerActivity from "./components/BrokerActivity";
import BrokerDashCard from "./components/BrokerDashCard";
import BrokerProfile from "./components/BrokerProfile";
import ReferralDetail from "./components/ReferralDetail";
import ReferralList from "./components/ReferralList";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import dayjs from "dayjs";
import { SettingsApplications } from "@mui/icons-material";
export default function BrokerDashboard(props) {
  const [brokerInfo, setBrokerInfo] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [refererInfo, setRefererInfo] = useState(null);
  const [dashboardGist, setDashboardGist] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(
    dayjs(moment().format(GLOBAL_TIME_FORMAT), GLOBAL_TIME_FORMAT)
  );

  let { brokerId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    fetchBrokerInfo();
    fetchDashboardGist();
  }, [selectedMonth]);
  const fetchBrokerInfo = async () => {
    const response = await apiGet(API_ENDPOINT_USER_INFO + brokerId);
    if (response.status) {
      setBrokerInfo(response.data);
      if (response.data.profileInfo.refer_by) {
        getRefererInfo(response.data.profileInfo.refer_by);
      } else {
        setRefererInfo(null);
      }
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setSelectedMonth(moment(dateString, "YYYY-MM").format(GLOBAL_TIME_FORMAT));
  };

  const fetchDashboardGist = async () => {
    setLoading(true);
    const response = await apiGet(
      parseURL(API_ENDPOINT_FETCH_BROKER_DASHBOARD_GIST, {
        brokerId: brokerId,
      }) +
        "?start=" +
        moment(selectedMonth).format(GLOBAL_TIME_FORMAT) +
        "&end=" +
        moment(selectedMonth).format(GLOBAL_TIME_FORMAT)
    );
    if (response.status) {
      setDashboardGist(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  const getRefererInfo = async (userId) => {
    const response = await apiGet(API_ENDPOINT_USER_INFO + userId);
    if (response.status) {
      setRefererInfo(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  const updateProfileStatus = async () => {
    const response = await apiPost(
      parseURL(API_ENDPOINT_USER_UPDATE_ACCOUNT_STATUS, {
        userId: brokerId,
        status:
          brokerInfo.profileInfo?.status === "ACTIVE" ? "DISABLED" : "ACTIVE",
      })
    );
    if (response.status) {
      fetchBrokerInfo();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    }
  };

  return (
    <div className="BrokerDashboard">
      <div className="TopHeader">
        <div className="WidgetTop">
          <Typography>Status</Typography>
          <div
            className="StatusChip"
            style={{
              backgroundColor:
                colorPicker(
                  ACCOUNT_STATUS_MAP_COLOR[
                    brokerInfo && brokerInfo.profileInfo?.status
                  ]
                ) + "26",
            }}
          >
            <Typography
              style={{
                color: colorPicker(
                  ACCOUNT_STATUS_MAP_COLOR[
                    brokerInfo && brokerInfo.profileInfo?.status
                  ]
                ),
              }}
            >
              {brokerInfo && brokerInfo.profileInfo?.status}
            </Typography>
          </div>
        </div>
        <div className="WidgetTop">
          <Typography style={{ marginRight: 8 }}>
            Account (Enable/Disable)
          </Typography>
          <Switch
            checked={brokerInfo && brokerInfo.profileInfo?.status === "ACTIVE"}
            onChange={updateProfileStatus}
          />
        </div>
        <div className="RightWidget">
          <DatePicker
            defaultValue={selectedMonth}
            onChange={onChange}
            // format="YYYY/MM"
            picker="month"
          />
        </div>
      </div>
      <div className="Row">
        <div className="WidgetContainer">
          <BrokerDashCard
            type="HOME"
            data={dashboardGist?.property_sales}
            isLoading={isLoading}
            redirect={"/brokers/" + brokerId + "/sales"}
          />
        </div>

        <div className="WidgetContainer">
          <BrokerDashCard
            type="COMISSION"
            data={dashboardGist?.comission_earning}
            isLoading={isLoading}
            dataType={"AMOUNT"}
            redirect={"/brokers/" + brokerId + "/comission"}
          />
        </div>
        <div className="WidgetContainer">
          <BrokerDashCard
            type="VISIT"
            data={dashboardGist?.property_visits}
            isLoading={isLoading}
            redirect={"/brokers/" + brokerId + "/visit"}
          />
        </div>
      </div>
      <div className="Row">
        <div className="WidgetContainer" style={{ width: "35%" }}>
          <ReferralDetail data={refererInfo} isLoading={isLoading} />
        </div>

        <div className="WidgetContainer" style={{ width: "63%" }}>
          <ReferralList brokerId={brokerId} />
        </div>
      </div>
      <div className="Row">
        <div className="WidgetContainer" style={{ width: "100%", height: 260 }}>
          <BrokerActivity brokerId={brokerId} />
        </div>
      </div>
      <div className="Row">
        <div className="WidgetContainer" style={{ width: "100%" }}>
          <BrokerProfile data={brokerInfo} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
}
