import Typography from "app/shared/Typography";
import LastPayment from "./components/LastPayment";
import QuickInsight from "./components/QuickInsight";
import SalesAndRent from "./components/SaleAndRent";
import SaleOverview from "./components/SaleOverview";
import TopPerformers from "./components/TopPerformers";
import "./dashboard.scss";
import LeadOverview from "./components/LeadOverview";
import { connect } from "react-redux";
import { Progress } from "antd";
import { colorPicker } from "app/utils/helpers/color.helper";
import { useState } from "react";
import { apiGet } from "app/services/apiService";
import { API_ENDPOINT_DASHBOARD_FETCH_PROPERTY_GIST } from "./dashboard.constants";
import { useEffect } from "react";

function Dashboard(props) {
  const [propertyGist, setPropertyGist] = useState(null);

  useEffect(() => {
    fetchPropertyGist();
  }, []);
  const fetchPropertyGist = async () => {
    const response = await apiGet(API_ENDPOINT_DASHBOARD_FETCH_PROPERTY_GIST);
    if (response.status) {
      setPropertyGist(response.data);
    }
  };
  return (
    <div className="Dashboard">
      <div className="greeting">
        <Typography variant="h5">
          Hi, {props.userInfo && props.userInfo?.user_profile?.first_name} 👋
        </Typography>
        <Typography variant="caption" color="gray-dark">
          Welcome to Admin Panel
        </Typography>
      </div>
      <div className="TopContainer">
        <div className="WidgetCard">
          <div className="left">
            <Typography variant="h4" weight={600}>
              {propertyGist?.sold}
            </Typography>
            <Typography>Unit has been sold</Typography>
          </div>
          <div className="right">
            <Progress
              strokeLinecap="butt"
              type="circle"
              percent={(propertyGist?.sold / propertyGist?.total) * 100}
              strokeWidth={18}
              showInfo={false}
              size={68}
              strokeColor={colorPicker("green")}
            />
          </div>
        </div>

        <div className="WidgetCard">
          <div className="left">
            <Typography variant="h4" weight={600}>
              {propertyGist?.booked}
            </Typography>
            <Typography>Unit has booked</Typography>
          </div>
          <div className="right">
            <Progress
              strokeLinecap="butt"
              type="circle"
              percent={(propertyGist?.booked / propertyGist?.total) * 100}
              strokeWidth={18}
              showInfo={false}
              size={68}
              strokeColor={colorPicker("yellow")}
            />
          </div>
        </div>

        <div className="WidgetCard">
          <div className="left">
            <Typography variant="h4" weight={600}>
              {propertyGist?.hold}
            </Typography>
            <Typography>Unit on hold</Typography>
          </div>
          <div className="right">
            <Progress
              strokeLinecap="butt"
              type="circle"
              percent={(propertyGist?.hold / propertyGist?.total) * 100}
              strokeWidth={18}
              showInfo={false}
              size={68}
            />
          </div>
        </div>

        <div className="WidgetCard">
          <div className="left">
            <Typography variant="h4" weight={600}>
              {propertyGist?.to_sell}
            </Typography>
            <Typography>Unit Available</Typography>
          </div>
          <div className="right">
            <Progress
              strokeLinecap="butt"
              type="circle"
              percent={(propertyGist?.to_sell / propertyGist?.total) * 100}
              strokeWidth={18}
              showInfo={false}
              size={68}
              strokeColor={colorPicker("red")}
            />
          </div>
        </div>
        <div className="WidgetCard">
          <div className="left">
            <Typography variant="h4" weight={600}>
              {propertyGist?.total}
            </Typography>
            <Typography>Total Unit</Typography>
          </div>
          <div className="right">
            {/* <Progress
              strokeLinecap="butt"
              type="circle"
              percent={(propertyGist?.rejected / propertyGist?.total) * 100}
              strokeWidth={18}
              showInfo={false}
              size={68}
              strokeColor={colorPicker("red")}
            /> */}
          </div>
        </div>
      </div>
      <div className="WidgetContainer">
        <div className="WidgetColumn" style={{ width: "70%" }}>
          <div className="widgetCard" style={{ width: "35%" }}>
            <LastPayment />
          </div>{" "}
          <div className="widgetCard" style={{ width: "35%" }}>
            <LeadOverview />
          </div>
          <div className="widgetCard" style={{ width: "20%" }}>
            <QuickInsight type="PROMOTER" />
          </div>
          <div className="widgetCard" style={{ width: "100%" }}>
            <SaleOverview />
          </div>
        </div>
        <div className="WidgetColumn" style={{ width: "30%" }}>
          <div className="widgetCard" style={{ width: "100%", height: 300 }}>
            <SalesAndRent />
          </div>
          <div
            className="widgetCard"
            style={{ width: "100%", height: 300, alignItems: "flex-start" }}
          >
            <TopPerformers />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, {})(Dashboard);
