import {
  DeleteOutlined,
  EditFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { Drawer, Modal, Popconfirm, Switch, Tabs, Tag } from "antd";
import { ACCOUNT_STATUS } from "app/constants/global.constants";
import { apiDelete, apiGet} from "app/services/apiService";
import Button from "app/shared/Button";
import TableView from "app/shared/TableView";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { FeatureFlags } from "app/utils/FeatureFlag/FeatrueFlag";
import { parseURL } from "app/utils/helpers/common.helper";

import { useContext, useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";


import "./AnnouncementList.scss";
import { API_ENDPOINT_ANNOUNCEMENT_LIST } from "../../announcement.constants";
import MakeAnnouncement from "../MakeAnnouncement";
import Typography from "app/shared/Typography";
import moment from "moment";

const tableColumn = [
  {
    title: "Status",
    index: "status",
    renderTag:true
  },
  {
    title: "Subject",
    index: "subject",
    search: true,
  },
  {
    title: "Description",
    index: "description",
    ellipsis:true
  },
  {
    title: "Sender",
    index: "user.user_profile.first_name",
  },
  {
    title: "Shared with",
    index: "send_to",
    renderTag:true
  },
  {
    title: "Shared On",
    index: "updatedAt",
    date:true
  },

];

const actionColumnObj = {
  title: "Action",
  renderAction: true,
  handleAction: (propsData) => propsData.handleAction(propsData),
  actions: [
    {
      event: "view_detail",
      icon: <SearchOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#f0932b",
      showOn: (data) => data.hasAccess,
      accessKey: "PROMOTER_MANAGE",
    },
    // {
    //   event: "delete",
    //   icon: <DeleteOutlined style={{ fontSize: 12, color: "white" }} />,
    //   color: "#FF0000",
    //   popup: true,
    //   popupTitle: "Are you sure want to delete?",
    //   showOn: (data) => data.hasAccess,
    //   accessKey: "PROMOTER_MANAGE",
    // },
  ],
};
const LIST_TYPE_MAP = {
  1: "ALL",
  2: "APPROVED",
  3: "PENDING",
  4: "REJECTED",
  5: "INVITED",
};

export default function AnnouncementList(props) {
  const [isLoading, setLoading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalFor,setShowModalFor]=useState("")
  const dispatch = useDispatch();
  const { hasAccess } = useContext(FeatureFlags);

  useEffect(() => {
    fetchAnnoucementList();
  }, []);
  const fetchAnnoucementList = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_ANNOUNCEMENT_LIST);
    if (response.status) {
      setAnnouncements(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  const handleShowModal = (type) => {
    // setShowDrawerFor(type);
    setShowModal(true);
    setShowModalFor(type)
  };

  const handleDeleteAmentity = async (event) => {
    const response = await apiDelete(
"      API_ENDPOINT_GLOBAL_DELETE_AMENTITY + event.rowData.id"    );
    if (response.status) {
      fetchAnnoucementList();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    }
  };

  const handleTableAction = (event) => {
    switch (event.eventType) {
      case "delete":
        handleDeleteAmentity(event.rowData.id);
      case "view_detail":
        setSelectedData(event.rowData);
        handleShowModal("VIEW");
        return;
      default:
        return null;
    }
  };



  return (
    <div className="AnnouncementList">
      <div className="TopSelectors">
        <div className="leftSelectors"></div>
        <div className="rightSelectors">
          {hasAccess("PROPERTY_LISTING_MANAGE") ? (
            <div className="WidgetContainer" style={{ minWidth: 0 }}>
              <Button onClick={() => handleShowModal("CREATE")}>📢 Make Announcement</Button>
            </div>
          ) : null}
        </div>
      </div>
      <div id="tabBarContainer">
        <TableView
          data={announcements}
          isLoading={isLoading}
          column={[...tableColumn, actionColumnObj]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      </div>

      <Modal
        title={showModalFor==="CREATE"? "Make Announcement":"View Announcement"}
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
        width={800}
      >
        {showModalFor==="CREATE"?
        <MakeAnnouncement onCreate={()=>{
          setShowModal(false)
          fetchAnnoucementList()
        }}
   
        />:
        <div>
          <div style={{marginBottom:24}}>
            <div  style={{display:'flex',alignItems:'center'}}>
            <small>Article Created by  -  {" "}</small> <Typography variant="caption">{selectedData?.user?.user_profile?.first_name} {selectedData?.user?.user_profile?.last_name}</Typography>
             <br/>
            </div>
            <small>Posted on  - {" "} {moment(selectedData?.updatedAt).fromNow()} </small>

          </div>
          <div style={{height:500,overflowY:'auto'}} dangerouslySetInnerHTML={{__html:selectedData?.description}}></div>
          </div>}
      </Modal>
    </div>
  );
}
