import Typography from "app/shared/Typography";
import PromotorIcon from "assets/images/promote.svg";
import HomeIcon from "assets/images/home.svg";
import ComissionIcon from "assets/images/comission.svg";
import VisitIcon from "assets/images/visit.svg";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import HomeSVGIcon from "assets/images/HomeIcon.svg";
import PlotSVGIcon from "assets/images/PlotIcon.svg";
import EarningIcon from "assets/images/earning.svg";
import PaidIcon from "assets/images/paid.svg";
import UnpaidIcon from "assets/images/unpaid.svg";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import MapsHomeWorkOutlinedIcon from "@mui/icons-material/MapsHomeWorkOutlined";
import "./BrokerDashCard.scss";
import { colorPicker } from "app/utils/helpers/color.helper";
import { FormattedNumber } from "react-intl";
import { useNavigate } from "react-router";
const imageType = {
  HOME: HomeIcon,
  VISIT: VisitIcon,
  COMISSION: ComissionIcon,
  EARNING: EarningIcon,
  UNPAID: UnpaidIcon,
  PAID: PaidIcon,
};

const titleMap = {
  HOME: "Property Sales",
  VISIT: "Property Visits",
  COMISSION: "Comission Made",
  EARNING: "Expected Current Month",
  UNPAID: "Total Unpaid",
  PAID: "Total Paid",
};
export default function BrokerDashCard(props) {
  const navigate = useNavigate();
  return (
    <div className="BrokerDashCard">
      <div className="TopHeader">
        <div className="HeaderLeft">
          <img src={imageType[props.type]} alt="Icon" style={{ width: 62 }} />
          <div className="HeaderInfo">
            <div className="TopStateNumber">
              {props.dataType === "AMOUNT" ? (
                <Typography variant="h4">
                  <b style={{ fontWeight: 700, color: colorPicker("green") }}>
                    <FormattedNumber
                      value={(props.data && props.data?.total) || 0}
                      style="currency"
                      currency={"INR"}
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  </b>
                </Typography>
              ) : (
                <Typography variant="h4">
                  {(props.data && props.data?.total) || 0}
                </Typography>
              )}
            </div>
            <Typography variant="h6" color="gray-dark">
              {titleMap[props.type]}
            </Typography>
          </div>
        </div>
        {props.redirect ? (
          <Typography
            variant="caption"
            color="red"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(props.redirect)}
          >
            See All
          </Typography>
        ) : null}
      </div>
      <div className="Stats">
        <div className="Stat">
          <MapsHomeWorkOutlinedIcon
            style={{ color: colorPicker("gray_dark"), fontSize: 36 }}
          />

          <div className="StateDetail">
            {props.dataType === "AMOUNT" ? (
              <Typography variant="h6">
                <FormattedNumber
                  value={props.data && props.data?.residential}
                  style="currency"
                  currency={"INR"}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              </Typography>
            ) : (
              <Typography variant="h6">
                {props.data && props.data?.residential}
              </Typography>
            )}

            <Typography variant="caption" color="gray-dark">
              Residential
            </Typography>
          </div>
        </div>
        <div className="Stat">
          {/* <img src={PlotSVGIcon} style={{ width: 30, marginRight: 6 }} /> */}
          <BusinessRoundedIcon
            style={{ color: colorPicker("gray_dark"), fontSize: 36 }}
          />
          <div className="StateDetail">
            {props.dataType === "AMOUNT" ? (
              <Typography variant="h6">
                <FormattedNumber
                  value={props.data && props.data?.commercial}
                  style="currency"
                  currency={"INR"}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              </Typography>
            ) : (
              <Typography variant="h6">
                {props.data && props.data?.commercial}
              </Typography>
            )}

            <Typography variant="caption" color="gray-dark">
              Commercial
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
